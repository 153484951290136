<template>
  <div class="editor_content">
    <div class="editor_videos">视频专区</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="视频管理" name="first" style="padding: 5px">
        <template>
          <el-row class="row-bg row-bg-list">
            <el-col :span="6"> 视频选择：</el-col>
          </el-row>
          <div
            class="img_list"
            v-for="(item, index) in diyItem.cate_goods"
            :key="index"
          >
            <el-row class="row-bg">
              <el-col :span="6">
                <div class="img_left">
                  <img :src="item.t_image" alt="" />
                  <div class="bton" @click="selectedgoods(index)">更换视频</div>
                </div>
              </el-col>
              <el-col :span="18">
                <el-row type="flex" style="align-items: center">
                  <el-col :span="6" class="name">视频标题</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.title"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="样式管理" name="second">
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 标题颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.titlecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 人数颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.peoplecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 时长颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.durationcolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 原价颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.originalPricecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 现价颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.discountPricecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 页面边距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.padding"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 课程间距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.goodsmargin"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <selectVideos @selectImgshow="goodsurl" ref="mygoods"></selectVideos>
  </div>
</template>

<script>
import selectVideos from "@/views/components/selectVideos";
export default {
  name: "edit_videos",
  components: {
    selectVideos,
  },
  data() {
    return {
      activeName: "first",
      index: "",
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
  },
  mounted() {},
  methods: {
    // 选择课程
    selectedgoods: function (index) {
      this.index = index;
      this.$refs.mygoods.showimgselect();
    },
    // 课程选择回调
    goodsurl(vlu) {
      console.log(vlu);
      let newObj = {
        id: vlu.id,
        t_image: vlu.t_image,
        title: vlu.title,
        discountPrice: vlu.OriginalPrice,
        originalPrice: vlu.ShowPrice,
        IsPlatformDiscount: vlu.IsPlatformDiscount,
        CourseTags: vlu.CourseTags,
      };

      console.log(newObj);
      if (this.index === "") {
        this.diyItem.cate_goods.push(newObj);
      } else {
        this.$set(this.diyItem.cate_goods, this.index, newObj);
        this.index = "";
      }
    },
  },
};
</script>

<style scoped></style>
