<template>
  <div>
    <div class="index-hot">
      <div
        class="weui-flex"
        :style="{
          background: diyitem.style.background,
          padding:
            diyitem.style.paddingtop + 'px ' + diyitem.style.paddingleft + 'px',
        }"
      >
        <div class="item-hotdot">
          <template v-if="diyitem.style.iconType == 2">
            <img
              :src="diyitem.style.imgUrl"
              style="width: 20px; height: 20px"
              alt=""
            />
          </template>
          <template v-else>
            <i
              class="iconfont"
              :style="{ color: diyitem.style.color }"
              :class="diyitem.style.iconurl"
            ></i>
          </template>
        </div>
        <div class="notice-ba">
          <span :style="{ color: diyitem.style.color }">{{
            diyitem.data.title || "系统公告"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_notice",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      images: ["https://img.yzcdn.cn/1.jpg", "https://img.yzcdn.cn/2.jpg"],
    };
  },
  components: {
    // [NoticeBar.name]:NoticeBar,
  },
  mounted() {
    console.log(this.diyitem);
  },
};
</script>

<style scoped lang="scss">
.weui-flex {
  display: -webkit-box;
  display: flex;
  align-items: center;
}
.hotdot {
  height: 26px;
  width: 30px;
  vertical-align: middle;
  display: inline-block;
  margin: auto;
}
.item-hotdot {
  margin-right: 10px;
  img {
    width: 16px;
    height: auto;
    max-height: 25px;
  }
}

.notice-ba {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
</style>
