var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fui-title diypage-title",style:({
      background: _vm.diyitem.style.background,
      color: _vm.diyitem.style.color,
      padding:
        _vm.diyitem.style.paddingtop + 'px ' + _vm.diyitem.style.paddingleft + 'px',
      fontSize: _vm.diyitem.style.fontsize + 'px',
      textAlign: _vm.diyitem.style.textalign,
    })},[_c('div',{staticClass:"item-hotdot"},[(_vm.diyitem.style.iconType == 2)?[_c('img',{staticStyle:{"width":"20px","height":"20px","margin-right":"5px"},attrs:{"src":_vm.serverSrc + _vm.diyitem.data.imgUrl,"alt":""}})]:[_c('i',{staticClass:"iconfont",class:_vm.diyitem.data.icon,staticStyle:{"margin-right":"5px"},style:({
            color: _vm.diyitem.style.color,
            fontSize: _vm.diyitem.style.iconsize + 'px',
          })})]],2),_c('div',{staticClass:"fui-title-name"},[_vm._v(" "+_vm._s(_vm.diyitem.data.title || "请输入标题名称")+" ")]),(_vm.diyitem.style.rightIcon == 1)?[_c('div',{staticClass:"right-more",style:({
          fontSize: _vm.diyitem.style.rightsize + 'px',
          color: _vm.diyitem.style.iconColor,
          backgroundImage: 'url(' + _vm.diyitem.style.rightbgurl + ')',
        })},[_vm._v(" "+_vm._s(_vm.diyitem.data.rightText)+" ")])]:(_vm.diyitem.style.rightIcon == '0')?[_c('div')]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }