import request from '@/utils/request'

// 在线艺术 列表
export function artListNew(data) {
    return request({
        url: 'api/designTemplate/QueryArtExhibitionList',
        method: 'get',
        params: data
    })
}

// 在线艺术 分类
export function QueryGoodsPropertyList(data) {
    return request({
        url: 'api/designTemplate/QueryGoodsPropertyList',
        method: 'get',
        params: data
    })
}