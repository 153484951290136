<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <el-row :gutter="diyitem.style.goodsmargin">
      <el-col
        :span="24"
        :style="{
          marginTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-artist-item"
        :key="index"
      >
        <div
          class="top flex jc-space-between ai-baseline"
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          <div class="flex">
            <img :src="childitem.avatar" alt="" srcset="" class="avatar" />
            <div class="">
              <p
                class="title"
                :style="{
                  color: diyitem.style.titlecolor,
                }"
              >
                {{ childitem.title }}
              </p>
              <p
                class="describe"
                :style="{
                  color: diyitem.style.describecolor,
                }"
              >
                {{ childitem.describe }}
              </p>
            </div>
          </div>
          <div
            class="worknum"
            :style="{
              color: diyitem.style.worknumcolor,
            }"
          >
            作品数&nbsp;&nbsp;{{ childitem.worknum }}
          </div>
        </div>
        <el-row :gutter="6" class="details">
          <el-col :span="8" v-for="(ci, i) in childitem.images" :key="i">
            <img
              v-if="ci.CoverImg == null"
              :src="ci"
              alt=""
              srcset=""
              class="avatar"
            />
            <img v-else :src="ci.CoverImg" alt="" srcset="" class="avatar" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "tpl_goods",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-artist-item {
  text-align: left;
  line-height: 1.5;
  padding-bottom: 10px;
  .top {
    color: #333333;
    line-height: 1;
    .avatar {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 20px;
      border-radius: 50%;
    }
    .title {
      font-size: 14px;
    }
    .describe {
      font-size: 12px;
      color: #656565;
      margin-top: 5px;
    }
    .worknum {
      font-size: 12px;
      color: #afafaf;
    }
  }
  .details {
    margin-top: 25px;
    img {
      width: 100%;
      height: 89px;
    }
  }
}
</style>