<template>
  <div>
    <div
      class="fui-title diypage-title"
      :style="{
        background: diyitem.style.background,
        color: diyitem.style.color,
        padding:
          diyitem.style.paddingtop + 'px ' + diyitem.style.paddingleft + 'px',
        fontSize: diyitem.style.fontsize + 'px',
        textAlign: diyitem.style.textalign,
      }"
    >
      <div class="item-hotdot">
        <template v-if="diyitem.style.iconType == 2">
          <img
            :src="serverSrc + diyitem.data.imgUrl"
            style="width: 20px; height: 20px; margin-right: 5px"
            alt=""
          />
        </template>
        <template v-else>
          <i
            class="iconfont"
            :style="{
              color: diyitem.style.color,
              fontSize: diyitem.style.iconsize + 'px',
            }"
            style="margin-right: 5px"
            :class="diyitem.data.icon"
          ></i>
        </template>
      </div>
      <div class="fui-title-name">
        {{ diyitem.data.title || "请输入标题名称" }}
      </div>
      <template v-if="diyitem.style.rightIcon == 1">
        <div
          class="right-more"
          :style="{
            fontSize: diyitem.style.rightsize + 'px',
            color: diyitem.style.iconColor,
            backgroundImage: 'url(' + diyitem.style.rightbgurl + ')',
          }"
        >
          {{ diyitem.data.rightText }}
        </div>
      </template>
      <template v-else-if="diyitem.style.rightIcon == '0'">
        <div></div>
      </template>
    </div>
  </div>
</template>

<script>
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "tpl_title",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serverSrc,
    };
  },
};
</script>

<style scoped lang="scss">
.fui-title {
  display: flex;
  align-items: center;
  &-name {
    flex: 1;
  }
  .right-more {
    width: 49px;
    height: 18px;
    line-height: 18px;
    background-size: cover;
    text-align: center;
  }
}
</style>
