import request from '@/utils/request'

// 艺术家 分类
export function QueryCategoryList(data) {
    return request({
        url: 'api/v2/Art/QueryCategoryList',
        method: 'get',
        params: data
    })
}

// 艺术家 列表
export function QuerySupplierList(data) {
    return request({
        url: 'api/designTemplate/QuerySupplierList',
        method: 'get',
        params: data
    })
}