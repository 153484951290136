<template>
  <el-dialog
    title="在线展览"
    :visible.sync="centerDialogVisible"
    :width="dialogWidth"
    :append-to-body="true"
    class="upload-demo-zqj"
  >
    <div>
      <el-row class="row-bg row-bg-list" type="flex" justify="space-between">
        <el-col :span="8">
          <!-- <el-select v-model="cate_id" multiple style="width: 100%;" @change="onChange" filterable placeholder="请选择商品分类">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.goods_cate_id">
            </el-option>
          </el-select> -->
          <el-select
            v-model="cate_id"
            placeholder="请选择在线展览分类"
            @change="onChange"
          >
            <el-option
              v-for="item in options"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <el-input
            placeholder="请输入在线展览名称"
            v-model="title"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="shearch"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="img-container">
      <el-table :data="tableData" stripe max-height="450" style="width: 100%">
        <el-table-column prop="title" label="在线展览">
          <template slot-scope="scope">
            <div class="scope_title">
              <img :src="scope.row.CoverImg" alt="" />
              <div style="margin-left: 10px">{{ scope.row.Title }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="goodChoose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page flex-justify-end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="page_size"
          :pager-count="5"
          :page-sizes="[5, 10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cateList, goodsListNew } from "@/api/goods";
import {
  exhibitionListNew,
  QueryOnlineExhibitionCategoryList,
} from "@/api/exhibition";
export default {
  name: "index",
  data() {
    return {
      dialogWidth: "835px",
      centerDialogVisible: false,
      options: [
        {
          Id: 0,
          Name: "全部",
        },
      ],
      tableData: [],
      page: 1,
      page_size: 5,
      total: 0,
      title: "",
      keyWords: "",
      cate_id: "",
      props: {
        multiple: true,
        emitPath: false,
        checkStrictly: false,
        label: "Name",
        value: "Id",
        children: "children",
      },
    };
  },
  mounted() {},
  methods: {
    showimgselect: function () {
      this.centerDialogVisible = true;
      this.getCateList();
    },
    shearch: function () {
      this.keyWords = {
        title: this.title,
        cate_id: this.cate_id,
      };
      this.getGoodsList();
    },
    onChange() {
      this.getGoodsList();
    },
    // 获取商品列表
    getGoodsList: function () {
      let data = {
        pageIndex: this.page,
        pageSize: this.page_size,
        CateporyId: this.cate_id,
        SupplierCategory: 0,
        Title: this.title == null ? null : this.title,
      };
      exhibitionListNew(data)
        .then((response) => {
          this.tableData = response.response.data;
          this.total = response.response.dataCount;
        })
        .catch();
    },
    //  获取分类列表
    getCateList: function () {
      let data = {
        parentId: 2, // 暂时写死
      };
      QueryOnlineExhibitionCategoryList(data).then((response) => {
        this.options = [{ Id: 0, Name: "全部" }, ...response.response.data];
        this.cate_id = this.options[0].Id;
        console.log(this.options);
        this.getGoodsList();
      });
    },
    // 选择
    goodChoose(data) {
      this.centerDialogVisible = false;
      this.$emit("selectImgshow", data);
    },
    //  改变每页大小
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    //  前去多少页
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsList();
    },
  },
};
</script>
<style>
.upload-demo-zqj .el-upload__input {
  display: none;
}

.upload-demo-zqj .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__footer {
  border-top: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__body {
  padding: 20px;
}
</style>
<style scoped lang="scss">
.scope_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.img-container {
  margin-top: 20px;
  height: 520px;
  position: relative;

  .page {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
</style>
