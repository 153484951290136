<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <div
      :style="{
        marginTop: diyitem.style.goodsmargin + 'px',
      }"
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-exhibition-item"
      :key="index"
    >
      <img
        :src="childitem.t_image"
        class="t_image"
        alt=""
        srcset=""
        :style="{
          borderRadius: diyitem.style.imgborder + 'px',
        }"
      />
      <div class="title flex-between">
        <span
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          {{ childitem.title }}
        </span>
        <!-- <span class="time">2天前</span> -->
      </div>
      <div class="describe">
        <p
          :style="{
            color: diyitem.style.describecolor,
          }"
        >
          {{ childitem.describe | intercept(36) }}
        </p>
        <p
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
          class="describe-bottom"
        >
          <span>
            {{ childitem.date }}
          </span>
          <span>
            <i class="iconfont el-icon-view"></i>
            <template v-if="childitem.ReadCount == null"> 0 </template>
            <template v-else> {{ childitem.ReadCount }} </template>
          </span>
          <!-- &nbsp; &nbsp; -->
          <!-- <span>
            <i class="iconfont icon-custom-collection"></i>
            0
          </span>
          &nbsp; &nbsp;
          <span>
            <i class="iconfont icon-custom-share"></i>
            0
          </span> -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_exhibition",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-exhibition-item {
  text-align: left;
  line-height: 1.5;
  border: 1px solid #7e7e7e;
  .t_image {
    width: 100%;
    height: 142px;
    display: block;
  }
  .title {
    color: #373737;
    font-weight: bold;
    font-size: 15px;
    margin: 12px 0 6px;
    padding: 0 10px;
  }
  .time {
    font-size: 12px;
    font-weight: normal;
    color: #afafaf;
  }
  .describe {
    padding: 0 10px;
    color: #373737;
    font-size: 13px;
    .describe-bottom {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>