import request from '@/utils/request'

// 在线展览列表
export function exhibitionListNew(data) {
    return request({
        url: 'api/designTemplate/QueryArtNews',
        method: 'get',
        params: data
    })
}

// 在线展览 分类
export function QueryOnlineExhibitionCategoryList(data) {
    return request({
        url: 'api/designTemplate/QueryOnlineExhibitionCategoryList',
        method: 'get',
        params: data
    })
}