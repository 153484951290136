<template>
  <div class="preview_component_child" ref="body">
    <draggable
      :options="options"
      class="board-column-content"
      ref="bodyList"
      v-model="diyList"
      :group="{ name: 'people', pull: false, put: true }"
      :component-data="getComponentData()"
      draggable=".preview-item-list"
      :scroll-sensitivity="100"
      :force-fallback="true"
    >
      <template v-for="(item, index) in diyList">
        <template v-if="item.id === 'fixedsearch'">
          <!--固定搜索栏需要进行一次单独处理-->
          <div
            :key="index"
            :ref="'content' + index"
            :class="[selectKey === index ? 'previewChecked' : '']"
            @click="fixedSearch(item, index)"
            class="preview-item"
          >
            <tpl-fixedsearch
              class="item-list"
              :diyitem="item"
            ></tpl-fixedsearch>
            <!--删除图标-->
            <div
              class="delete_item"
              style="color: #666666"
              @click.stop="deleteItem(index)"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            :key="index"
            :ref="'content' + index"
            :class="[selectKey === index ? 'previewChecked' : '']"
            class="preview-item preview-item-list"
          >
            <!--顶部标题-->
            <tpl-header-title
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'headerTitle'"
            ></tpl-header-title>
            <!--轮播-->
            <tpl-banner
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'banner'"
            ></tpl-banner>
            <!--公告-->
            <tpl-notice
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'notice'"
            ></tpl-notice>
            <!--图文导航-->
            <tpl-menu
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'menu'"
            ></tpl-menu>

            <!--标题-->
            <tpl-title
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'title'"
            ></tpl-title>

            <!--课程-->
            <tpl-courses
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'courses'"
            ></tpl-courses>

            <!--在线艺术-->
            <tpl-art
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'art'"
            ></tpl-art>

            <!--在线展览-->
            <tpl-exhibition
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'exhibition'"
            ></tpl-exhibition>

            <!--商城模块-->
            <tpl-goods
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'goods'"
            ></tpl-goods>

            <!--资讯动态-->
            <tpl-dynamic
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'dynamic'"
            ></tpl-dynamic>

            <!--资料下载-->
            <tpl-dataDownload
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'dataDownload'"
            ></tpl-dataDownload>

            <!--讲师团队-->
            <tpl-lecturer
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'lecturer'"
            ></tpl-lecturer>

            <!--专家团队-->
            <tpl-expert
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'expert'"
            ></tpl-expert>

            <!--合作机构-->
            <tpl-organization
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'organization'"
            ></tpl-organization>

            <!--艺术模块-->
            <tpl-artist
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'artist'"
            ></tpl-artist>

            <!--艺术推荐-->
            <tpl-artRecommend
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'artRecommend'"
            ></tpl-artRecommend>

            <!--艺术活动-->
            <tpl-activity
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'activity'"
            ></tpl-activity>

            <!--艺术展览-->
            <tpl-artExhibition
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'artExhibition'"
            ></tpl-artExhibition>

            <!--视频专区-->
            <tpl-videos
              class="item-list"
              :diyitem="item"
              v-if="item.id === 'videos'"
            ></tpl-videos>

            <!--拖拽过程中留白-->
            <div class="preview_blank">
              <div class="blank">
                <i class="el-icon-plus"></i>
                <span>放置此处</span>
              </div>
            </div>
            <!--删除图标-->
            <div
              class="delete_item"
              style="color: #666666"
              @click.stop="deleteItem(index)"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
        </template>
      </template>
    </draggable>
    <template v-if="diyList.length == 0">
      <div class="no-content">
        <span>暂无组件数据</span>
      </div>
    </template>
  </div>
</template>

<script>
import tplHeaderTitle from "./tpl_header_title";
import tplBanner from "./tpl_banner";
import tplNotice from "./tpl_notice";
import tplMenu from "./tpl_menu";
import tplTitle from "./tpl_title";
import tplCourses from "./tpl_courses";
import tplArt from "./tpl_art";
import tplExhibition from "./tpl_exhibition";
import tplGoods from "./tpl_goods";
import tplDynamic from "./tpl_dynamic";
import tplDataDownload from "./tpl_dataDownload";
import tplLecturer from "./tpl_lecturer";
import tplExpert from "./tpl_expert";
import tplOrganization from "./tpl_organization";
import tplArtist from "./tpl_artist";
import tplArtRecommend from "./tpl_artRecommend";
import tplActivity from "./tpl_activity.vue";
import tplArtExhibition from "./tpl_artExhibition.vue";
import tplVideos from "./tpl_videos.vue";
import draggable from "vuedraggable";

export default {
  name: "index",
  components: {
    "tpl-header-title": tplHeaderTitle,
    "tpl-banner": tplBanner,
    "tpl-notice": tplNotice,
    "tpl-menu": tplMenu,
    "tpl-title": tplTitle,
    "tpl-courses": tplCourses,
    "tpl-art": tplArt,
    "tpl-exhibition": tplExhibition,
    "tpl-goods": tplGoods,
    "tpl-dynamic": tplDynamic,
    "tpl-dataDownload": tplDataDownload,
    "tpl-organization": tplOrganization,
    "tpl-lecturer": tplLecturer,
    "tpl-expert": tplExpert,
    "tpl-artist": tplArtist,
    "tpl-artRecommend": tplArtRecommend,
    "tpl-activity": tplActivity,
    "tpl-artExhibition": tplArtExhibition,
    "tpl-videos": tplVideos,
    draggable,
  },
  data() {
    return {
      options: {
        sort: true, // 定义是否可以拖动
        chosenClass: "selector", // 格式为简单css选择器的字符串，目标被选中时添加
        ghostClass: "selector1", // 格式为简单css选择器的字符串，当拖动列表单元时会生成一个副本作为影子单元来模拟被拖动单元排序的情况，此配置项就是来给这个影子单元添加一个class，我们可以通过这种方式来给影子元素进行编辑样式
        dragClass: "selector2", // 格式为简单css选择器的字符串，目标拖动过程中添加
        forceFallback: false, // 如果设置为true时，将不使用原生的html5的拖放，可以修改一些拖放中元素的样式等
        scroll: true, // 当排序的容器是个可滚动的区域，拖放可以引起区域滚动
        animation: 150, //动画
        // scrollSensitivity: 60, // 就是鼠标靠近边缘多远开始滚动默认30
        // scrollSpeed: 4000, // 滚动速度
        // scrollFn: function (offsetX, offsetY, originalEvent, touchEvt, hoverTargetEl) {
        //   console.log(offsetY)
        // }
      },
      offsetTop: 0,
      diyListLength: 0,
    };
  },
  mounted() {},
  computed: {
    diyList: {
      get: function (vlu) {
        let self = this;
        let length = this.$store.state.diypage.diyList.length;
        let diyList = this.$store.state.diypage.diyList;
        if (self.diyListLength < length) {
          // 将元素滚动到指定位置
          self.$nextTick(function () {
            // 这里必须是页面渲染完成后执行，否则拿不到数据
            if (this.selectKey === 0) {
              // this.$refs.body.scrollTop=0;
              this.$refs.body.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            } else {
              this.$refs.body.scrollTo({
                top: this.offsetTop,
                behavior: "smooth",
              });
            }
          });
        }
        self.diyListLength = length;
        return diyList;
      },
      set: function (value) {
        this.$store.dispatch("updataDiyList", value);
      },
    },
    selectKey: {
      get: function () {
        let value = this.$store.state.diypage.diyKey;
        let self = this;
        if (value !== "") {
          self.$nextTick(function () {
            // 这里必须是页面渲染完成后执行，否则拿不到数据
            let offsetTop = this.$refs.bodyList.$el.clientHeight;
            self.offsetTop = offsetTop;
            // let ref='content'+value
            // let offsetTop=this.$refs[ref][0].offsetTop;
            // this.offsetTop=offsetTop;
            // // this.$refs[ref][0].scrollTop=offsetTop
            // this.$store.commit('SETOFFSETTOP',offsetTop-40);
          });
        }
        return this.$store.state.diypage.diyKey;
      },
      set: function (value) {
        this.$store.commit("SETDIYKEY", value);
      },
    },
  },
  methods: {
    // 固定搜索栏点击
    fixedSearch: function (item, index) {
      if (index === this.selectKey) {
        return "";
      }
      this.selectKey = index;
      this.$store.commit("SETDIYITEM", this.diyList[index]);
    },
    deleteItem: function (index) {
      this.$confirm("您确定要删除此模块吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.diyList.splice(index, 1);
          this.selectKey = "";
          this.$store.commit("SETDIYITEM", {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onChoose: function (item) {
      let index = item.oldIndex;
      if (index === this.selectKey) {
        return "";
      }
      this.selectKey = item.oldIndex;
      this.$store.commit("SETDIYITEM", this.diyList[index]);
    },
    // 排序发生变化时的回调函数
    onUpdate: function (item) {
      this.selectKey = item.newIndex;
      this.$store.commit("SETDIYITEM", this.diyList[item.newIndex]);
    },
    getComponentData() {
      return {
        on: {
          // 用来代理UI组件需要绑定的事件（@）
          choose: this.onChoose,
          sort: this.onUpdate,
        },
        props: {
          // 用来代理UI组件需要绑定的属性（:）
        },
      };
    },
  },
  mounted() {
    console.log(this.diyList);
  },
};
</script>

<style scoped lang="scss">
.no-content {
  display: flex;
  height: 100%;

  span {
    margin: auto;
    color: red;
  }
}

.preview_component_child {
  width: 24.5rem;
}
.board-column-content {
  height: 37.5rem;
  overflow-y: scroll;
}

.preview-item {
  position: relative;
  z-index: 10;
  box-sizing: border-box;

  .item-list {
    cursor: grab;
    overflow: hidden;
  }

  .delete_item {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2001;
    cursor: pointer;
    display: none;
  }

  .preview_blank {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: #f5f5f5;
    border: 0.125rem dashed #409eff;
    color: #999999;

    .blank {
      margin: auto;
    }
  }
}

.selector1 {
  .preview_blank {
    display: flex;
  }
}

.preview-item:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: grab;
}

.preview-item:hover {
  .delete_item {
    display: block;
  }
}

.preview-item:hover:before {
  border: 0.0625rem dashed #409eff;
}

.previewChecked:before {
  border: 0.0625rem dashed #409eff;
}
</style>
