var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-exhibition-item",style:({
      marginTop: _vm.diyitem.style.goodsmargin + 'px',
    })},[_c('el-row',{staticClass:"title"},[_c('el-col',{attrs:{"span":4}},[_c('img',{staticClass:"img",attrs:{"src":childitem.t_image,"alt":""}})]),_c('el-col',{attrs:{"span":16}},[_c('p',{staticClass:"ellipsis-1",style:({
            color: _vm.diyitem.style.titlecolor,
          })},[_vm._v(" "+_vm._s(childitem.title)+" ")]),_c('p',{staticClass:"time",style:({
            color: _vm.diyitem.style.timecolor,
          })},[_vm._v(" "+_vm._s(childitem.time)+" ")]),(childitem.Price == 0)?_c('p',{staticClass:"upload"},[_vm._v("免费下载")]):_c('p',{staticClass:"Price"},[_vm._v("￥"+_vm._s(childitem.Price))])]),_c('el-col',{attrs:{"span":4}},[_c('i',{staticClass:"el-icon-arrow-right icon",staticStyle:{"font-size":"30px"}})])],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }