import request from '@/utils/request'

// 资料下载 分类
export function QueryCourseFileTypeList(data) {
    return request({
        url: 'api/designTemplate/QueryCourseFileTypeList',
        method: 'get',
        params: data
    })
}

// 资料下载 列表
export function QueryCourseFileList(data) {
    return request({
        url: 'api/DesignTemplate/QueryCourseFileList',
        method: 'get',
        params: data
    })
}