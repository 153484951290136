var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fui-title diypage-title",style:({
      background: _vm.diyitem.style.background,
      color: _vm.diyitem.style.color,
      padding:
        _vm.diyitem.style.paddingtop + 'px ' + _vm.diyitem.style.paddingleft + 'px',
      fontSize: _vm.diyitem.style.fontsize + 'px',
      textAlign: _vm.diyitem.style.textalign,
    })},[(_vm.diyitem.data.return.icon == '1')?[_c('i',{staticClass:"el-icon-arrow-left",style:({
          color: _vm.diyitem.style.iconColor,
          fontSize: _vm.diyitem.style.iconsize + 'px',
        })})]:_vm._e(),(_vm.diyitem.data.mail.icon == '1')?[_c('i',{staticClass:"iconfont icon-custom-mail",style:({
          color: _vm.diyitem.style.iconColor,
          fontSize: _vm.diyitem.style.iconsize + 'px',
        })})]:_vm._e(),_c('div',{staticClass:"fui-title-name"},[_vm._v(" "+_vm._s(_vm.diyitem.data.title || "请输入标题名称")+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }