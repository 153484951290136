var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},[_c('el-row',{attrs:{"gutter":_vm.diyitem.style.goodsmargin}},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('el-col',{key:index,staticClass:"fui-artist-item",style:({
        marginTop: _vm.diyitem.style.goodsmargin + 'px',
      }),attrs:{"span":24}},[_c('div',{staticClass:"top flex jc-space-between ai-baseline",style:({
          color: _vm.diyitem.style.titlecolor,
        })},[_c('div',{staticClass:"flex"},[_c('img',{staticClass:"avatar",attrs:{"src":childitem.avatar,"alt":"","srcset":""}}),_c('div',{},[_c('p',{staticClass:"title",style:({
                color: _vm.diyitem.style.titlecolor,
              })},[_vm._v(" "+_vm._s(childitem.title)+" ")]),_c('p',{staticClass:"describe",style:({
                color: _vm.diyitem.style.describecolor,
              })},[_vm._v(" "+_vm._s(childitem.describe)+" ")])])]),_c('div',{staticClass:"worknum",style:({
            color: _vm.diyitem.style.worknumcolor,
          })},[_vm._v(" 作品数  "+_vm._s(childitem.worknum)+" ")])]),_c('el-row',{staticClass:"details",attrs:{"gutter":6}},_vm._l((childitem.images),function(ci,i){return _c('el-col',{key:i,attrs:{"span":8}},[(ci.CoverImg == null)?_c('img',{staticClass:"avatar",attrs:{"src":ci,"alt":"","srcset":""}}):_c('img',{staticClass:"avatar",attrs:{"src":ci.CoverImg,"alt":"","srcset":""}})])}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }