<template>
  <el-dialog
    :visible.sync="centerDialogVisible"
    :width="dialogWidth"
    title
    :append-to-body="true"
    class="select-img"
  >
    <div class="title" slot="title">选择图片</div>
    <div class="img-container">
      <el-row class="row-bg">
        <el-col :span="5">
          <div class="select-img-top">
            <button-upload
              @imgData="UpimgInfo"
              :group_id="group_id"
            ></button-upload>
          </div>
          <!-- <div class="select-img-top">
            <el-button type="danger" @click="deleteImg">删除图片</el-button>
          </div> -->
          <!-- <div class="container_left">
            <ul>
              <li
                v-for="(item, index) in groupList"
                :class="item.checked ? 'active' : ''"
                :key="index"
                :style="{ borderColor: item.checked ? defaultColor : '' }"
                @click="chooseItem(index)"
              >
                <div
                  class="type_name"
                  :style="{ color: item.checked ? defaultColor : '' }"
                >
                  {{ item.group_name }}
                </div>
              </li>
            </ul>
            <div class="flex-center">
              <el-button
                type="primary"
                style="margin-top: 5px"
                plain
                @click="getAddGroup"
                >添加分组</el-button
              >
            </div>
          </div> -->
        </el-col>
        <el-col :span="19" class="img-container-right">
          <div
            class="select-img-top flex-between"
            style="padding: 0; padding-bottom: 30px"
          >
            <!-- <div>
              <span>排序方式：</span>
              <el-select
                v-model="sort"
                placeholder="请选择"
                @change="getpictureList"
                style="width: 150px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div> -->
            <div>
              <el-input
                placeholder="图片名字"
                style="width: 250px"
                v-model="Name"
              >
                <el-button
                  slot="append"
                  @click="getpictureList"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </div>
          </div>
          <ul class="image-list flex-start-start">
            <li
              class="image-item"
              v-for="(item, index) in pictureList"
              :key="index"
              @click="itemclick(item, index)"
            >
              <div
                :class="item.checked ? 'active' : ''"
                class="image-box"
                :style="{
                  borderColor: item.checked ? defaultColor : '',
                  backgroundImage:
                    'url(' +
                    (item.Url.indexOf('http') != -1
                      ? item.Url
                      : serverSrc + item.Url) +
                    ')',
                }"
              >
                <div style="width: 26px; background: #ff0000">
                  <i
                    v-if="item.checked"
                    style="margin: 5px; color: #fff"
                    class="el-icon-delete"
                    @click="deleteImg(item)"
                  ></i>
                </div>
              </div>
              <!-- <div class="image-meta">{{ item.size }}</div> -->
              <div class="image-title" :title="item.Name">
                {{ item.Name }}
              </div>
            </li>
          </ul>
          <div
            class="flex-justify-end"
            style="margin-top: 20px; margin-bottom: 10px"
          >
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :pager-count="5"
              :page-size="page_size"
              :page-sizes="[10, 20, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="delclick">取 消</el-button>
      <el-button type="primary" @click="surclick">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ButtonUpload from "../Upload/ButtonUpload";
import { groupList, groupAdd, pictureList, ImageDelete } from "@/api/public";
import { serverSrc } from "@/apiConfig/index";

export default {
  name: "index",
  components: {
    "button-upload": ButtonUpload,
  },
  data() {
    return {
      serverSrc: serverSrc,
      dialogWidth: "1000px",
      imgurl: [],
      centerDialogVisible: false,
      pictureList: [],
      options: [
        {
          label: "时间倒序",
          value: "desc",
        },
        {
          label: "时间顺序",
          value: "asc",
        },
      ],
      sort: "desc",
      groupList: [], // 图片分组
      page: 1,
      group_id: 0,
      keywords: "",
      page_size: 10,
      total: 0,
      imglist: [], //多选图片
      more: "",
      Name: "",
    };
  },
  computed: {
    defaultColor: function () {
      return this.$store.state.theme.defaultColor;
    },
  },
  mounted() {},
  methods: {
    //  获取图片列表
    getpictureList: function () {
      let data = {
        pageIndex: this.page,
        pageSize: this.page_size,
        Name: this.Name,
      };
      pictureList(data)
        .then((res) => {
          this.pictureList = res.data;
          this.total = res.dataCount;
        })
        .catch();
    },
    // 上传图片回调
    UpimgInfo: function (e) {
      if (e) {
        this.$message.success("图片上传成功");
        this.page = 1;
        this.getpictureList();
      }
    },
    // 删除图片
    deleteImg(item) {
      console.log(item);
      ImageDelete({ id: item.Id }).then((res) => {
        console.log(res);
        this.getpictureList();
      });
    },
    // 选择分组
    chooseItem: function (index) {
      for (let i = 0; i < this.groupList.length; i++) {
        this.$set(this.groupList[i], "checked", false);
      }
      this.$set(this.groupList[index], "checked", true);
      this.group_id = this.groupList[index].id;
      this.page = 1;
      this.getpictureList();
    },
    // 获取图片分组
    getgroupList: function () {
      this.groupList = [];
      groupList({})
        .then((response) => {
          let data = [
            {
              group_name: "全部分组",
              id: 0,
              checked: true,
            },
            {
              group_name: "未分组",
              id: -1,
            },
          ];
          let arr = data.concat(response.result);
          for (let i in arr) {
            if (this.group_id == arr[i].id) {
              arr[i].checked = true;
            } else {
              arr[i].checked = false;
            }
          }
          this.groupList = arr;
        })
        .catch();
    },
    // 添加分组
    getAddGroup: function () {
      this.$prompt("请输入分组名称", "提示", {
        confirmButtonText: "确定",
        inputValue: name,
        cancelButtonText: "取消",
        inputErrorMessage: "请输入1到20个字符",
        inputValidator: function (value) {
          if (value.length >= 1 && value.length < 20) {
            return true;
          } else {
            return false;
          }
        },
      })
        .then(({ value }) => {
          let data = {
            group_name: value,
          };
          groupAdd(data)
            .then((response) => {
              this.$message.success(response.message);
              this.getgroupList();
              this.group_id = response.result.id;
              this.getpictureList();
            })
            .catch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    showimgselect: function (more) {
      this.more = more;
      this.centerDialogVisible = true;
      // this.getgroupList();
      this.getpictureList();
    },
    //  选择图片
    itemclick: function (imgurl, index) {
      if (this.more != 2) {
        this.imgurl = imgurl;
        for (let i = 0; i < this.pictureList.length; i++) {
          this.$set(this.pictureList[i], "checked", false);
        }
        this.$set(this.pictureList[index], "checked", true);
      } else {
        let imgindex = this.imglist.indexOf(index);
        if (imgindex != -1) {
          this.imglist.splice(imgindex, 1);
          this.imgurl.splice(imgindex, 1);
        } else {
          this.imglist.push(index);
          this.imgurl.push(imgurl);
        }
        for (let i = 0; i < this.pictureList.length; i++) {
          this.$set(this.pictureList[i], "checked", false);
        }
        for (let i = 0; i < this.imglist.length; i++) {
          this.$set(this.pictureList[this.imglist[i]], "checked", true);
        }
      }

      // this.$set(this.pictureList[index], "checked", true);
    },
    surclick: function () {
      if (!this.imgurl) {
        this.$message("请选择图片！");
        return "";
      }
      this.centerDialogVisible = false;
      this.$emit("selectImgshow", this.imgurl);
      this.imgurl = [];
      this.imglist = [];
    },
    delclick() {
      this.centerDialogVisible = false;
      this.imgurl = [];
      this.imglist = [];
    },
    preLoadImg: function () {
      var pictureList = this.pictureList;
      for (let i in pictureList) {
        this.getImageInfo(pictureList[i].url).then((response) => {
          pictureList[i].size = response;
        });
      }
    },
    // 获取图片信息
    getImageInfo: function (url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img.width + "*" + img.height);
        img.onerror = reject;
        img.src = url;
      });
    },
    //  改变每页大小
    handleSizeChange(val) {
      this.page_size = val;
      this.getpictureList();
    },
    //  前去那一页
    handleCurrentChange(val) {
      this.page = val;
      this.getpictureList();
    },
  },
};
</script>
<style lang="scss">
.select-img {
  .el-dialog__header {
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 20px 30px 20px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
  }
}
</style>

<style lang="scss" scoped>
.select-img {
  .title {
    font-size: 20px;
  }

  .select-img-top {
    padding: 30px;
  }
}

.img-container {
  .img-container-right {
    padding: 30px;
    padding-bottom: 0;
  }

  .container_left {
    background: #f7f7f7;
    width: 100%;
    height: 420px;

    ul {
      height: 350px;
      overflow-y: auto;
    }

    li {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type_name {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .active {
      background-color: #ffffff;
      border-left: 2px solid;
    }

    li:hover {
      background-color: #ffffff;
    }
  }

  .image-list {
    padding: 0 10px;
    height: 350px;
    width: 740px;
    flex-wrap: wrap;
    overflow-y: auto;

    .image-item {
      position: relative;
      float: left;
      width: 120px;
      height: 155px;
      margin-right: 20px;
      margin-bottom: 15px;
      cursor: pointer;
      font-size: 12px;
      overflow: hidden;
    }
    .active {
      position: relative;
      font-family: "iconfont" !important;
      font-size: 16px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .active:after {
      content: "\e615";
      position: absolute;
      z-index: 100;
      top: -50px;
      right: -40px;
      width: 80px;
      padding-left: 28px;
      height: 40px;
      color: #ffffff;
      line-height: 60px;
      background: #e76506;
      transform-origin: left bottom;
      transform: rotate(90deg);
      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }

    .image-meta {
      position: absolute;
      width: 100%;
      height: 25px;
      line-height: 25px;
      color: #fff;
      text-align: center;
      background: rgba(0, 0, 0, 0.2);
      bottom: 35px;
    }

    .image-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #333333;
    }

    .image-box {
      background: #f7f7f7;
      width: 116px;
      height: 116px;
      border: 2px solid transparent;
      background-size: cover;
      box-sizing: content-box;
      background-position: 50% 50%;
    }
  }
}
</style>
