import request from "@/utils/request";

// 注册
export function register(data) {
  return request({
    url: "/platform/v1/account/register",
    method: "post",
    data,
  });
}
// 发送验证码
export function sendsms(data) {
  return request({
    url: "/platform/v1/account/sendsms",
    method: "post",
    data,
  });
}
// 登录---账号密码
export function login(data) {
  return request({
    url: "/platform/v1/account/login",
    method: "post",
    data,
  });
}
// 登录---验证码密码
export function codelogin(data) {
  return request({
    url: "/platform/v1/account/codelogin",
    method: "post",
    data,
  });
}
// 忘记密码
export function forgetPassword(data) {
  return request({
    url: "/platform/v1/account/forget",
    method: "post",
    data,
  });
}
// 修改头像
export function changeHeader(data) {
  return request({
    url: "/b2b/v1/customer/header",
    method: "post",
    data,
  });
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/platform/v1/account/info",
    method: "post",
    data,
  });
}
// 获取店铺列表
export function getMerchantsList(data) {
  return request({
    url: "/platform/v2/shop/list",
    method: "post",
    data,
  });
}
// 获取店铺类型
export function getmodelsList(data) {
  return request({
    url: "/platform/v1/shop/models",
    method: "get",
    params: data,
  });
}
// 获取经营类目
export function getcategoryList(data) {
  return request({
    url: "/platform/v1/shop/category",
    method: "get",
    params: data,
  });
}
// 创建店铺
export function createStore(data) {
  return request({
    url: "/platform/v1/shop/create",
    method: "post",
    data,
  });
}
// 创建店铺B2B
export function createStoreB2B(data) {
  return request({
    url: "/platform/v2/shop/createB2B",
    method: "post",
    data,
  });
}
// 删除店铺
export function shopDelete(data) {
  return request({
    url: "/platform/v1/shop/delete",
    method: "post",
    data,
  });
}
// 图片分组-列表
export function groupList(data) {
  return request({
    url: "/platform/v1/picture/group/list",
    method: "get",
    params: data,
  });
}
// 图片批量分组
export function pictureEditBatch(data) {
  return request({
    url: "/platform/v1/picture/editBatch",
    method: "post",
    data,
  });
}
// 视频分组-列表
export function videogroupList(data) {
  return request({
    url: "/platform/v1/video/group/list",
    method: "get",
    params: data,
  });
}

// 图片分组-添加
export function groupAdd(data) {
  return request({
    url: "/platform/v1/picture/group/add",
    method: "post",
    data,
  });
}

// 视频分组-添加
export function videogroupAdd(data) {
  return request({
    url: "/platform/v1/video/group/add",
    method: "post",
    data,
  });
}
// 图片分组-编辑
export function groupEdit(data) {
  return request({
    url: "/platform/v1/picture/group/edit",
    method: "post",
    data,
  });
}
// 视频分组-编辑
export function videogroupEdit(data) {
  return request({
    url: "/platform/v1/video/group/edit",
    method: "post",
    data,
  });
}
// 图片修改名称或分组）
export function pictureEdit(data) {
  return request({
    url: "/platform/v1/picture/edit",
    method: "post",
    data,
  });
}
//  视频修改名称或分组）
export function videoEdit(data) {
  return request({
    url: "/platform/v1/video/edit",
    method: "post",
    data,
  });
}

// 上传图片 
export function ImageDelete(data) {
  return request({
    url: "/api/DesignTemplate/ImageDelete",
    method: "get",
    params: data,
  });
}

// 图片分组-图片列表-普利
export function pictureList(data) {
  return request({
    url: "/api/DesignTemplate/QueryImagesList",
    method: "get",
    params: data,
  });
}

// app、三合艺术馆轮播图 列表
export function QueryBannerList(data) {
  return request({
    url: "/api/DesignTemplate/QueryBannerList",
    method: "get",
    params: data,
  });
}
// 商城、在线艺术轮播图
export function QueryShopBannerList(data) {
  return request({
    url: "/api/DesignTemplate/QueryShopBannerList",
    method: "get",
    params: data,
  });
}

//  视频 --列表
export function videoList(data) {
  return request({
    url: "/platform/v1/video/list",
    method: "get",
    params: data,
  });
}

// 图片分组-图片-删除
export function pictureDel(data) {
  return request({
    url: "/platform/v1/picture/del",
    method: "get",
    params: data,
  });
}

// 视频分组-视频-删除
export function videoDel(data) {
  return request({
    url: "/platform/v1/video/del",
    method: "get",
    params: data,
  });
}

// 图片分组-分组-删除
export function groupDel(data) {
  return request({
    url: "/platform/v1/picture/group/del",
    method: "get",
    params: data,
  });
}

// 视频分组-分组-删除
export function videogroupDel(data) {
  return request({
    url: "/platform/v1/video/group/del",
    method: "get",
    params: data,
  });
}

// 系统物流公司
export function companySys(data) {
  return request({
    url: "/platform/v1/express/company/sys",
    method: "get",
    params: data,
  });
}
// 系统-支付方式
export function commonPaytype(data) {
  return request({
    url: "/platform/v1/common/paytype",
    method: "get",
    params: data,
  });
}

// 公共搜索项
export function listSearch(data) {
  return request({
    url: "/platform/v1/list/search",
    method: "get",
    params: data,
  });
}
// 跳转链接-普利-带分类
export function pageMenu(data) {
  return request({
    url: "api/DesignTemplate/QueryLinkCategory",
    method: "get",
    params: data,
  });
}

// 日志
export function logAccess(data) {
  return request({
    url: "/platform/v1/log/access",
    method: "post",
    data,
  });
}

// 店铺信息
export function shopInfo(data) {
  return request({
    url: "/platform/v1/shop/info",
    method: "post",
    data,
  });
}

// 获取图片base64
export function imageUrlbase64(data) {
  return request({
    url: "/platform/v1/local/image",
    method: "get",
    params: data,
  });
}

// 获取图片base64
export function getQrcode(data) {
  return request({
    url: "/b2c/v1/qrcode",
    method: "post",
    data,
  });
}

// 微信登录信息
export function wechatUser(data) {
  return request({
    url: "/platform/v1/wechat/user",
    method: "get",
    params: data,
  });
}

// 账号绑定微信
export function wechatBind(data) {
  return request({
    url: "/platform/v1/wechat/bind",
    method: "post",
    data,
  });
}

// 获取店铺微信登录配置信息
export function accountInfo(data) {
  return request({
    url: "/platform/v1/wechat/accountInfo",
    method: "get",
    data,
  });
}

// 用户信息-编辑
export function changeEdit(data) {
  return request({
    url: "/platform/v1/account/edit",
    method: "post",
    data,
  });
}
// 获取专题
export function specificList(data) {
  return request({
    url: "/platform/v2/specific/list",
    method: "post",
    data,
  });
}
// 查看积分凭证
export function pointslookImg(data) {
  return request({
    url: "/wap/v1/member/points/lookImg",
    method: "post",
    data,
  });
}