<template>
  <div class="add-page">
    <div class="store_template">
      <!--组件库-->
      <previewAdd></previewAdd>
      <div class="store">
        <div class="preview">
          <!--头部-->
          <div class="preview_header" @click="changeHeader">
            <div class="preview_title">{{ pageInfo.page_title || "首页" }}</div>
          </div>
          <!--可拖拽模块-->
          <div
            class="preview_component"
            :style="{
              backgroundColor:
                pageInfo.backgroundType == 2 ? pageInfo.background : '',
            }"
          >
            <page-item></page-item>
            <!--编辑器-->
          </div>
        </div>
        <scroll-bar style="height: 660px">
          <!--编辑栏-->
          <editor class="editor_new"></editor>
        </scroll-bar>
      </div>
    </div>
    <bottom-button>
      <el-button type="primary" @click="onSubmit" :loading="loading"
        >提交保存</el-button
      >
    </bottom-button>
  </div>
</template>

<script>
import ScrollBar from "@/components/ScrollBar";
import pageItem from "@/components/diy/index";
import editor from "@/components/templates/editor";
import previewAdd from "@/components/templates/previewAdd";
import BottomButton from "@/components/BottomButton";

import axios from "axios";
import { Loading } from "element-ui";
import { serverSrc } from "@/apiConfig/index";
import { QueryDesignDetail, SaveDesign } from "@/api/courser";
export default {
  name: "editPage",
  components: {
    "scroll-bar": ScrollBar,
    "bottom-button": BottomButton,
    "page-item": pageItem,
    editor: editor,
    previewAdd: previewAdd,
  },
  data() {
    return {
      loading: false,
      pageId: "",
      pageInfo: {
        page_title: "",
      },
      type: "",
      list_id: 0,
      nav_id: "",
      templateId: 1,
    };
  },
  computed: {
    diylist() {
      return this.$store.state.diypage.diyList;
    },
  },
  created() {
    this.templateId = this.$route.query.id;
    // this.templateId = 1;
    this.getModelDetail();
  },
  mounted() {},
  methods: {
    // 获取模板详情
    async getModelDetail() {
      let res = await QueryDesignDetail({ id: this.templateId });
      if (res.response.ContentStr) {
        this.$store.dispatch(
          "updataDiyList",
          JSON.parse(res.response.ContentStr)
        );
      }
      console.log(JSON.parse(res.response.ContentStr));
    },
    //  页面编辑保存
    pageEidt: function () {
      let data = {
        id: this.pageId,
        // nav_id: this.nav_id || 1,
        data: this.diylist,
      };
      this.loading = true;
      editPage(data)
        .then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ path: "/store/shopDecoration/pageTab" });
          }, 1500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 保存
    onSubmit: function () {
      console.log(this.diylist, "");
      const instance = axios.create({
        baseURL: serverSrc, // api 的 base_url
        timeout: 20000, // request timeout
        withCredentials: false, // 禁止携带 Cookie
      });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let param = {
        ContentStr: JSON.stringify(this.diylist),
        Id: this.templateId,
      };
      // param = JSON.stringify(param);
      instance
        .post("api/designTemplate/SaveDesign", param, config)
        .then((response) => {
          if (response.status == 200) {
            this.$message.success("编辑成功");
          } else {
            this.$message.error("编辑失败");
          }
          console.log(response);
        })
        .catch((err) => {
          this.$message.error("编辑失败");
        });
      // return;
      // if (this.pageId) {
      //   if (this.type) {
      //     this.addpagesave();
      //   } else {
      //     this.pageEidt();
      //   }
      // } else {
      //   this.addPage();
      // }
    },
    // 获取自定页面详情
    getPagedetail: function () {
      let data = {
        id: this.pageId,
      };
      Pagedetail(data)
        .then((response) => {
          this.pageInfo = response.result;
          let data = response.result.data;
          if (data !== "" && data !== undefined && data !== null) {
            if (data.constructor === Array) {
              this.$store.dispatch("updataDiyList", data);
            }
          }
        })
        .catch(() => {});
    },
    // 获取大平台自定页面详情
    getptpagedetail: function () {
      let data = {
        page_id: this.pageId,
      };
      ptpagedetail(data)
        .then((response) => {
          this.pageInfo = response.result;
          this.list_id = response.result.id;
          let data = response.result.page_data;
          if (data !== "" && data !== undefined && data !== null) {
            console.log(data.constructor);
            if (data.constructor === Array) {
              this.$store.dispatch("updataDiyList", data);
            }
          }
        })
        .catch(() => {});
    },

    // 改变页面标题
    changeHeader: function () {
      this.$store.commit("SETDIYITEM", this.pageInfo);
      this.$store.commit("SETDIYKEY", "");
    },
    // 页面新增保存
    addPage: function () {
      let ruleForm = this.$route.query.ruleForm;
      if (ruleForm) {
        ruleForm = JSON.parse(ruleForm);
        this.pageInfo = ruleForm;
      }
      this.loading = true;
      ruleForm.data = this.diylist;
      addPage(ruleForm)
        .then((response) => {
          this.$message.success(response.message);
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ path: "/store/shopDecoration/pageTab" });
          }, 1500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 大平台-引用系统模板并保存
    addpagesave: function () {
      let data = {
        list_id: this.list_id,
        data: this.diylist,
        page_id: this.pageId,
      };
      this.loading = true;
      addpagesave(data)
        .then((response) => {
          console.log(response);
          this.$message.success(response.message);
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ path: "/store/shopDecoration/pageTab" });
          }, 1500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 前去pc  建站
    gotopc: function () {
      this.$router.push("/pcSite");
    },
  },
};
</script>

<style scoped lang="scss">
.height {
  height: 60px;
}

.store_name {
  text-align: left;
  padding: 20px;
}

.store_template {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.store {
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  .preview {
    width: 375px;
    min-width: 375px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    margin-right: 40px;
    background-color: #fff;

    .preview_header {
      height: 64px;
      width: 375px;
      position: relative;
      cursor: pointer;
      // background: url("public/images/default/store/header-white.jpg");
      background: url("../assets/images/default/store/header-white.jpg");
      background-size: 100% 100%;

      .preview_title {
        display: inline-block;
        padding: 24px 60px 0;
        height: 64px;
        line-height: 45px;
        width: 375px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
      }
    }

    .preview_component {
      height: 600px;
      overflow: hidden;
    }

    .preview_component_child {
      width: 392px;
      /*overflow-y: scroll;*/
    }
  }
}

.store_fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-box-shadow: 0 -3px 5px #eee;
  box-shadow: 0 -3px 5px #eee;
  display: flex;

  .store_button {
    margin: auto;
    margin-left: 50%;
  }
}
</style>
