import request from '@/utils/request'

// 专家 列表 
export function QueryArtist(data) {
    return request({
        url: 'api/DesignTemplate/QueryArtist',
        method: 'get',
        params: data
    })
}

// 领域分类 
export function QueryArtistCategory(data) {
    return request({
        url: 'api/DesignTemplate/QueryArtistCategory',
        method: 'get',
        params: data
    })
}