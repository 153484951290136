import picUrl from "../assets/images/default/store/default.png";
import bgMoreUrl from "../assets/images/default/store/bg_more.png";

const configList = [
  // 顶部标题
  {
    style: {
      background: "#ffffff",
      color: "#333333",
      paddingtop: 10,
      paddingleft: 15,
      textalign: "center",
      fontsize: 14,
      iconsize: 18,
      iconColor: "#333333",
    },
    data: {
      return: {
        icon: "1",
        nolink: "1",
        linkurl: "",
      },
      mail: {
        icon: "1",
        nolink: "1",
        linkurl: "",
      },
    },
    name: "顶部标题",
    id: "headerTitle",
  },
  // banner
  {
    params: {
      catedata: {},
    },
    style: {
      dotstyle: "round",
      dotalign: "center",
      background: "#ffffff",
      opacity: "0.8",
    },
    data: [{
        imgurl: picUrl,
        linkurl: "",
        linkType: "system",
      },
      {
        imgurl: picUrl,
        linkurl: "",
        linkType: "system",
      },
    ],
    name: "轮播广告",
    id: "banner",
  },
  // 公告
  {
    style: {
      type: 1,
      background: "#ffffff",
      color: "#333333",
      placeholder: "测试",
      paddingtop: 10,
      paddingleft: 15,
      iconurl: "icon-gonggao",
      iconType: 1,
      imgUrl: "",
    },
    data: {
      title: "",
      type: 1,
    },
    name: "公告栏",
    id: "notice",
  },
  // 图文导航
  {
    style: {
      navstyle: "",
      background: "#ffffff",
      rownum: 4,
      showtype: 0,
      pagenum: 4,
      showdot: "show",
      dotstyle: "radius",
    },
    data_temp: [],
    data_line: 1,
    data: [{
        imgurl: picUrl,
        linkurl: "",
        text: "按钮文字1",
        color: "#333333",
      },
      {
        imgurl: picUrl,
        linkurl: "",
        text: "按钮文字2",
        color: "#333333",
      },
      {
        imgurl: picUrl,
        linkurl: "",
        text: "按钮文字3",
        color: "#333333",
      },
      {
        imgurl: picUrl,
        linkurl: "",
        text: "按钮文字4",
        color: "#333333",
      },
    ],
    name: "图文导航",
    id: "menu",
  },
  // 标题
  {
    style: {
      background: "#ffffff",
      color: "#333333",
      paddingtop: 10,
      paddingleft: 15,
      fontsize: 17,
      rightsize: 12,
      iconsize: 18,
      rightIcon: 1,
      textalign: "left",
      iconColor: "#656565",
      iconType: 1,
      rightbgurl: bgMoreUrl,
    },
    params: {
      linkurl: "",
    },
    data: {
      title: "",
      rightText: "更多..",
      imgUrl: "",
      icon: "icon-gonggao",
    },
    name: "标题栏",
    id: "title",
  },
  // 课程
  {
    params: {
      styleTemplate: "1",
      showprice: "1",
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
      showsales: "0",
      salestext: "销量",
      buytext: "购买",
      imgborder: 8,
    },
    style: {
      buybtncolor: "#000000",
      buybtntextcolor: "#E2E2E2",
      padding: 15,
      goodsmargin: 15,
      titleSize: 15,
      titlecolor: "#636466 ",
      describeSize: 12,
      describecolor: "#ABABAB",
      pricecolor: "#CE0000 ",
      productpricecolor: "#B7B7B7",
      title2color: "#636466 ",
    },
    cate_goods: [{
        t_image: picUrl,
        market_price: "20.00",
        sale_price: "99.00",
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        avatar: picUrl,
        name: "教师",
        TeacherInfo: [],
        Temp1: '课程时间：',
        Temp2: '课程地点：',
        Temp3: '适龄人群：',
        CourseType: '0',
      },
      {
        t_image: picUrl,
        market_price: "20.00",
        sale_price: "99.00",
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        avatar: picUrl,
        name: "教师",
        TeacherInfo: [],
        Temp1: '课程时间：',
        Temp2: '课程地点：',
        Temp3: '适龄人群：',
        CourseType: '0',
      },
    ],
    name: "课程模块",
    id: "courses",
  },
  // 在线艺术
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      iconColor: "#AEAEAE ",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        ViewCount: '100',
        CollectCount: '100'
      },
      {
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        ViewCount: '100',
        CollectCount: '100'
      },
      {
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        ViewCount: '100',
        CollectCount: '100'
      },
    ],
    name: "在线艺术",
    id: "art",
  },
  // 在线展览
  {
    params: {
      styleTemplate: "1",
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      buybtncolor: "#000000",
      buybtntextcolor: "#E2E2E2",
      describeSize: 12,
      describecolor: "#ABABAB",
      title2color: "#636466 ",
      titlecolor: "#373737",
      iconColor: "#AEAEAE ",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
      t_image: picUrl,
      title: "傅榆翔《盲区》",
      describe: "很多年轻艺术家对于这个信息化时代带来的图像冲击的理解总 是“陈列式”的。将没有转译过，或者深度理解化过的图像简单 形式化之....",
      id: "",
      t_image: picUrl,
      images: [
        picUrl,
        picUrl,
        picUrl,
        picUrl,
        picUrl,
        picUrl,
        picUrl,
        picUrl,
      ],
    }, ],
    name: "在线展览",
    id: "exhibition",
  },
  // 商城模块
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
    },
    style: {
      bordercolor: "#AFAFAF",
      pricecolor: "#CE0000",
      pricesize: 14,
      productpricecolor: "#B7B7B7",
      productpricesize: 12,
      jfsize: 12,
      titlecolor: "#373737",
      iconColor: "#AEAEAE ",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        market_price: "20.00",
        sale_price: "99.00",
        title: "艺术装饰画具画材教材图书积分商城国家图书艺术装饰画具画材教材图书积分商城国家图书",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        IsPreferential: 1,
        IsRecommend: 1,
        IsNew: 1,
        IsPlatformDiscount: 1,
        IsCoupon: 1,
        PayMehtod: 1
      },
      {
        market_price: "20.00",
        sale_price: "99.00",
        title: "艺术装饰画具画材教材",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        IsPreferential: 1,
        IsRecommend: 1,
        IsNew: 1,
        IsPlatformDiscount: 1,
        IsCoupon: 1,
        PayMehtod: 1
      },
      {
        market_price: "20.00",
        sale_price: "99.00",
        title: "艺术装饰画具画材教材图书积分商城国家图书",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        IsPreferential: 1,
        IsRecommend: 1,
        IsNew: 1,
        IsPlatformDiscount: 1,
        IsCoupon: 1,
        PayMehtod: 1
      },
      {
        market_price: "20.00",
        sale_price: "99.00",
        title: "艺术装饰画具画材教材图书积分商城国家图书",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
        IsPreferential: 1,
        IsRecommend: 1,
        IsNew: 1,
        IsPlatformDiscount: 1,
        IsCoupon: 1,
        PayMehtod: 1
      },
    ],
    name: "商城模块",
    id: "goods",
  },
  // 资讯动态
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      describecolor: "#7E7E7E ",
      iconColor: "#AEAEAE ",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        t_image: picUrl,
        title: "三合美术馆 | 艺术顾问傅舟",
        describe: "傅舟教授擅长书法、篆刻及花鸟，专业与理论修养深厚，艺术感悟力敏锐，文学功底淳厚傅舟教授擅长书法、篆刻及花鸟，专业与理论修养深厚，艺术感悟力敏锐，文学功底淳厚",
        id: "",
        date: '2022-10-10',
        ReadCount: '10',
      },
      {
        t_image: picUrl,
        title: "三合美术馆 | 艺术顾问傅舟",
        describe: "傅舟教授擅长书法、篆刻及花鸟，专业与理论修养深厚，艺术感悟力敏锐，文学功底淳厚傅舟教授擅长书法、篆刻及花鸟，专业与理论修养深厚，艺术感悟力敏锐，文学功底淳厚",
        id: "",
        date: '2022-10-10',
        ReadCount: '100',
      },
    ],
    name: "资讯动态",
    id: "dynamic",
  },
  // 资料下载
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      timecolor: "#afafaf",
      padding: 15,
      goodsmargin: 15,
    },
    cate_goods: [{
        t_image: picUrl,
        title: "展览地点：三合美术馆",
        id: "",
        time: "2022-04-02",
        Price: 0,
      },
      {
        t_image: picUrl,
        title: "三合美术馆 | 艺术顾问傅舟",
        id: "",
        time: "2022-04-02",
        Price: 100,
      },
    ],
    name: "资料下载",
    id: "dataDownload",
  },
  // 讲师团队
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      bordercolor: "#AFAFAF",
      bordersize: 3,
      padding: 15,
      goodsmargin: 15,
      imgborder: 50,
    },
    cate_goods: [{
        t_image: picUrl,
        title: "阴怡然",
        describe: "艺术理论/艺术教育/专业写作 ",
        id: "",
      },
      {
        t_image: picUrl,
        title: "傅榆翔",
        describe: "艺术理论/艺术教育/专业写作 ",
        id: "",
      },
      {
        t_image: picUrl,
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
      },
    ],
    name: "讲师团队",
    id: "lecturer",
  },
  // 专家团队
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      bordercolor: "#AFAFAF",
      bordersize: 3,
      padding: 15,
      goodsmargin: 15,
      imgborder: 50,
    },
    cate_goods: [{
        t_image: picUrl,
        title: "阴怡然",
        describe: "素描优秀教师,素描优秀教师",
        CategoryName: '素描艺术家',
        id: "",
      },
      {
        t_image: picUrl,
        title: "傅榆翔",
        describe: "素描优秀教师,素描优秀教师",
        CategoryName: '素描艺术家',
        id: "",
      },
    ],
    name: "专家团队",
    id: "expert",
  },
  // 合作机构
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
    },
    style: {
      bordercolor: "#b7b7b7",
      timecolor: "#CE0000",
      describecolor: "#B7B7B7",
      titlecolor: "#333333",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        title: "三合美术馆",
        describe: "艺术展览/研学基地",
        time: "2022.02.03",
        id: "",
        t_image: picUrl,
      },
      {
        title: "原画设计",
        describe: "动画设计/原画设计",
        time: "2022.02.03",
        id: "",
        t_image: picUrl,
      },
      {
        title: "三合美术馆",
        describe: "艺术展览/研学基地",
        time: "2022.02.03",
        id: "",
        t_image: picUrl,
      },
      {
        title: "原画设计",
        describe: "动画设计/原画设计",
        time: "2022.02.03",
        id: "",
        t_image: picUrl,
      },
    ],
    name: "合作机构",
    id: "organization",
  },
  // 合作机构
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
    },
    style: {
      worknumcolor: "#CE0000",
      describecolor: "#B7B7B7",
      titlecolor: "#333333",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        title: "艺术家",
        describe: "日本知名艺术家",
        time: "2022.02.03",
        worknum: 213,
        avatar: picUrl,
        images: [picUrl, picUrl, picUrl],
        id: "",
      },
      {
        title: "艺术家",
        describe: "日本知名艺术家",
        time: "2022.02.03",
        worknum: 213,
        avatar: picUrl,
        images: [picUrl, picUrl, picUrl],
        id: "",
      },
    ],
    name: "艺术模块",
    id: "artist",
  },
  // 艺术推荐
  {
    params: {
      catedata: {},
      goodsiconsrc: "",
    },
    style: {
      titlecolor: "#373737",
      iconColor: "#AEAEAE ",
      iconsize: 12,
      padding: 15,
      goodsmargin: 15,
      imgborder: 0,
    },
    cate_goods: [{
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
      },
      {
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
      },
      {
        title: "傅榆翔《盲区》",
        describe: "55×95×80cm-不锈钢-2018",
        id: "",
        t_image: picUrl,
      },
    ],
    name: "艺术推荐",
    id: "artRecommend",
  },
  // 艺术活动
  {
    params: {
      styleTemplate: "2",
      showprice: "1",
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
      showsales: "0",
      imgborder: 8,
    },
    style: {
      buybtncolor: "#000000",
      buybtntextcolor: "#E2E2E2",
      padding: 15,
      goodsmargin: 15,
      titleSize: 15,
      titlecolor: "#636466 ",
      describeSize: 12,
      describecolor: "#ABABAB",
      pricecolor: "#CE0000 ",
      productpricecolor: "#B7B7B7",
      title2color: "#636466 ",
    },
    cate_goods: [{
        t_image: picUrl,
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        date: '2020/8/8——2022/8/8',
        CategoryName: "分类",
        Address: '三合艺术馆',
      },
      {
        t_image: picUrl,
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        date: '2020/8/8——2022/8/8',
        CategoryName: "分类",
        Address: '三合艺术馆',
      },
    ],
    name: "艺术活动",
    id: "activity",
  },
  // 艺术展览
  {
    params: {
      styleTemplate: "2",
      showprice: "1",
      catedata: {},
      goodsiconsrc: "",
      showproductprice: "1",
      showsales: "0",
      imgborder: 8,
    },
    style: {
      buybtncolor: "#000000",
      buybtntextcolor: "#E2E2E2",
      padding: 15,
      goodsmargin: 15,
      titleSize: 15,
      titlecolor: "#636466 ",
      describeSize: 12,
      describecolor: "#ABABAB",
      pricecolor: "#CE0000 ",
      productpricecolor: "#B7B7B7",
      title2color: "#636466 ",
    },
    cate_goods: [{
        t_image: picUrl,
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        date: '2020/8/8——2022/8/8',
        CategoryName: "分类",
        Address: '三合艺术馆',
      },
      {
        t_image: picUrl,
        title: "这里是商品标题最多显示两行这里是商品标题最多显示两行",
        describe: "这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行这里是商品描述最多显示一行",
        id: "",
        date: '2020/8/8——2022/8/8',
        CategoryName: "分类",
        Address: '三合艺术馆',
      },
    ],
    name: "艺术展览",
    id: "artExhibition",
  },
  // 视频专区
  {
    cate_goods: [{
      title: '视频1',
      t_image: picUrl,
      viewers: '999',
      duration: '4:20:20',
      originalPrice: "3000",
      discountPrice: '2000'
    }, {
      title: '视频2',
      t_image: picUrl,
      viewers: '1999',
      duration: '2:20:20',
      originalPrice: "",
      discountPrice: ''
    }],
    style: {
      titlecolor: "#ffffff",
      peoplecolor: "#ffffff",
      durationcolor: "#ffffff",
      originalPricecolor: '#A6A6A6',
      discountPricecolor: '#FF0000',
      padding: 15,
      goodsmargin: 15,
    },
    name: '视频专区',
    id: "videos"
  }
];

export default configList;