<template>
  <div
    class="swipe_main"
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <div
      :style="{
        marginRight: diyitem.style.goodsmargin + 'px',
      }"
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-art-item"
      :key="index"
    >
      <img
        v-if="childitem.HeadImg == null"
        :src="childitem.t_image"
        class="t_image"
        alt=""
        srcset=""
        :style="{
          borderRadius: diyitem.style.imgborder + '%',
        }"
      />
      <img
        v-else
        :src="childitem.HeadImg"
        class="t_image"
        alt=""
        srcset=""
        :style="{
          borderRadius: diyitem.style.imgborder + '%',
        }"
      />
      <div
        :style="{
          borderRight:
            diyitem.style.bordersize + 'px solid' + diyitem.style.bordercolor,
        }"
        style="padding: 0 35px"
      >
        <div
          class="title ellipsis-1"
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          {{ childitem.title }}
        </div>
        <div class="describe flex-between">
          <div
            class="describe-box"
            :style="{
              color: diyitem.style.titlecolor,
            }"
          >
            {{ childitem.describe | intercept(20) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_art",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-art-item {
  text-align: center;
  line-height: 1.5;
  width: 170px;
  flex: 0 0 170px;
  margin-top: 15px;
  .t_image {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }
  .title {
    color: #373737;
    font-weight: bold;
    font-size: 15px;
    margin: 12px 0 6px;
  }
  .describe {
    color: #373737;
    font-size: 13px;
    margin-bottom: 10px;
    word-break: break-all;
  }
  .flex-between {
    align-items: flex-start;
  }
  .describe-box {
    width: 100px;
    height: 40px;
    // white-space: nowrap; // 规定段落中的文本不换行
    text-overflow: ellipsis; //文本溢出部分用省略号代替
    display: -webkit-box; // 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示
    -webkit-box-orient: vertical; // 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式
    -webkit-line-clamp: 2; // 用来限制在一个块元素显示的文本的行数
    overflow: hidden; // 溢出元素不显示
  }
}
</style>