<template>
  <div class="btn-upload">
    <el-upload
      class="avatar-uploader"
      action=""
      multiple
      :http-request="requestUpload"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
    >
      <el-button type="primary">上传图片</el-button>
    </el-upload>
  </div>
</template>
<script>
import axios from "axios";
import { Loading } from "element-ui";
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "ButtonUpload",
  props: {
    group_id: {},
  },
  data() {
    return {};
  },
  methods: {
    requestUpload: function (file) {
      let param = new FormData(); // 创建form对象
      // param.append('file', file.file, 'file_' + Date.parse(new Date()) + '.jpg') // 文件对象
      param.append("file", file.file);
      // param.append("group_id", this.group_id);
      this.upImage(param);
    },
    // 上传图片
    upImage: function (param) {
      let loadingInstance = Loading.service({
        lock: true,
        text: "加载中...",
        // spinner: 'my-el-custom-spinner',
        customClass: "my-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      const instance = axios.create({
        baseURL: serverSrc, // api 的 base_url
        timeout: 20000, // request timeout
        withCredentials: false, // 禁止携带 Cookie
      });
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      instance
        .post("api/DesignTemplate/UploadPic", param, config)
        .then((response) => {
          loadingInstance.close();
          if (response.status == 200) {
            this.$emit("imgData", response);
          } else {
            this.$message.error(response.msg);
          }
        })
        .catch(() => {
          loadingInstance.close();
          this.$message.error("上传失败，请稍后再试");
        });
    },
    //  上传之前的钩子
    beforeAvatarUpload(file) {
      const isJPG = file.type == "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    beforeUpload() {
      return new Promise((resolve, reject) => {});
    },
  },
};
</script>

<style scoped></style>
