<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <el-row :gutter="diyitem.style.goodsmargin">
      <el-col
        :span="12"
        :style="{
          marginTop: diyitem.style.goodsmargin / 2 + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-goods-item"
        :key="index"
      >
        <div style="border: 1px solid #afafaf; padding-bottom: 10px">
          <div class="img-group">
            <img
              v-if="childitem.IsPreferential == 1"
              src="../../assets/images/default/store/icon_youhui3.png"
              alt=""
              srcset=""
              class="img1"
            />
            <img
              v-if="childitem.IsRecommend == 1"
              src="../../assets/images/default/store/icon_tuijian3.png"
              alt=""
              srcset=""
              class="img1"
            />
            <img
              v-if="childitem.IsNew == 1"
              src="../../assets/images/default/store/icon_zuixin3.png"
              alt=""
              srcset=""
              class="img1"
            />&nbsp;
            <img
              v-if="childitem.IsPlatformDiscount == 1"
              src="../../assets/images/default/store/icon_vip2.png"
              alt=""
              srcset=""
              class="img2"
            />
            <img
              v-if="childitem.IsCoupon == 1"
              src="../../assets/images/default/store/icon_yhq2.png"
              alt=""
              srcset=""
              class="img2"
            />
            <img
              v-if="childitem.PayMehtod != 3"
              src="../../assets/images/default/store/icon_jf2.png"
              alt=""
              srcset=""
              class="img2"
            />
            <!-- <img
              src="../../assets/images/default/store/icon_mj2.png"
              alt=""
              srcset=""
              class="img2"
            /> -->
          </div>
          <img
            :src="childitem.t_image"
            class="t_image"
            alt=""
            srcset=""
            :style="{
              borderRadius: diyitem.style.imgborder + 'px',
            }"
          />
          <div
            class="title ellipsis-2"
            :style="{
              color: diyitem.style.titlecolor,
            }"
          >
            {{ childitem.title | intercept(20) }}
          </div>
          <div class="price">
            <span
              class="market_price"
              :style="{
                color: diyitem.style.pricecolor,
                fontSize: diyitem.style.pricesize + 'px',
              }"
              >￥{{ childitem.market_price }}</span
            >
            &nbsp;&nbsp;
            <span
              v-if="diyitem.params.showproductprice == '1'"
              class="sale_price"
              :style="{
                color: diyitem.style.productpricecolor,
                fontSize: diyitem.style.productpricesize + 'px',
              }"
              >￥{{ childitem.sale_price }}</span
            >
            <span
              v-if="diyitem.params.showproductprice == '2'"
              class="sale_price"
              :style="{
                color: diyitem.style.pricecolor,
                fontSize: diyitem.style.jfsize + 'px',
              }"
            >
              <span>积</span>
              {{ childitem.sale_price }}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "tpl_goods",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {
    IsIcon(icon) {
      if (icon !== 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-goods-item {
  text-align: left;
  line-height: 1.5;
  padding-bottom: 10px;
  .img-group {
    img {
      width: 12px;
      height: 12px;
      margin-right: 3px;
    }
    .img1 {
      width: 24px;
    }
  }
  .t_image {
    width: 100%;
    height: 207px;
    display: block;
  }
  .title {
    color: #7e7e7e;
    font-weight: bold;
    font-size: 12px;
    height: 36px;
    margin: 12px 0 6px;
    padding: 0 6px;
  }
  .price {
    color: #ce0000;
    font-size: 14px;
    padding: 0 6px;
  }
  .sale_price {
    text-decoration: line-through;
  }
}
</style>
