<template>
  <el-dialog
    title="选择跳转链接"
    :visible.sync="chooseShow"
    :width="dialogWidth"
    :append-to-body="true"
    class="upload-demo-zqj"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="item.CategoryName"
        :name="item.Id + ''"
        v-for="(item, index) in topList"
        :key="index"
      ></el-tab-pane>
    </el-tabs>
    <div class="dialog-body">
      <div class="dialog-left">
        <ul>
          <!-- <li
            v-for="(it, ix) in leftList"
            :key="ix"
            :class="{ 'is-acyive': ix == curIndex }"
            @click="changeLeftIndex(ix)"
          >
            {{ it.Name }}
          </li> -->
          <li v-for="(it, ix) in leftList" :key="ix" @click="selectItem(it)">
            {{ it.Name }}
          </li>
        </ul>
      </div>
      <div class="dialog-right">
        <div
          v-for="(itm, idx) in rightList"
          :key="idx"
          @click="selectItem(itm)"
        >
          {{ itm.Name }}
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="colseChoose(1)">确定</el-button>
      <el-button @click="colseChoose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { pageMenu } from "@/api/public";
export default {
  name: "index",
  data() {
    return {
      chooseShow: false,
      dialogWidth: "800px",
      seleItem: "",
      activeName: "",
      linkList: [],
      topList: [], // 顶部一级选项列表
      leftList: [], // 左边二级选项列表
      rightList: [], // 右边三级级选项列表
      curIndex: 0, // 二级选中index
    };
  },
  mounted() {},
  methods: {
    // 处理供选中数据
    handleAllList() {
      this.curIndex = 0;
      this.linkList.forEach((item) => {
        if (item.Id == this.activeName) {
          if (item.Links.length > 0) {
            this.leftList = item.Links;
          } else {
            this.leftList = [];
          }
        }
      });
      // this.rightList = this.leftList[this.curIndex].Links;
    },
    // 选择二级选项
    changeLeftIndex(ix) {
      if (this.curIndex == ix) return;
      this.curIndex = ix;
      this.rightList = this.leftList[this.curIndex].Links;
    },
    // 选择一级选项
    handleClick() {
      this.handleAllList();
    },
    // 获取跳转链接
    getpageMenu() {
      let data = {
        pageIndex: this.page,
        pageSize: this.page_size,
      };
      this.topList = [];
      pageMenu(data)
        .then((response) => {
          this.linkList = response.response;
          this.linkList.forEach((item) => {
            this.topList.push(item);
          });
          this.activeName = this.topList[0].Id + "";
          this.handleAllList();
        })
        .catch();
    },
    // 打开链接选择弹框
    showChoose() {
      this.getpageMenu();
      this.chooseShow = true;
    },
    // 普通跳转链接
    selectItem(item) {
      console.log(item);
      this.chooseShow = false;
      let linkurl = item.LinkUrl;
      let linkname = item.Name;
      let data = {
        linkurl,
        linkname,
      };
      this.$emit("selectItem", data);
      this.seleItem = item;
    },
    // 确定或者取消
    colseChoose(type) {
      if (type == 1 && !this.seleItem) {
        this.$message.error("请选择链接");
        return "";
      }
      this.chooseShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.scope_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.label_ul {
  li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
  }
}
.dialog-body {
  display: flex;
  .dialog-left {
    width: 140px;
    margin-right: 20px;
    ul {
      li {
        height: 32px;
        line-height: 32px;
        min-width: 100px !important;
        padding-left: 20px !important;
        font-size: 12px;
        cursor: pointer;
      }
      .is-acyive {
        color: #7536d0;
        background: rgba(116, 56, 213, 0.14);
        border-radius: 4px;
      }
    }
  }
  .dialog-right {
    padding-left: 20px;
    div {
      padding: 0 16px;
      height: 32px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      color: #444;
      cursor: pointer;
      margin-right: 14px;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
