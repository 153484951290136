<template>
  <div>
    <div
      v-if="diyitem.params.styleTemplate == '1'"
      :style="{
        paddingLeft: diyitem.style.padding + 'px',
        paddingRight: diyitem.style.padding + 'px',
      }"
    >
      <div
        :style="{
          marginTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-exhibition-item"
        :key="index"
      >
        <img
          v-if="childitem.CoverImg == null"
          :src="childitem.t_image"
          class="t_image"
          alt=""
          srcset=""
          :style="{
            borderRadius: diyitem.style.imgborder + 'px',
          }"
        />
        <img
          v-else
          :src="childitem.CoverImg"
          class="t_image"
          alt=""
          srcset=""
          :style="{
            borderRadius: diyitem.style.imgborder + 'px',
          }"
        />
        <div
          class="title ellipsis-1"
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          <template v-if="childitem.Title == null">
            {{ childitem.title }}
          </template>
          <template v-else>
            {{ childitem.Title }}
          </template>
        </div>
        <div class="describe">
          <p
            :style="{
              color: diyitem.style.describecolor,
            }"
          >
            <template v-if="childitem.Introduce == null">
              {{ childitem.describe }}
            </template>
            <template v-else>
              {{ childitem.Introduce }}
            </template>
          </p>
          <p
            :style="{
              color: diyitem.style.iconColor,
              fontSize: diyitem.style.iconsize + 'px',
            }"
          >
            <span>
              <i class="iconfont el-icon-view"></i>
              <template v-if="childitem.ReadCount == null">
                {{ 0 }}
              </template>
              <template v-else>
                {{ childitem.ReadCount }}
              </template>
            </span>
            &nbsp; &nbsp;
            <span>
              <i class="iconfont icon-custom-collection"></i>
              0
            </span>
            &nbsp; &nbsp;
            <span>
              <i class="el-icon-chat-dot-round"></i>
              0
            </span>
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="diyitem.params.styleTemplate == '2'"
      class="fui-courses-group"
      :class="diyitem.params.styleTemplate == '2' ? 'fui-courses-group2' : ''"
      :style="{
        paddingLeft: diyitem.style.padding + 'px',
        paddingRight: diyitem.style.padding + 'px',
      }"
    >
      <div
        :style="{
          paddingTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-courses-item"
        :key="index"
      >
        <div class="fui-courses-item-top">
          <img
            class="salez"
            :src="childitem.t_image"
            :style="{
              borderRadius: diyitem.style.imgborder + 'px',
            }"
          />
          <div class="fui-courses-item-title">
            <div>
              <div
                :style="{
                  fontSize: diyitem.style.titleSize + 'px',
                  color: diyitem.style.titlecolor,
                }"
                class="overflow"
              >
                {{ childitem.title | intercept(11) }}
              </div>
              <div
                class="suitable"
                :style="{
                  color: diyitem.style.title2color,
                }"
              >
                <p>展览时间：2022/4/2——5/31</p>
              </div>
            </div>
            <div class="detail-right flex-between">
              <div
                class="detail-right-playType"
                :style="{
                  color: diyitem.style.buybtntextcolor,
                  backgroundColor: diyitem.style.buybtncolor,
                }"
              >
                直播
              </div>
              <i
                class="iconfont icon-custom-share"
                :style="{
                  fontSize: diyitem.style.iconsize + 'px',
                  color: diyitem.style.buybtntextcolor,
                }"
              ></i>
            </div>
          </div>
        </div>
        <div class="detail">
          <div
            :style="{
              fontSize: diyitem.style.describeSize + 'px',
              color: diyitem.style.describecolor,
            }"
          >
            {{ childitem.describe | intercept(62) }}
          </div>
        </div>
        <div class="swipe_main images">
          <img
            :src="ci"
            alt=""
            srcset=""
            v-for="(ci, i) in childitem.images"
            :key="i"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_exhibition",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-exhibition-item {
  text-align: left;
  line-height: 1.5;
  .t_image {
    width: 100%;
    height: 207px;
    display: block;
  }
  .title {
    color: #373737;
    font-weight: bold;
    font-size: 15px;
    margin: 12px 0 6px;
  }
  .describe {
    color: #373737;
    font-size: 13px;
  }
}

.fui-courses-group {
  text-align: left;
  line-height: 1.5;
  .fui-courses-item {
    border-bottom: 1px solid #afafaf;
    .fui-courses-item-top {
      display: flex;
      .salez {
        flex: 0 0 167px;
        width: 167px;
        height: 86px;
      }
      .fui-courses-item-title {
        margin-left: 13px;
        font-size: 15px;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0 12px;
      .detail-left {
        display: flex;
        align-items: center;
        .detail-left-avatar {
          width: 21px;
          height: 21px;
          border-radius: 50%;
        }
        .detail-left-name {
          display: inline-block;
          min-width: 67px;
          margin: 0 10px;
          font-size: 12px;
        }
        .market_price {
          font-size: 14px;
        }
        .sale_price {
          font-size: 12px;
        }
      }
    }
    .detail-right {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-left: 2px;
        &:first-child {
          margin-left: 0;
        }
      }
      .detail-right-playType {
        width: 142px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        border-radius: 10px;
        margin-left: 4px;
      }
    }
  }
}
.fui-courses-group2 {
  .fui-courses-item {
    .fui-courses-item-top {
      display: flex;
      .salez {
        flex: 0 0 117px;
        width: 117px;
        height: 164px;
      }
    }
    .fui-courses-item-title {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      margin-left: 18px !important;
    }
    .detail-right {
      .detail-right-playType {
        margin: 0 8px 0 0;
      }
    }
    .suitable {
      font-size: 12px;
      color: #7e7e7e;
      margin-bottom: 9px;
    }
    .images {
      overflow: hidden;
      img {
        height: 76px;
        display: block;
        margin-right: 7px;
      }
    }
  }
}
</style>