<template>
  <div
    class="swipe_main"
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <div
      :style="{
        marginRight: diyitem.style.goodsmargin + 'px',
        marginTop: diyitem.style.goodsmargin + 'px',
      }"
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-art-item"
      :key="index"
    >
      <img
        v-if="childitem.ArtistHeadImg == null"
        :src="childitem.t_image"
        class="t_image"
        alt=""
        srcset=""
        :style="{
          borderRadius: diyitem.style.imgborder + 'px',
        }"
      />
      <img
        v-else
        :src="childitem.ArtistHeadImg"
        class="t_image"
        alt=""
        srcset=""
        :style="{
          borderRadius: diyitem.style.imgborder + 'px',
        }"
      />
      <div
        class="title ellipsis-1"
        :style="{
          color: diyitem.style.titlecolor,
        }"
      >
        <template v-if="childitem.ArtTitle == null">
          {{ childitem.title }}
        </template>
        <template v-else>
          {{ childitem.ArtTitle }}
        </template>
      </div>
      <div class="describe flex-between">
        <p
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          <template v-if="childitem.ArtSiezName == null">
            {{ childitem.describe }}
          </template>
          <template v-else>
            {{ childitem.ArtSiezName }}
          </template>
        </p>
        <p
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        >
          <span>
            <i class="iconfont icon-custom-collection"></i>
            <template v-if="childitem.CollectCount == null">
              {{ 451 }}
            </template>
            <template v-else>
              {{ childitem.CollectCount }}
            </template>
          </span>
          &nbsp;
          <span>
            <i class="iconfont icon-custom-share"></i>
            <template v-if="childitem.ViewCount == null">
              {{ 450 }}
            </template>
            <template v-else>
              {{ childitem.ViewCount }}
            </template>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_art",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-art-item {
  text-align: left;
  line-height: 1.5;
  width: 261px;
  .t_image {
    width: 261px;
    height: 246px;
    display: block;
  }
  .title {
    color: #373737;
    font-weight: bold;
    font-size: 15px;
    margin: 12px 0 6px;
  }
  .describe {
    color: #373737;
    font-size: 13px;
  }
}
</style>