import request from '@/utils/request'

// 课程方向
export function QueryCourseCategoryList(data) {
    return request({
        url: 'api/DesignTemplate/QueryCourseCategoryList',
        method: 'get',
        params: data
    })
}

// 课程列表
export function QueryCourseList(data) {
    return request({
        url: 'api/DesignTemplate/QueryCourseList',
        method: 'get',
        params: data
    })
}

// 获取模板详细
export function QueryDesignDetail(data) {
    return request({
        url: 'api/designTemplate/QueryDesignDetail',
        method: 'get',
        params: data
    })
}

// 保存模板
export function SaveDesign(data) {
    return request({
        url: 'api/designTemplate/SaveDesign',
        method: 'post',
        data
    })
}