<template>
  <!-- 讲师管理 -->
  <div class="editor_content">
    <div class="editor_title">专家</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="专家管理" name="first" style="padding: 5px">
        <template>
          <el-row class="row-bg row-bg-list">
            <el-col :span="6"> 专家选择：</el-col>
            <el-col :span="18">
              <div class="flex-start-center">
                <el-tag
                  type="success"
                  style="margin-right: 15px"
                  v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
                  >{{ diyItem.params.catedata.title }}</el-tag
                >
                <div
                  class="pointer"
                  :style="{ color: defaultColor }"
                  @click="showSelect"
                >
                  选择专家
                </div>
              </div>
            </el-col>
          </el-row>
          <div
            class="img_list"
            v-for="(item, index) in diyItem.cate_goods"
            :key="index"
          >
            <el-row class="row-bg">
              <el-col :span="6">
                <div class="img_left">
                  <img v-if="item.HeadImg == null" :src="item.t_image" alt="" />
                  <img v-else :src="item.HeadImg" alt="" />
                  <div class="bton" @click="selectedgoods(index)">更换专家</div>
                </div>
              </el-col>
              <el-col :span="18">
                <el-row type="flex" style="align-items: center">
                  <el-col :span="6" class="name">专家名称</el-col>
                  <el-col :span="18">
                    <input
                      v-if="item.Name == null"
                      class="input"
                      type="text"
                      v-model="item.title"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                    <input
                      v-else
                      class="input"
                      type="text"
                      v-model="item.Name"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; margin-top: 10px"
                >
                  <el-col :span="6" class="name">专家介绍</el-col>
                  <el-col :span="18">
                    <input
                      v-if="item.ShortTitle == null"
                      class="input"
                      type="text"
                      v-model="item.describe"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                    <input
                      v-else
                      class="input"
                      type="text"
                      v-model="item.ShortTitle"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div
              class="img_list_remove"
              @click="removeChild(index)"
              style="color: #999999"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="样式管理" name="second">
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">标题颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.titlecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 页面边距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.padding"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
        <!-- <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 专家间距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.goodsmargin"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row> -->
        <!-- <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 图片圆角：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.imgborder"
              :min="0"
              :max="100"
            ></el-slider>
          </el-col>
        </el-row> -->
      </el-tab-pane>
    </el-tabs>

    <selectExpert @selectImgshow="goodsurl" ref="mygoods"></selectExpert>
  </div>
</template>

<script>
import selectExpert from "@/views/components/selectExpert";
export default {
  name: "edit_goods",
  components: {
    selectExpert,
  },
  data() {
    return {
      activeName: "first",
      options: [],
      index: "",
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
    diyKey: function () {
      return this.$store.state.diypage.diyKey;
    },
    diyList() {
      return this.$store.state.diypage.diyList;
    },
    defaultColor() {
      return this.$store.state.theme.defaultColor;
    },
  },
  mounted() {},
  methods: {
    // 分类选择回调
    updata: function (row) {
      let data = {
        cate_id: row.id,
      };
    },
    // 显示分类
    showSelect: function () {
      // this.$refs.cate.showDialog();
    },
    // 选择讲师
    selectedgoods: function (index) {
      this.index = index;
      this.$refs.mygoods.showimgselect();
    },
    // 讲师选择回调
    goodsurl: function (vlu) {
      let newObj = {
        describe: vlu.describe,
        id: vlu.id,
        t_image: vlu.t_image,
        title: vlu.title,
        CategoryName: vlu.CourseCategoryName,
      };
      if (this.index === "") {
        this.diyItem.cate_goods.push(newObj);
      } else {
        this.$set(this.diyItem.cate_goods, this.index, newObj);
        this.index = "";
      }
    },
    // 图片选择回调
    imgurl: function (vl) {
      this.$set(this.diyItem.params, "goodsiconsrc", vl);
    },
    // 选择图片
    selectedimg: function (index) {
      this.index = index;
      this.$refs.mychild.showimgselect();
    },
    // 删除子元素
    removeChild: function (index) {
      this.diyItem.cate_goods.splice(index, 1);
    },
    // 添加子元素
    addChild: function () {
      if (this.diyItem.cate_goods.length >= 10) {
        this.$message.error("最多添加10张图片");
        return "";
      }
      this.$refs.mygoods.showimgselect();
    },
  },
};
</script>

<style>
.el-radio {
  margin-bottom: 10px;
}
</style>
