<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <div
      :style="{
        marginTop: diyitem.style.goodsmargin + 'px',
      }"
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-exhibition-item"
      :key="index"
    >
      <el-row class="title">
        <el-col :span="4">
          <img class="img" :src="childitem.t_image" alt="" />
        </el-col>
        <el-col :span="16">
          <p
            class="ellipsis-1"
            :style="{
              color: diyitem.style.titlecolor,
            }"
          >
            {{ childitem.title }}
          </p>
          <p
            class="time"
            :style="{
              color: diyitem.style.timecolor,
            }"
          >
            {{ childitem.time }}
          </p>
          <p class="upload" v-if="childitem.Price == 0">免费下载</p>
          <p class="Price" v-else>￥{{ childitem.Price }}</p>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-arrow-right icon" style="font-size: 30px"></i>
        </el-col>
      </el-row>
      <!-- <div class="title flex-between">
        <p
          class="ellipsis-1"
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          {{ childitem.title }}
        </p>
        <p
          class="time"
          :style="{
            color: diyitem.style.timecolor,
          }"
        >
          {{ childitem.time }}
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_exhibition",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-exhibition-item {
  text-align: left;
  line-height: 1.5;
  border-bottom: 1px solid #b8b6b6;
  .title {
    color: #373737;
    font-size: 14px;
    margin: 12px 0 6px;
    display: flex;
    align-items: center;
  }
  .time {
    color: #afafaf;
    font-size: 12px;
  }
}
.img {
  width: 37px;
  height: 50px;
}
.upload {
  color: #f1c800;
}
.Price {
  color: red;
}
.icon {
  font-size: 30px;
  color: #e2e2e2;
}
</style>
