var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swipe_main",style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-art-item",style:({
      marginRight: _vm.diyitem.style.goodsmargin + 'px',
      marginTop: _vm.diyitem.style.goodsmargin + 'px',
    })},[(childitem.ArtistHeadImg == null)?_c('img',{staticClass:"t_image",style:({
        borderRadius: _vm.diyitem.style.imgborder + 'px',
      }),attrs:{"src":childitem.t_image,"alt":"","srcset":""}}):_c('img',{staticClass:"t_image",style:({
        borderRadius: _vm.diyitem.style.imgborder + 'px',
      }),attrs:{"src":childitem.ArtistHeadImg,"alt":"","srcset":""}}),_c('div',{staticClass:"title ellipsis-1",style:({
        color: _vm.diyitem.style.titlecolor,
      })},[(childitem.ArtTitle == null)?[_vm._v(" "+_vm._s(childitem.title)+" ")]:[_vm._v(" "+_vm._s(childitem.ArtTitle)+" ")]],2),_c('div',{staticClass:"describe flex-between"},[_c('p',{style:({
          color: _vm.diyitem.style.titlecolor,
        })},[(childitem.ArtSiezName == null)?[_vm._v(" "+_vm._s(childitem.describe)+" ")]:[_vm._v(" "+_vm._s(childitem.ArtSiezName)+" ")]],2),_c('p',{style:({
          color: _vm.diyitem.style.iconColor,
          fontSize: _vm.diyitem.style.iconsize + 'px',
        })},[_c('span',[_c('i',{staticClass:"iconfont icon-custom-collection"}),(childitem.CollectCount == null)?[_vm._v(" "+_vm._s(451)+" ")]:[_vm._v(" "+_vm._s(childitem.CollectCount)+" ")]],2),_vm._v("   "),_c('span',[_c('i',{staticClass:"iconfont icon-custom-share"}),(childitem.ViewCount == null)?[_vm._v(" "+_vm._s(450)+" ")]:[_vm._v(" "+_vm._s(childitem.ViewCount)+" ")]],2)])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }