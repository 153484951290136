<template>
    <el-scrollbar ref="scrollContainer"    wrap-class="scroll-bar-list" >
        <slot/>
    </el-scrollbar>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style   lang="scss">
    .scroll-bar-list{
        overflow-y: auto;
        overflow-x: hidden!important;
    }
</style>
