var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-art-item",style:({
      marginTop: _vm.diyitem.style.goodsmargin + 'px',
    })},[(childitem.t_image instanceof Array)?_c('div',{staticClass:"images flex-between"},[_c('img',{staticClass:"img1",attrs:{"src":childitem.t_image[0],"alt":"","srcset":""}}),_c('div',{staticClass:"flex ac-space-between fw-wrap",staticStyle:{"flex":"0 0 94px","height":"100%","margin-left":"13px"}},[_c('img',{staticClass:"img2",attrs:{"src":childitem.t_image[1],"alt":"","srcset":""}}),_c('img',{staticClass:"img2",attrs:{"src":childitem.t_image[2],"alt":"","srcset":""}})])]):_c('div',{staticClass:"images flex-between"},[_c('img',{staticClass:"img1",attrs:{"src":childitem.t_image,"alt":"","srcset":""}}),_c('div',{staticClass:"flex ac-space-between fw-wrap",staticStyle:{"flex":"0 0 94px","height":"100%","margin-left":"13px"}},[_c('img',{staticClass:"img2",attrs:{"src":childitem.t_image,"alt":"","srcset":""}}),_c('img',{staticClass:"img2",attrs:{"src":childitem.t_image,"alt":"","srcset":""}})])]),_c('div',{staticClass:"title ellipsis-1",style:({
        color: _vm.diyitem.style.titlecolor,
      })},[_vm._v(" "+_vm._s(childitem.title)+" ")]),_c('div',{staticClass:"describe flex-between"},[_c('p',{style:({
          color: _vm.diyitem.style.titlecolor,
        })},[_vm._v(" "+_vm._s(childitem.describe)+" ")]),_c('p',{staticClass:"price",style:({
          color: _vm.diyitem.style.priceColor,
          fontSize: _vm.diyitem.style.iconsize + 'px',
        })},[(childitem.market_price)?[_vm._v(" ￥"+_vm._s(childitem.market_price)+" ")]:[_vm._v(" ￥0 ")]],2)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }