var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},[_c('el-row',{attrs:{"gutter":_vm.diyitem.style.goodsmargin}},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('el-col',{key:index,staticClass:"fui-goods-item",style:({
        marginTop: _vm.diyitem.style.goodsmargin / 2 + 'px',
      }),attrs:{"span":12}},[_c('div',{staticStyle:{"border":"1px solid #afafaf","padding-bottom":"10px"}},[_c('div',{staticClass:"img-group"},[(childitem.IsPreferential == 1)?_c('img',{staticClass:"img1",attrs:{"src":require("../../assets/images/default/store/icon_youhui3.png"),"alt":"","srcset":""}}):_vm._e(),(childitem.IsRecommend == 1)?_c('img',{staticClass:"img1",attrs:{"src":require("../../assets/images/default/store/icon_tuijian3.png"),"alt":"","srcset":""}}):_vm._e(),(childitem.IsNew == 1)?_c('img',{staticClass:"img1",attrs:{"src":require("../../assets/images/default/store/icon_zuixin3.png"),"alt":"","srcset":""}}):_vm._e(),_vm._v("  "),(childitem.IsPlatformDiscount == 1)?_c('img',{staticClass:"img2",attrs:{"src":require("../../assets/images/default/store/icon_vip2.png"),"alt":"","srcset":""}}):_vm._e(),(childitem.IsCoupon == 1)?_c('img',{staticClass:"img2",attrs:{"src":require("../../assets/images/default/store/icon_yhq2.png"),"alt":"","srcset":""}}):_vm._e(),(childitem.PayMehtod != 3)?_c('img',{staticClass:"img2",attrs:{"src":require("../../assets/images/default/store/icon_jf2.png"),"alt":"","srcset":""}}):_vm._e()]),_c('img',{staticClass:"t_image",style:({
            borderRadius: _vm.diyitem.style.imgborder + 'px',
          }),attrs:{"src":childitem.t_image,"alt":"","srcset":""}}),_c('div',{staticClass:"title ellipsis-2",style:({
            color: _vm.diyitem.style.titlecolor,
          })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.title,20))+" ")]),_c('div',{staticClass:"price"},[_c('span',{staticClass:"market_price",style:({
              color: _vm.diyitem.style.pricecolor,
              fontSize: _vm.diyitem.style.pricesize + 'px',
            })},[_vm._v("￥"+_vm._s(childitem.market_price))]),_vm._v("    "),(_vm.diyitem.params.showproductprice == '1')?_c('span',{staticClass:"sale_price",style:({
              color: _vm.diyitem.style.productpricecolor,
              fontSize: _vm.diyitem.style.productpricesize + 'px',
            })},[_vm._v("￥"+_vm._s(childitem.sale_price))]):_vm._e(),(_vm.diyitem.params.showproductprice == '2')?_c('span',{staticClass:"sale_price",style:({
              color: _vm.diyitem.style.pricecolor,
              fontSize: _vm.diyitem.style.jfsize + 'px',
            })},[_c('span',[_vm._v("积")]),_vm._v(" "+_vm._s(childitem.sale_price)+" ")]):_vm._e()])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }