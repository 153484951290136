<template>
  <div>
    <div
      v-if="diyitem.params.styleTemplate == '1'"
      class="fui-courses-group"
      :style="{
        paddingLeft: diyitem.style.padding + 'px',
        paddingRight: diyitem.style.padding + 'px',
      }"
    >
      <div
        :style="{
          paddingTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-courses-item"
        :key="index"
      >
        <div class="fui-courses-item-top">
          <img
            class="salez"
            :src="childitem.t_image"
            :style="{
              borderRadius: diyitem.style.imgborder + 'px',
            }"
          />
          <div class="fui-courses-item-title">
            <div
              :style="{
                fontSize: diyitem.style.titleSize + 'px',
                color: diyitem.style.titlecolor,
              }"
              class="overflow"
            >
              {{ childitem.title | intercept(11) }}
            </div>
            <div
              :style="{
                fontSize: diyitem.style.describeSize + 'px',
                color: diyitem.style.describecolor,
              }"
            >
              {{ childitem.describe | intercept(22) }}
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="detail-left">
            <img
              v-if="childitem.TeacherInfo.length == 0"
              :src="childitem.avatar"
              class="detail-left-avatar"
            />
            <img
              v-else
              :src="childitem.TeacherInfo[0].HeadImg"
              class="detail-left-avatar"
            />
            <span
              class="detail-left-name"
              v-if="childitem.TeacherInfo.length == 0"
              >{{ childitem.name }}</span
            >
            <span class="detail-left-name" v-else>{{
              childitem.TeacherInfo[0].Name
            }}</span>
            <span
              v-if="
                diyitem.params.showproductprice == '1' && childitem.sale_price
              "
              class="sale_price"
              :style="{
                color: diyitem.style.pricecolor,
              }"
              >￥{{ childitem.sale_price }}</span
            >
            &nbsp;&nbsp;
            <span
              v-if="diyitem.params.showprice == '1' && childitem.market_price"
              class="market_price"
              :style="{
                color: diyitem.style.productpricecolor,
              }"
              >￥{{ childitem.market_price }}</span
            >
            <span
              v-if="!childitem.sale_price && !childitem.market_price"
              style="color: #f1c800"
            >
              免费
            </span>
          </div>
          <div class="detail-right flex">
            <img
              v-if="childitem.VipPrice == null ? false : true"
              src="../../assets/images/default/store/icon_vip.png"
              alt=""
              srcset=""
            />
            <img
              v-if="
                childitem.CourseTags == null
                  ? false
                  : childitem.CourseTags.includes('推荐')
              "
              src="../../assets/images/default/store/icon_tuijian.png"
              alt=""
              srcset=""
            />
            <img
              v-if="
                childitem.CourseTags == null
                  ? false
                  : childitem.CourseTags.includes('最新')
              "
              src="../../assets/images/default/store/icon_zuixin.png"
              alt=""
              srcset=""
            />
            <img
              v-if="
                childitem.CourseTags == null
                  ? false
                  : childitem.CourseTags.includes('热门')
              "
              src="../../assets/images/default/store/icon_youhui.png"
              alt=""
              srcset=""
            />
            <div
              class="detail-right-playType"
              :style="{
                color: diyitem.style.buybtntextcolor,
                backgroundColor: diyitem.style.buybtncolor,
              }"
            >
              {{
                childitem.CourseType == 0
                  ? "直播"
                  : childitem.CourseType == 1
                  ? "点播"
                  : "面授"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="diyitem.params.styleTemplate == '2'"
      class="fui-courses-group"
      :class="diyitem.params.styleTemplate == '2' ? 'fui-courses-group2' : ''"
      :style="{
        paddingLeft: diyitem.style.padding + 'px',
        paddingRight: diyitem.style.padding + 'px',
      }"
    >
      <div
        :style="{
          paddingTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        class="fui-courses-item"
        :key="index"
      >
        <div class="fui-courses-item-top">
          <img
            class="salez"
            :src="childitem.t_image"
            :style="{
              borderRadius: diyitem.style.imgborder + 'px',
            }"
          />
          <div class="fui-courses-item-title">
            <div>
              <div
                :style="{
                  fontSize: diyitem.style.titleSize + 'px',
                  color: diyitem.style.titlecolor,
                }"
                class="overflow"
              >
                {{ childitem.title | intercept(11) }}
              </div>
              <div
                class="suitable"
                :style="{
                  color: diyitem.style.title2color,
                }"
              >
                <p>{{ childitem.Temp1 }}</p>
                <p>{{ childitem.Temp2 }}</p>
                <p>{{ childitem.Temp3 }}</p>
              </div>
              <div>
                <span
                  v-if="
                    diyitem.params.showproductprice == '1' &&
                    childitem.sale_price
                  "
                  class="sale_price"
                  :style="{
                    color: diyitem.style.pricecolor,
                  }"
                  >￥{{ childitem.sale_price }}&nbsp;&nbsp;</span
                >

                <span
                  v-if="
                    diyitem.params.showprice == '1' && childitem.market_price
                  "
                  class="market_price"
                  :style="{
                    color: diyitem.style.productpricecolor,
                  }"
                  >￥{{ childitem.market_price }}</span
                >
                <span
                  v-if="!childitem.sale_price && !childitem.market_price"
                  style="color: #f1c800"
                >
                  免费
                </span>
              </div>
            </div>
            <div>
              <div class="detail-right">
                <div
                  class="detail-right-playType"
                  :style="{
                    color: diyitem.style.buybtntextcolor,
                    backgroundColor: diyitem.style.buybtncolor,
                  }"
                >
                  {{
                    childitem.CourseType == 0
                      ? "直播"
                      : childitem.CourseType == 1
                      ? "点播"
                      : "面授"
                  }}
                </div>
                <img
                  v-if="childitem.VipPrice == null ? false : true"
                  src="../../assets/images/default/store/icon_vip.png"
                  alt=""
                  srcset=""
                />
                <img
                  v-if="
                    childitem.CourseTags == null
                      ? false
                      : childitem.CourseTags.includes('推荐')
                  "
                  src="../../assets/images/default/store/icon_tuijian.png"
                  alt=""
                  srcset=""
                />
                <img
                  v-if="
                    childitem.CourseTags == null
                      ? false
                      : childitem.CourseTags.includes('最新')
                  "
                  src="../../assets/images/default/store/icon_zuixin.png"
                  alt=""
                  srcset=""
                />
                <img
                  v-if="
                    childitem.CourseTags == null
                      ? false
                      : childitem.CourseTags.includes('热门')
                  "
                  src="../../assets/images/default/store/icon_youhui.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div
            :style="{
              fontSize: diyitem.style.describeSize + 'px',
              color: diyitem.style.describecolor,
            }"
          >
            {{ childitem.describe | intercept(62) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "tpl_courses",
  components: {},
  data() {
    return {
      serverSrc: serverSrc,
    };
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {
    isicon(icon, str) {
      if (icon !== null) {
        return icon.includes(str);
        console.log(1);
      } else {
        return false;
        console.log(2);
      }
    },
  },
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courses-spe {
  text-align: left;
}

.height {
  height: 100px;
}

.sub-title {
  font-size: 12px;
  line-height: 16px;
  color: #999;
  height: 16px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
}

.fui-courses-group {
  text-align: left;
  line-height: 1.5;
  .fui-courses-item {
    border-bottom: 1px solid #afafaf;
    .fui-courses-item-top {
      display: flex;
      .salez {
        flex: 0 0 167px;
        width: 167px;
        height: 86px;
      }
      .fui-courses-item-title {
        margin-left: 13px;
        font-size: 15px;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0 12px;
      .detail-left {
        display: flex;
        align-items: center;
        .detail-left-avatar {
          width: 21px;
          height: 21px;
          border-radius: 50%;
        }
        .detail-left-name {
          display: inline-block;
          min-width: 67px;
          margin: 0 10px;
          font-size: 12px;
        }
      }
    }
    .market_price {
      font-size: 12px;
      text-decoration: line-through;
    }
    .sale_price {
      font-size: 14px;
    }
    .detail-right {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-left: 2px;
        &:first-child {
          margin-left: 0;
        }
      }
      .detail-right-playType {
        width: 58px;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        text-align: center;
        border-radius: 10px;
        margin-left: 4px;
      }
    }
  }
}
.fui-courses-group2 {
  .fui-courses-item {
    .fui-courses-item-top {
      display: flex;
      .salez {
        flex: 0 0 117px;
        width: 117px;
        height: 164px;
      }
    }
    .fui-courses-item-title {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      margin-left: 18px !important;
    }
    .detail-right {
      .detail-right-playType {
        margin: 0 8px 0 0;
      }
    }
    .suitable {
      font-size: 12px;
      color: #7e7e7e;
      margin-bottom: 9px;
    }
  }
}
</style>
