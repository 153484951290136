<template>
  <div class="editor_content">
    <div class="editor_title">标题</div>

    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 标题名称：</el-col>
      <el-col :span="18">
        <el-input
          v-model="diyItem.data.title"
          placeholder="请输入标题名称"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6">左侧返回设置：</el-col>
      <el-col :span="18">
        <el-row>
          <el-col :span="24" class="mb-10">
            <el-radio-group v-model="diyItem.data.return.icon">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" class="mb-10" v-if="diyItem.data.return.icon == 1">
            <el-radio-group v-model="diyItem.data.return.nolink">
              <el-radio label="1">按钮</el-radio>
              <!-- <el-radio label="2">链接</el-radio> -->
            </el-radio-group>
          </el-col>
          <el-col :span="24" v-if="diyItem.data.return.nolink == 2">
            <el-input
              disabled=""
              placeholder="请选择跳转链接"
              v-model="diyItem.data.return.linkurl"
            >
              <el-button @click="showLink('return')" slot="suffix" type="text"
                >选择链接</el-button
              >
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6">左侧消息设置：</el-col>
      <el-col :span="18">
        <el-row>
          <el-col :span="24" class="mb-10">
            <el-radio-group v-model="diyItem.data.mail.icon">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" class="mb-10" v-if="diyItem.data.mail.icon == 1">
            <el-radio-group v-model="diyItem.data.mail.nolink">
              <el-radio label="1">按钮</el-radio>
              <!-- <el-radio label="2">链接</el-radio> -->
            </el-radio-group>
          </el-col>
          <el-col :span="24" v-if="diyItem.data.mail.nolink == 2">
            <el-input
              disabled=""
              placeholder="请选择跳转链接"
              v-model="diyItem.data.mail.linkurl"
            >
              <el-button @click="showLink('mail')" slot="suffix" type="text"
                >选择链接</el-button
              >
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- <el-row class="row-bg row-bg-list">
      <el-col :span="6">右侧搜索设置：</el-col>
      <el-col :span="18">
        <el-row>
          <el-col :span="24" class="mb-10">
            <el-radio-group v-model="diyItem.data.search.icon">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" class="mb-10" v-if="diyItem.data.search.icon == 1">
            <el-radio-group v-model="diyItem.data.search.nolink">
              <el-radio label="1">按钮</el-radio>
              <el-radio label="2">链接</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" v-if="diyItem.data.search.nolink == 2">
            <el-input
              disabled=""
              placeholder="请选择跳转链接"
              v-model="diyItem.data.search.linkurl"
            >
              <el-button @click="showLink('search')" slot="suffix" type="text"
                >选择链接</el-button
              >
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row> -->
    <!-- <el-row class="row-bg row-bg-list">
      <el-col :span="6">右侧筛选设置：</el-col>
      <el-col :span="18">
        <el-row>
          <el-col :span="24" class="mb-10">
            <el-radio-group v-model="diyItem.data.screen.icon">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" class="mb-10" v-if="diyItem.data.screen.icon == 1">
            <el-radio-group v-model="diyItem.data.screen.nolink">
              <el-radio label="1">按钮</el-radio>
              <el-radio label="2">链接</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="24" v-if="diyItem.data.screen.nolink == 2">
            <el-input
              disabled=""
              placeholder="请选择跳转链接"
              v-model="diyItem.data.screen.linkurl"
            >
              <el-button @click="showLink('screen')" slot="suffix" type="text"
                >选择链接</el-button
              >
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row> -->
    <template>
      <el-row class="row-bg row-bg-list">
        <el-col :span="6">图标颜色 ：</el-col>
        <el-col :span="18">
          <el-color-picker v-model="diyItem.style.iconColor"></el-color-picker>
        </el-col>
      </el-row>
    </template>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 背景颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.background"></el-color-picker>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 文字颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.color"></el-color-picker>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 图标大小：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.iconsize"
          :min="12"
          :max="30"
        ></el-slider>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 文字大小：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.fontsize"
          :min="8"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 上下边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingtop"
          :min="0"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>

    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 左右边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingleft"
          :min="0"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>
    <choose-link @selectItem="linkUrl" ref="link"></choose-link>
  </div>
</template>

<script>
import chooseLink from "@/views/components/chooseLink";
export default {
  name: "edit_title",
  components: {
    "choose-link": chooseLink,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
  },
  methods: {
    //    链接回调
    linkUrl: function (url) {
      console.log(url);
      this.$set(this.diyItem.data[this.name], "linkurl", url.linkurl);
    },
    //  选择链接
    showLink: function (name) {
      this.name = name;
      this.$refs.link.showChoose();
    },
  },
};
</script>

<style scoped>
</style>
