<template>
  <div class="editor_content">
    <div class="editor_title">图文导航</div>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 背景颜色：</el-col>
      <el-col :span="18">
        <el-color-picker
          @change="changedata"
          v-model="diyItem.style.background"
        ></el-color-picker>
      </el-col>
    </el-row>
    <!-- <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 按钮形状：</el-col>
      <el-col :span="18">
        <el-radio-group @change="changedata" v-model="diyItem.style.dotstyle">
          <el-radio label="square">正方形</el-radio>
          <el-radio label="radius">圆角</el-radio>
          <el-radio label="circle">圆形</el-radio>
        </el-radio-group>
      </el-col>
    </el-row> -->
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 显示方式：</el-col>
      <el-col :span="18">
        <el-radio-group @change="changedata" v-model="diyItem.style.showtype">
          <el-radio :label="0">单页显示</el-radio>
          <el-radio :label="1">多页滑动显示</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 每行数量：</el-col>
      <el-col :span="18">
        <el-radio-group @change="changedata" v-model="diyItem.style.rownum">
          <el-radio :label="3">3个</el-radio>
          <el-radio :label="4">4个</el-radio>
          <el-radio :label="5">5个</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <div class="img_list" v-for="(item, index) in diyItem.data" :key="index">
      <el-row class="row-bg">
        <el-col :span="6">
          <div class="img_left">
            <img
              v-if="item.imgurl != 'img/default.3894ed26.png'"
              :src="
                item.imgurl.indexOf('http') != -1
                  ? item.imgurl
                  : serverSrc + item.imgurl
              "
              alt=""
            />
            <img
              v-if="item.imgurl == 'img/default.3894ed26.png'"
              :src="item.imgurl"
              alt=""
            />
            <div class="bton" @click="selectedimg(index)">更换图片</div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-row type="flex" style="align-items: center">
            <el-col :span="6" class="name">导航标题</el-col>
            <el-col :span="18" style="display: flex">
              <input
                class="input"
                style="flex: 1"
                v-model="item.text"
                type="text"
                placeholder="建议10个字以内，可不填"
              />
              <el-color-picker
                size="small"
                v-model="item.color"
              ></el-color-picker>
            </el-col>
          </el-row>
          <el-row type="flex" style="align-items: center; margin-top: 10px">
            <el-col :span="6" class="name">跳转路径</el-col>
            <el-col :span="18">
              <el-input
                disabled=""
                placeholder="请选择跳转链接"
                v-model="item.linkurl"
              >
                <el-button @click="showLink(index)" slot="suffix" type="text"
                  >选择</el-button
                >
              </el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div
        class="img_list_remove"
        @click="removeChild(index)"
        style="color: #999999"
      >
        <i class="el-icon-error"></i>
      </div>
    </div>
    <div class="img_list img_add" @click="addChild">
      <div><i class="el-icon-plus"></i> 添加一个</div>
    </div>

    <select-img @selectImgshow="imgurl" ref="mychild"></select-img>
    <choose-link @selectItem="linkUrl" ref="link"></choose-link>
  </div>
</template>

<script>
import selectImg from "@/views/components/selectImg";
import chooseLink from "@/views/components/chooseLink";
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "edit_menu",
  components: {
    "choose-link": chooseLink,
    "select-img": selectImg,
  },
  data() {
    return {
      serverSrc: serverSrc,
      options: [
        {
          linkurl: "www.baidu.com1",
          label: "测试链接1",
        },
        {
          linkurl: "www.baidu.com2",
          label: "测试链接2",
        },
      ],
      index: "",
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
    diyKey: function () {
      return this.$store.state.diypage.diyKey;
    },
    diyList: {
      get: function () {
        return this.$store.state.diypage.diyList;
      },
      set: function (value) {
        this.$store.dispatch("updataDiyList", value);
      },
    },
  },
  mounted() {
    this.changedata();
  },
  methods: {
    // 图片选择回调
    imgurl: function (vl) {
      if (this.index === "") {
        var item = {
          imgurl: vl.Url,
          linkurl: "",
          text: "按钮文字1",
          color: "#666666",
        };
        this.diyItem.data.push(item);
      } else {
        this.diyItem.data[this.index].imgurl = vl.Url;
        this.index = "";
      }
      this.changedata();
    },
    // 选择图片
    selectedimg: function (index) {
      this.index = index;
      this.$refs.mychild.showimgselect();
    },
    //    链接回调
    linkUrl: function (url) {
      this.$set(this.diyItem.data[this.index], "linkurl", url.linkurl);
      this.index = "";
      this.changedata();
    },
    //  选择链接
    showLink: function (index) {
      this.index = index;
      this.$refs.link.showChoose();
    },
    // 删除子元素
    removeChild: function (index) {
      this.diyItem.data.splice(index, 1);
      this.changedata();
    },
    // 添加子元素
    addChild: function () {
      if (this.diyItem.data.length >= 20) {
        this.$message.error("最多添加20个");
        return "";
      }
      this.$refs.mychild.showimgselect();
      this.changedata();
    },
    // 对数组进行分组
    split_array: function (arr, len) {
      var aLen = arr.length;
      var result = [];
      for (let i = 0; i < aLen; i += len) {
        result.push(arr.slice(i, i + len));
      }
      return result;
    },
    // 对数据进行重组
    changedata: function () {
      var value = this.diyItem.style.showtype;
      var newdata = this.diyItem;
      console.log(newdata);
      let dataLine = "";
      if (value === 1) {
        newdata.style.pagenum = 8;
        if (newdata.style.pagenum > newdata.style.rownum * 2) {
          dataLine = Math.floor(newdata.style.pagenum / newdata.style.rownum);
        } else {
          dataLine = 2;
        }
        let dataTemp = this.split_array(newdata.data, newdata.style.rownum * 2);
        newdata.data_temp = dataTemp;
        newdata.data_line = dataLine;
        this.$set(this.diyList, this.diyKey, newdata);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
