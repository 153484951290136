<template>
  <div>
    <div
      class="fui-icon-group noborder"
      v-if="diyitem.style.showtype == '0'"
      :class="['col-' + diyitem.style.rownum, diyitem.style.dotstyle]"
      :style="{ background: diyitem.style.background }"
    >
      <div
        @click="gotourl(childitem.linkurl)"
        class="fui-icon-col external"
        :key="inde"
        v-for="(childitem, inde) in diyitem.data"
      >
        <div class="icon">
          <img
            v-if="childitem.imgurl != 'img/default.3894ed26.png'"
            :class="diyitem.style.navstyle"
            :src="
              childitem.imgurl.indexOf('http') != -1
                ? childitem.imgurl
                : serverSrc + childitem.imgurl
            "
          />
          <img
            v-if="childitem.imgurl == 'img/default.3894ed26.png'"
            :class="diyitem.style.navstyle"
            :src="childitem.imgurl"
          />
        </div>
        <div class="text" :style="{ color: childitem.color }">
          {{ childitem.text }}
        </div>
      </div>
    </div>
    <template v-if="diyitem.style.showtype == '1'">
      <el-carousel
        class="menu"
        :style="{
          background: diyitem.style.background,
          padding: '10px',
          height: diyitem.data_line * 75 + 'px',
        }"
        :indicator-position="diyitem.style.showdot"
        arrow="never"
      >
        <el-carousel-item
          class="menu_banner"
          :class="['col-' + diyitem.style.rownum, diyitem.style.dotstyle]"
          v-for="(childitem, index) in diyitem.data_temp"
          :key="index"
        >
          <div
            :key="indx"
            v-for="(menu_item, indx) in childitem"
            class="fui-icon-col external"
          >
            <div class="icon">
              <img
                v-if="menu_item.imgurl != 'img/default.3894ed26.png'"
                :class="diyitem.style.navstyle"
                :src="
                  menu_item.imgurl.indexOf('http') != -1
                    ? menu_item.imgurl
                    : serverSrc + menu_item.imgurl
                "
              />
              <img
                v-if="menu_item.imgurl == 'img/default.3894ed26.png'"
                :class="diyitem.style.navstyle"
                :src="menu_item.imgurl"
              />
            </div>
            <div class="text" :style="{ color: menu_item.color }">
              {{ menu_item.text }}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!--<el-carousel class="menu" type="card" :autoplay='false' :style="{background:diyitem.style.background,padding:'10px',height:diyitem.data_line*75 +'px'}" :indicator-position="diyitem.style.showdot" arrow="never">-->
      <!--<el-carousel-item v-for="(childitem,inde) in diyitem.data" :key="inde" class="menu_banner " :class="['col-'+diyitem.style.rownum ,diyitem.style.dotstyle]">-->
      <!--<div @click="gotourl(childitem.linkurl)" class="fui-icon-col external" >-->
      <!--<div class="icon">-->
      <!--<img :class="diyitem.style.navstyle" :src="childitem.imgurl"/>-->
      <!--</div>-->
      <!--<div class="text" :style="{color:childitem.color}">-->
      <!--{{childitem.text}}-->
      <!--</div>-->
      <!--</div>-->
      <!--</el-carousel-item>-->
      <!--</el-carousel>-->
    </template>
  </div>
</template>

<script>
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "tpl_menu",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serverSrc: serverSrc,
    };
  },
  components: {},
  mounted() {
    console.log(this.diyitem);
  },
  methods: {
    gotourl: function (url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style>
.menu .el-carousel__container {
  vertical-align: top;
  height: 100%;
  width: 100%;
}

.menu .el-carousel__button {
  background-color: #999999;
}
</style>
<style scoped>
.fui-icon-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  line-height: normal;
}

.menu_banner {
  display: flex;
  flex-wrap: wrap;
}

/*导航栏多页滑动展示  */
.menu {
  /*min-height: 180px;*/
  box-sizing: content-box;
}

.menu .van-swipe-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.radius .fui-icon-col img {
  border-radius: 10px;
}

.circle .fui-icon-col img {
  border-radius: 50%;
}

.menu .fui-icon-col {
  width: 25%;
  text-align: center;
}

.menu .col-4 .fui-icon-col {
  width: 25%;
  text-align: center;
}

.menu .col-3 .fui-icon-col {
  width: 33.3%;
  text-align: center;
}

.menu .col-5 .fui-icon-col {
  width: 20%;
  text-align: center;
}

img.circle {
  border-radius: 50%;
}

.fui-icon-col .icon img {
  height: 44px;
  width: 44px;
  display: inline-block;
}

.index-hot {
  margin-bottom: 20px;
}

.fui-goods-item .detail .price .text {
  text-align: left;
}

.fui-icon-group .fui-icon-col {
  width: 25%;
  height: auto;
  position: relative;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
  float: left;
  border: none !important;
}

.fui-icon-group {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
}

.fui-icon-group .fui-icon-col .text {
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px;
  color: #000;
}
.fui-icon-col .text {
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.fui-icon-group .fui-icon-col .icon {
  height: 44px;
  margin: auto;
  text-align: center;
  line-height: 44px;
}

img {
  vertical-align: middle;
  width: 100px;
  height: 100px;
}

.fui-icon-group .fui-icon-col {
  width: 25%;
  height: auto;
  position: relative;
  text-align: center;
  transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
  float: left;
}

.fui-icon-group .fui-icon-col:active {
  background: #ececec;
}

.fui-icon-group .fui-icon-col:last-child:before {
  display: none;
}

.fui-icon-group .fui-icon-col .icon {
  height: 44px;
  margin: auto;
  text-align: center;
  line-height: 44px;
}

.fui-icon-group.col-3 .fui-icon-col {
  width: 33.3%;
}

.fui-icon-group.col-5 .fui-icon-col {
  width: 20%;
}
</style>
