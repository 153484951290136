<template>
  <!-- 课程模块配置 -->
  <div class="editor_content">
    <div class="editor_title">课程</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="课程管理" name="first" style="padding: 5px">
        <!--课程分类-->
        <template>
          <el-row class="row-bg row-bg-list">
            <el-col :span="6"> 课程选择：</el-col>
            <el-col :span="18">
              <div class="flex-start-center">
                <el-tag
                  type="success"
                  style="margin-right: 15px"
                  v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
                  >{{ diyItem.params.catedata.title }}</el-tag
                >
                <div
                  class="pointer"
                  :style="{ color: type == 0 ? defaultColor : '#333' }"
                  @click="showSelect(0)"
                >
                  选择普通课程
                </div>
                <div
                  class="pointer"
                  :style="{
                    color: type == 1 ? defaultColor : '#333',
                    marginLeft: '30px',
                  }"
                  @click="showSelect(1)"
                >
                  选择三合美术馆艺术课程
                </div>
              </div>
            </el-col>
          </el-row>
          <div
            class="img_list"
            v-for="(item, index) in diyItem.cate_goods"
            :key="index"
          >
            <el-row class="row-bg">
              <el-col :span="6">
                <div class="img_left">
                  <img :src="item.t_image" alt="" />
                  <div class="bton" @click="selectedgoods(index)">更换课程</div>
                </div>
              </el-col>
              <el-col :span="18">
                <el-row type="flex" style="align-items: center">
                  <el-col :span="6" class="name">课程标题</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.title"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; margin-top: 10px"
                >
                  <el-col :span="6" class="name">课程价格</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.market_price"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div
              class="img_list_remove"
              @click="removeChild(index)"
              style="color: #999999"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
        </template>
        <!--手动选择-->
        <template>
          <div
            class="img_list"
            v-for="(item, index) in diyItem.data"
            :key="index"
          >
            <el-row class="row-bg">
              <el-col :span="6">
                <div class="img_left">
                  <img :src="item.t_image" alt="" />
                  <div class="bton" @click="selectedgoods(index)">更换课程</div>
                </div>
              </el-col>
              <el-col :span="18">
                <el-row type="flex" style="align-items: center">
                  <el-col :span="6" class="name">课程标题</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.title"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; margin-top: 10px"
                >
                  <el-col :span="6" class="name">课程价格</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.market_price"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div
              class="img_list_remove"
              @click="removeChild(index)"
              style="color: #999999"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
          <div class="img_list img_add" @click="addChild">
            <el-button type="text"
              ><i class="el-icon-plus"></i> 添加一个课程</el-button
            >
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="样式管理" name="second">
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 样式设置：</el-col>
          <el-col :span="18">
            <el-radio-group v-model="diyItem.params.styleTemplate">
              <el-radio label="1">样式一</el-radio>
              <el-radio label="2">样式二</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">名称颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.titlecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 课程价格：</el-col>
          <el-col :span="18">
            <el-radio-group v-model="diyItem.params.showprice">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row
          class="row-bg row-bg-list"
          v-if="diyItem.params.showprice == '1'"
        >
          <el-col :span="6">价格颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.pricecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 课程原价：</el-col>
          <el-col :span="18">
            <el-radio-group v-model="diyItem.params.showproductprice">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">不显示</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <template v-if="diyItem.params.showproductprice == '1'">
          <el-row class="row-bg row-bg-list">
            <el-col :span="6">原价颜色：</el-col>
            <el-col :span="18">
              <el-color-picker
                v-model="diyItem.style.productpricecolor"
              ></el-color-picker>
            </el-col>
          </el-row>
        </template>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">按钮背景颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.buybtncolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">按钮文字颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.buybtntextcolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 页面边距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.padding"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 课程间距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.goodsmargin"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 图片圆角：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.imgborder"
              :min="0"
              :max="20"
            ></el-slider>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <!-- <selectImg @selectImgshow="imgurl" ref="mychild"></selectImg> -->
    <selectCourser
      @selectImgshow="goodsurl"
      ref="mygoods"
      :type="type"
    ></selectCourser>
    <!-- <select-cate ref="cate" @updata="updata"></select-cate> -->
  </div>
</template>

<script>
// import selectImg from '@/components/selectImg'
import selectCourser from "@/views/components/selectCourser";
// import selectCate from './selectCate'
// import {goodsCatelist} from '@/api/store'
export default {
  name: "edit_goods",
  components: {
    // selectImg,
    selectCourser,
    // 'select-cate': selectCate
  },
  data() {
    return {
      activeName: "first",
      options: [],
      index: "",
      type: "0",
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
    diyKey: function () {
      return this.$store.state.diypage.diyKey;
    },
    diyList() {
      return this.$store.state.diypage.diyList;
    },
    defaultColor() {
      return this.$store.state.theme.defaultColor;
    },
  },
  mounted() {},
  methods: {
    // 分类选择回调
    updata: function (row) {
      let data = {
        cate_id: row.id,
      };
      goodsCatelist(data)
        .then((response) => {
          let goodsList = response.result;
          if (goodsList.length === 0) {
            this.$message.error("该分类下没有课程");
          }
          this.$set(this.diyItem, "cate_goods", goodsList);
          this.$set(this.diyItem.params, "catedata", row);
        })
        .catch();
    },
    // 显示分类
    showSelect: function (val) {
      this.type = val;
    },
    // 选择课程
    selectedgoods: function (index) {
      this.index = index;
      this.$refs.mygoods.showimgselect();
    },
    // 课程选择回调
    goodsurl(vlu) {
      console.log(vlu);
      let newObj = {
        avatar: vlu.avatar,
        describe: vlu.describe,
        id: vlu.id,
        market_price: vlu.market_price,
        name: vlu.name,
        sale_price: vlu.sale_price,
        t_image: vlu.t_image,
        title: vlu.title,
        CourseDirection: vlu.CourseDirection,
        TeacherInfo: vlu.TeacherInfo,
        Temp1: vlu.Temp1,
        Temp2: vlu.Temp2,
        Temp3: vlu.Temp3,
        CourseType: vlu.CourseType,
      };
      if (this.index === "") {
        this.diyItem.cate_goods.push(newObj);
      } else {
        this.$set(this.diyItem.cate_goods, this.index, newObj);
        this.index = "";
      }
    },
    // 图片选择回调
    imgurl: function (vl) {
      this.$set(this.diyItem.params, "goodsiconsrc", vl);
    },
    // 选择图片
    selectedimg: function (index) {
      this.index = index;
      this.$refs.mychild.showimgselect();
    },
    // 删除子元素
    removeChild: function (index) {
      this.diyItem.cate_goods.splice(index, 1);
    },
    // 添加子元素
    addChild: function () {
      if (this.diyItem.cate_goods.length >= 10) {
        this.$message.error("最多添加10个课程");
        return "";
      }
      this.$refs.mygoods.showimgselect();
    },
  },
};
</script>

<style>
.el-radio {
  margin-bottom: 10px;
}
</style>
