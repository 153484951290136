var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swipe_main",style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-art-item",style:({
      marginRight: _vm.diyitem.style.goodsmargin + 'px',
    })},[(childitem.HeadImg == null)?_c('img',{staticClass:"t_image",style:({
        borderRadius: _vm.diyitem.style.imgborder + '%',
      }),attrs:{"src":childitem.t_image,"alt":"","srcset":""}}):_c('img',{staticClass:"t_image",style:({
        borderRadius: _vm.diyitem.style.imgborder + '%',
      }),attrs:{"src":childitem.HeadImg,"alt":"","srcset":""}}),_c('div',{staticStyle:{"padding":"0 35px"},style:({
        borderRight:
          _vm.diyitem.style.bordersize + 'px solid' + _vm.diyitem.style.bordercolor,
      })},[_c('div',{staticClass:"title ellipsis-1",style:({
          color: _vm.diyitem.style.titlecolor,
        })},[_vm._v(" "+_vm._s(childitem.title)+" ")]),_c('div',{staticClass:"describe flex-between"},[_c('div',{staticClass:"describe-box",style:({
            color: _vm.diyitem.style.titlecolor,
          })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.describe,20))+" ")])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }