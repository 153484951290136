var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.diyitem.params.styleTemplate == '1')?_c('div',{style:({
      paddingLeft: _vm.diyitem.style.padding + 'px',
      paddingRight: _vm.diyitem.style.padding + 'px',
    })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-exhibition-item",style:({
        marginTop: _vm.diyitem.style.goodsmargin + 'px',
      })},[(childitem.CoverImg == null)?_c('img',{staticClass:"t_image",style:({
          borderRadius: _vm.diyitem.style.imgborder + 'px',
        }),attrs:{"src":childitem.t_image,"alt":"","srcset":""}}):_c('img',{staticClass:"t_image",style:({
          borderRadius: _vm.diyitem.style.imgborder + 'px',
        }),attrs:{"src":childitem.CoverImg,"alt":"","srcset":""}}),_c('div',{staticClass:"title ellipsis-1",style:({
          color: _vm.diyitem.style.titlecolor,
        })},[(childitem.Title == null)?[_vm._v(" "+_vm._s(childitem.title)+" ")]:[_vm._v(" "+_vm._s(childitem.Title)+" ")]],2),_c('div',{staticClass:"describe"},[_c('p',{style:({
            color: _vm.diyitem.style.describecolor,
          })},[(childitem.Introduce == null)?[_vm._v(" "+_vm._s(childitem.describe)+" ")]:[_vm._v(" "+_vm._s(childitem.Introduce)+" ")]],2),_c('p',{style:({
            color: _vm.diyitem.style.iconColor,
            fontSize: _vm.diyitem.style.iconsize + 'px',
          })},[_c('span',[_c('i',{staticClass:"iconfont el-icon-view"}),(childitem.ReadCount == null)?[_vm._v(" "+_vm._s(0)+" ")]:[_vm._v(" "+_vm._s(childitem.ReadCount)+" ")]],2),_vm._v("     "),_vm._m(0,true),_vm._v("     "),_vm._m(1,true)])])])}),0):_vm._e(),(_vm.diyitem.params.styleTemplate == '2')?_c('div',{staticClass:"fui-courses-group",class:_vm.diyitem.params.styleTemplate == '2' ? 'fui-courses-group2' : '',style:({
      paddingLeft: _vm.diyitem.style.padding + 'px',
      paddingRight: _vm.diyitem.style.padding + 'px',
    })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-courses-item",style:({
        paddingTop: _vm.diyitem.style.goodsmargin + 'px',
      })},[_c('div',{staticClass:"fui-courses-item-top"},[_c('img',{staticClass:"salez",style:({
            borderRadius: _vm.diyitem.style.imgborder + 'px',
          }),attrs:{"src":childitem.t_image}}),_c('div',{staticClass:"fui-courses-item-title"},[_c('div',[_c('div',{staticClass:"overflow",style:({
                fontSize: _vm.diyitem.style.titleSize + 'px',
                color: _vm.diyitem.style.titlecolor,
              })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.title,11))+" ")]),_c('div',{staticClass:"suitable",style:({
                color: _vm.diyitem.style.title2color,
              })},[_c('p',[_vm._v("展览时间：2022/4/2——5/31")])])]),_c('div',{staticClass:"detail-right flex-between"},[_c('div',{staticClass:"detail-right-playType",style:({
                color: _vm.diyitem.style.buybtntextcolor,
                backgroundColor: _vm.diyitem.style.buybtncolor,
              })},[_vm._v(" 直播 ")]),_c('i',{staticClass:"iconfont icon-custom-share",style:({
                fontSize: _vm.diyitem.style.iconsize + 'px',
                color: _vm.diyitem.style.buybtntextcolor,
              })})])])]),_c('div',{staticClass:"detail"},[_c('div',{style:({
            fontSize: _vm.diyitem.style.describeSize + 'px',
            color: _vm.diyitem.style.describecolor,
          })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.describe,62))+" ")])]),_c('div',{staticClass:"swipe_main images"},_vm._l((childitem.images),function(ci,i){return _c('img',{key:i,attrs:{"src":ci,"alt":"","srcset":""}})}),0)])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"iconfont icon-custom-collection"}),_vm._v(" 0 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"el-icon-chat-dot-round"}),_vm._v(" 0 ")])}]

export { render, staticRenderFns }