<template>
  <!--添加模块-->
  <div class="preview_add">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础组件" name="first">
        <div class="add_ul_pane">
          <scroll-bar style="height: 600px">
            <draggable
              :options="options"
              class="dragArea list-group add_ul"
              :list="configList"
              :group="{ name: 'people', pull: 'clone', put: false }"
              :clone="cloneDog"
              :force-fallback="true"
            >
              <template v-for="(item, index) in configList">
                <div class="add_ul_li" :key="index">
                  <img :src="item.src" alt="" />
                  <div class="tit">{{ item.name }}</div>
                </div>
              </template>
            </draggable>
          </scroll-bar>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="营销组件" name="second"> </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import configList from "../configNew";
import ScrollBar from "@/components/ScrollBar";
import draggable from "vuedraggable";
export default {
  name: "previewAdd",
  data() {
    return {
      options: {
        sort: false, // 定义是否可以拖动
      },
      activeName: "first",
      configList: configList,
    };
  },
  components: {
    "scroll-bar": ScrollBar,
    draggable,
  },
  computed: {
    diylist() {
      return this.$store.state.diypage.diyList;
    },
    selectKey: {
      get: function () {
        return this.$store.state.diypage.diyKey;
      },
      set: function (value) {
        this.$store.dispatch("updatadiykey", value);
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.configList.forEach((ele) => {
        ele.src = require(`@/assets/images/default/store/advert_${ele.id}.png`);
      });
      console.log(this.configList);
    },
    log: function () {
      console.log(11);
    },
    cloneDog({ id }) {
      let item = null;
      // console.log(id)
      // console.log(333333333)
      let configList = this.configList;
      for (let i = 0; i < configList.length; i++) {
        if (configList[i].id === id) {
          item = configList[i];
        }
      }
      if (id === "fixedsearch") {
        if (this.diylist[0] && this.diylist[0].id === "fixedsearch") {
          this.$message.error("该组件只能添加一个哦");
          return "";
        }
        this.$store.commit("SETDIYITEM", item);
        return item;
      } else {
        return item;
      }
    },
    // 添加配置
    adddiyItem: function (item) {
      if (item) {
        if (item.id !== "fixedsearch") {
          this.$store.commit("SETDIYLIST", this.deepClone(item));
          this.selectKey = this.diylist.length - 1;
          this.$store.commit("SETDIYITEM", this.diylist[this.selectKey]);
        } else {
          if (this.diylist[0] && this.diylist[0].id === "fixedsearch") {
            this.$message.error("该组件只能添加一个哦");
            return "";
          }
          this.$store.commit("SETDIYLISTPOP", this.deepClone(item));
          this.selectKey = 0;
          this.$store.commit("SETDIYITEM", this.diylist[this.selectKey]);
        }
      }
    },
    // 对象深度拷贝
    deepClone(obj) {
      var newObj = obj.constructor === Array ? [] : {};
      if (typeof obj !== "object") {
        return;
      } else {
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            newObj[i] =
              typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
          }
        }
      }
      return newObj;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped lang="scss">
.preview_add {
  width: 289px;
  padding-bottom: 5px;
  position: relative;
  margin-right: 40px;
  .add_ul_pane {
    max-height: 600px;
  }
  .add_ul {
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px;
    li,
    .add_ul_li {
      width: 120px;
      height: 100px;
      display: inline-block;
      border: 1px solid #fff;
      padding: 5px;
      border-radius: 4px;
      text-align: center;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      background-color: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      img {
        width: 88px;
        height: 50px;
      }

      .tit {
        font-size: 12px;
        color: #595961;
        font-weight: 700;
      }
    }

    li,
    .add_ul_li:hover {
      border: 1px solid #409eff;
    }

    li,
    .add_ul_li:nth-child(2n) {
      margin-right: 0;
    }
  }
}
/deep/ .el-tabs__nav {
  margin-left: 30px;
}
</style>
