<template>
  <!--:style="{marginTop:offsetTop+'px'}"-->
  <div class="editor" v-if="JSON.stringify(diyitem) != '{}'">
    <template v-if="diyitem.id === 'headerTitle'">
      <edit-header-title></edit-header-title>
    </template>
    <template v-if="diyitem.id === 'banner'">
      <edit-banner></edit-banner>
    </template>
    <template v-if="diyitem.id === 'notice'">
      <edit-notice></edit-notice>
    </template>
    <template v-if="diyitem.id === 'menu'">
      <edit-menu></edit-menu>
    </template>
    <template v-if="diyitem.id === 'title'">
      <edit-title></edit-title>
    </template>
    <template v-if="diyitem.id === 'courses'">
      <edit-courses></edit-courses>
    </template>
    <template v-if="diyitem.id === 'art'">
      <edit-art></edit-art>
    </template>
    <template v-if="diyitem.id === 'exhibition'">
      <edit-exhibition></edit-exhibition>
    </template>
    <template v-if="diyitem.id === 'goods'">
      <edit-goods></edit-goods>
    </template>
    <template v-if="diyitem.id === 'dynamic'">
      <edit-dynamic></edit-dynamic>
    </template>
    <template v-if="diyitem.id === 'dataDownload'">
      <edit-dataDownload></edit-dataDownload>
    </template>
    <template v-if="diyitem.id === 'lecturer'">
      <edit-lecturer></edit-lecturer>
    </template>
    <template v-if="diyitem.id === 'expert'">
      <edit-expert></edit-expert>
    </template>
    <template v-if="diyitem.id === 'organization'">
      <edit-organization></edit-organization>
    </template>
    <template v-if="diyitem.id === 'artist'">
      <edit-artist></edit-artist>
    </template>
    <template v-if="diyitem.id === 'artRecommend'">
      <edit-artRecommend></edit-artRecommend>
    </template>
    <template v-if="diyitem.id === 'activity'">
      <edit-activity></edit-activity>
    </template>
    <template v-if="diyitem.id === 'artExhibition'">
      <edit-artExhibitionVue></edit-artExhibitionVue>
    </template>
    <template v-if="diyitem.id === 'videos'">
      <edit-videos></edit-videos>
    </template>
  </div>
</template>

<script>
import editHeaderTitle from "./edit_header_title";
import editBanner from "./edit_banner";
import editNotice from "./edit_notice";
import editMenu from "./edit_menu";
import editTitle from "./edit_title";
import editCourses from "./edit_courses";
import editArt from "./edit_art";
import editExhibition from "./edit_exhibition";
import editGoods from "./edit_goods";
import editDynamic from "./edit_dynamic";
import editDataDownload from "./edit_dataDownload";
import editLecturer from "./edit_lecturer";
import editExpert from "./edit_expert";
import editOrganization from "./edit_organization";
import editArtist from "./edit_artist";
import editArtRecommend from "./edit_artRecommend";
import editActivity from "./edit_activity.vue";
import editArtExhibitionVue from "./edit_artExhibition.vue";
import editVideos from "./edit_videos";
export default {
  name: "editor",
  components: {
    "edit-header-title": editHeaderTitle,
    "edit-banner": editBanner,
    "edit-notice": editNotice,
    "edit-menu": editMenu,
    "edit-title": editTitle,
    "edit-courses": editCourses,
    "edit-art": editArt,
    "edit-exhibition": editExhibition,
    "edit-goods": editGoods,
    "edit-dynamic": editDynamic,
    "edit-dataDownload": editDataDownload,
    "edit-lecturer": editLecturer,
    "edit-expert": editExpert,
    "edit-organization": editOrganization,
    "edit-artist": editArtist,
    "edit-artRecommend": editArtRecommend,
    "edit-activity": editActivity,
    "edit-artExhibitionVue": editArtExhibitionVue,
    "edit-videos": editVideos,
  },
  data() {
    return {};
  },
  computed: {
    diyitem() {
      return this.$store.state.diypage.diyItem;
    },
    offsetTop() {
      return this.$store.state.diypage.offsetTop;
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
};
</script>
<style lang="scss">
@import "@/style/editor.scss";
</style>

<style scoped lang="scss">
.editor {
  /*margin-top: 63px;*/
  /*padding: 15px;*/
  width: 420px;
  /*margin-left: 80px;*/
  font-size: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 20px;
}

.editor:before,
.editor:after {
  /*  position: absolute;
        content: " ";
        top: 16px;
        left: -16px;
        display: block;
        width: 0;
        height: 0;
        border: 8px solid #F8F8F8;
        border-color: transparent #F8F8F8 transparent transparent;
        z-index: 111;*/
}

.editor:after {
  /*left: -17px;
        border-color: transparent #e2e2e2 transparent transparent;
        z-index: 11;*/
}
</style>
