<template>
  <div class="editor_content">
    <div class="editor_title">艺术模块</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="艺术管理" name="first" style="padding: 5px">
        <template>
          <el-row class="row-bg row-bg-list">
            <el-col :span="6"> 艺术选择：</el-col>
            <el-col :span="6">
              <div class="flex-start-center">
                <el-tag
                  type="success"
                  style="margin-right: 15px"
                  v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
                  >{{ diyItem.params.catedata.title }}</el-tag
                >
                <div
                  class="pointer"
                  :style="{ color: typeIndex == 0 ? defaultColor : '#333' }"
                  @click="showSelect(0)"
                >
                  选择艺术家
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="flex-start-center">
                <el-tag
                  type="success"
                  style="margin-right: 15px"
                  v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
                  >{{ diyItem.params.catedata.title }}</el-tag
                >
                <div
                  class="pointer"
                  :style="{ color: typeIndex == 1 ? defaultColor : '#333' }"
                  @click="showSelect(1)"
                >
                  选择艺术机构
                </div>
              </div>
            </el-col>
          </el-row>
          <div
            class="img_list"
            v-for="(item, index) in diyItem.cate_goods"
            :key="index"
          >
            <el-row class="row-bg">
              <el-col :span="6">
                <div class="img_left">
                  <img :src="item.avatar" alt="" />
                  <div class="bton" @click="selectedgoods(index)">更换</div>
                </div>
              </el-col>
              <el-col :span="18">
                <el-row type="flex" style="align-items: center">
                  <el-col :span="6" class="name">名称</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.title"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; margin-top: 10px"
                >
                  <el-col :span="6" class="name">分类</el-col>
                  <el-col :span="18">
                    <input
                      class="input"
                      type="text"
                      v-model="item.describe"
                      disabled="true"
                      readonly
                      placeholder="建议10个字以内，可不填"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div
              class="img_list_remove"
              @click="removeChild(index)"
              style="color: #999999"
            >
              <i class="el-icon-error"></i>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="样式管理" name="second">
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">标题颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.titlecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">介绍颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.describecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6">作品数颜色：</el-col>
          <el-col :span="18">
            <el-color-picker
              v-model="diyItem.style.timecolor"
            ></el-color-picker>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 页面边距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.padding"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
        <el-row class="row-bg row-bg-list">
          <el-col :span="6"> 模块间距：</el-col>
          <el-col :span="17">
            <el-slider
              v-model="diyItem.style.goodsmargin"
              :min="0"
              :max="30"
            ></el-slider>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <!-- <selectImg @selectImgshow="imgurl" ref="mychild"></selectImg> -->
    <selectArtList
      @selectImgshow="goodsurl"
      ref="mygoods"
      :type="typeIndex"
    ></selectArtList>
    <!-- <select-cate ref="cate" @updata="updata"></select-cate> -->
  </div>
</template>

<script>
// import selectImg from '@/components/selectImg'
import selectArtList from "@/views/components/selectArtList";
import { join } from "path";
// import selectCate from './selectCate'
// import {goodsCatelist} from '@/api/store'
export default {
  name: "edit_goods",
  components: {
    // selectImg,
    selectArtList,
    // 'select-cate': selectCate
  },
  data() {
    return {
      activeName: "first",
      options: [],
      index: "",
      typeIndex: 0,
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
    diyKey: function () {
      return this.$store.state.diypage.diyKey;
    },
    diyList() {
      return this.$store.state.diypage.diyList;
    },
    defaultColor() {
      return this.$store.state.theme.defaultColor;
    },
  },
  mounted() {},
  methods: {
    // 分类选择回调
    updata: function (row) {
      let data = {
        cate_id: row.id,
      };
      goodsCatelist(data)
        .then((response) => {
          let goodsList = response.result;
          if (goodsList.length === 0) {
            this.$message.error("该分类下没有机构");
          }
          this.$set(this.diyItem, "cate_goods", goodsList);
          this.$set(this.diyItem.params, "catedata", row);
        })
        .catch();
    },
    // 显示分类
    showSelect: function (val) {
      this.typeIndex = val;
      console.log(val);
      // this.$refs.cate.showDialog();
    },
    // 选择机构
    selectedgoods: function (index) {
      this.index = index;
      this.$refs.mygoods.showimgselect();
    },
    // 机构选择回调
    goodsurl: function (vlu) {
      let arr = [];
      if (this.typeIndex == 1) {
        arr = vlu.ArtistWorkImgs ? vlu.ArtistWorkImgs.split(",") : [];
      }
      console.log(vlu);
      let newObj = {
        avatar: vlu.avatar,
        describe: vlu.describe,
        id: vlu.id,
        images: this.typeIndex == 0 ? vlu.images : arr,
        time: vlu.AddTime ? vlu.AddTime.split(" ")[0].split("-").join(".") : "",
        title: vlu.title,
        worknum: vlu.worknum,
      };
      if (this.index === "") {
        this.diyItem.cate_goods.push(newObj);
      } else {
        this.$set(this.diyItem.cate_goods, this.index, newObj);
        this.index = "";
      }
    },
    // 图片选择回调
    imgurl: function (vl) {
      this.$set(this.diyItem.params, "goodsiconsrc", vl);
    },
    // 选择图片
    selectedimg: function (index) {
      this.index = index;
      this.$refs.mychild.showimgselect();
    },
    // 删除子元素
    removeChild: function (index) {
      this.diyItem.cate_goods.splice(index, 1);
    },
    // 添加子元素
    addChild: function () {
      if (this.diyItem.cate_goods.length >= 10) {
        this.$message.error("最多添加10张图片");
        return "";
      }
      this.$refs.mygoods.showimgselect();
    },
  },
};
</script>

<style>
.el-radio {
  margin-bottom: 10px;
}
</style>
