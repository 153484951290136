import request from '@/utils/request'

// 商品管理-类目列表-树形结构-无分页
export function industryList(data) {
  return request({
    url: '/platform/v2/goods/industry/list',
    method: 'get',
    params: data
  })
}

// 商品管理-类目列表-树形结构-有分页
export function cateListPage(data) {
  return request({
    url: '/b2c/v1/manage/getGoodsAll',
    method: 'post',
    data
  })
}
// 商品管理-下架原因
export function soldOutReason(data) {
  return request({
    url: '/goods/v1/goods/soldOutReason',
    method: 'post',
    data
  })
}
// 课程模块-二级分类
export function cateList(data) {
  return request({
    url: 'api/DesignTemplate/QueryCourseCategoryList',
    method: 'get',
    params: data
  })
}

// 商品管理-分类-新增
export function cateAdd(data) {
  return request({
    url: '/platform/v2/goods/cate/add',
    method: 'post',
    data
  })
}
// 商品管理-分类-新增
export function cateEdit(data) {
  return request({
    url: '/platform/v2/goods/cate/edit',
    method: 'post',
    data
  })
}

// 商品管理-分类-新增
export function cateDel(data) {
  return request({
    url: '/platform/v2/goods/cate/del',
    method: 'post',
    data
  })
}

// 商品管理-商品列表
export function goodsList(data) {
  return request({
    url: '/platform/v3/goods/shopGoods',
    method: 'post',
    data
  })
}

// 课程模块-课程列表
export function goodsListNew(data) {
  return request({
    url: 'api/DesignTemplate/QueryCourseList',
    method: 'get',
    params: data
  })
}

// 商品管理-商品状态统计
export function goodsStatus(data) {
  return request({
    url: '/platform/v2/goods/status',
    method: 'post',
    data
  })
}
// 商品管理-商品详情
export function goodsdetail(data) {
  return request({
    url: '/platform/v3/goods/shopGoodsDetail',
    method: 'post',
    data
  })
}

// 商品管理-商品添加
export function goodsAdd(data) {
  return request({
    url: '/goods/v1/goods/add',
    method: 'post',
    data
  })
}
// 商品管理-商品排序
export function goodsSort(data) {
  return request({
    url: '/b2c/v1/goods/sort',
    method: 'post',
    data
  })
}

// 商品管理-商品删除
export function goodsDel(data) {
  return request({
    url: '/platform/v2/goods/del',
    method: 'post',
    data
  })
}

// 商品管理-删除自建商品
export function goodsDelete(data) {
  return request({
    url: '/goods/v1/goods/delete',
    method: 'post',
    data
  })
}

// 商品管理-商品编辑
export function goodsedit(data) {
  return request({
    url: '/goods/v1/goods/edit',
    method: 'post',
    data
  })
}
// 商品管理-商品状态变更
export function goodschange(data) {
  return request({
    url: '/b2c/v1/goods/salesStatus',
    method: 'post',
    data
  })
}
// 设置默认分类
export function goodsSetClassify(data) {
  return request({
    url: '/platform/v2/goods/cate/default',
    method: 'post',
    data
  })
}
// 规格所有
export function specAll(data) {
  return request({
    url: '/platform/v2/product/spec/all',
    method: 'get',
    params: data
  })
}

// 规格更新
export function specChange(data) {
  return request({
    url: '/platform/v2/product/spec/change',
    method: 'post',
    data
  })
}

// 规格更新
export function changePrice(data) {
  return request({
    url: '/platform/v2/goods/changePrice',
    method: 'post',
    data
  })
}

// 商品详情页调价
export function priceBySpec(data) {
  return request({
    url: '/platform/v1/goods/price/priceBySpec',
    method: 'post',
    data
  })
}

// 商品更新
export function syncGoodsInfo(data) {
  return request({
    url: '/b2c/v1/egenew/syncGoodsInfo',
    method: 'post',
    data
  })
}


// 商品数据同步
export function syncGoodsInfoByEgenewGoodsId(data) {
  return request({
    url: '/b2c/v1/egenew/syncGoodsInfoByEgenewGoodsId ',
    method: 'post',
    data
  })
}

/**
 * [statusGoods 新上下架接口]
 * @param  {[type]} data [参数]
 */
export function statusGoods(data) {
  return request({
    url: '/operator/v2/goods/manageStatusGoods ',
    method: 'post',
    data
  })
}

// 商品分类
export function QueryGoodsCategoryList(data) {
  return request({
    url: 'api/designTemplate/QueryGoodsCategoryList',
    method: 'get',
    params: data
  })
}

// 商品列表 
export function QueryShopGoodsList(data) {
  return request({
    url: 'api/designTemplate/QueryShopGoodsList',
    method: 'get',
    params: data
  })
}