<template>
  <el-dialog
    title="我的图标"
    :visible.sync="centerDialogVisible"
    :width="dialogWidth"
    :append-to-body="true"
    class="upload-demo-zqj"
  >
    <div class="img-container">
      <ul class="img-container-iconList">
        <li
          v-for="(item, index) in dataIconList"
          @click="surclick(item.type)"
          :key="index"
        >
          <i class="iconfont" :class="item.type"></i>
          <div class="icon-name">{{ item.name }}</div>
        </li>
      </ul>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <!--<el-button type="primary" @click="surclick">确 定</el-button>-->
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      centerDialogVisible: false,
      dialogWidth: '835px',
      dataIconList: [
        {
          type: 'icon-yonghu',
          name: '用户'
        },
        {
          type: 'icon-gouwuche',
          name: '购物车'
        },
        {
          type: 'icon-gonggao',
          name: '公告'
        },
        {
          type: 'icon-dianpu',
          name: '店铺'
        },
        {
          type: 'icon-iconfontscan',
          name: '扫码'
        },
        {
          type: 'icon-kehuxinxi',
          name: '客户信息'
        },
        {
          type: 'icon-icon-',
          name: '数据统计'
        },
        {
          type: 'icon-zhangdan',
          name: '账单'
        },
        {
          type: 'icon-shouhuodizhi',
          name: '地址'
        },
        {
          type: 'icon-yinhangzhanghu',
          name: '银行账户'
        },
        {
          type: 'icon-daishouhuo1',
          name: '物流'
        },
        {
          type: 'icon-tuikuanguanhuai',
          name: '退款'
        },
        {
          type: 'icon-tuihuodan',
          name: '退货'
        },
        {
          type: 'icon-fenxiang3',
          name: '分享'
        },
        {
          type: 'icon-lianxiren',
          name: '客户'
        },
        {
          type: 'icon-lianxikehu',
          name: '客服'
        },
        {
          type: 'icon-diamond',
          name: 'vip'
        },
        {
          type: 'icon-custom-mail',
          name: '邮件'
        },
        {
          type: 'icon-custom-screen',
          name: '筛选'
        },
        {
          type: 'icon-custom-eyes',
          name: '看过'
        },
        {
          type: 'icon-custom-collection',
          name: '收藏'
        },
        {
          type: 'icon-custom-share',
          name: '分享'
        }
      ]
    }
  },
  methods: {
    showDialog: function () {
      this.centerDialogVisible = true
    },
    surclick: function (url) {
      this.centerDialogVisible = false
      this.$emit('selectIcon', url)
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {
  margin-top: 20px;
  max-height: 460px;
  min-height: 460px;
  &-iconList {
    li {
      width: 70px;
      height: 70px;
      margin: 5px;
      display: block;
      padding: 5px;
      float: left;
      cursor: pointer;
      text-align: center;
    }
    li:hover {
      background-color: #f6f6f6;
    }
    .iconfont {
      font-size: 25px;
    }
    .icon-name {
      margin-top: 10px;
    }
  }
}
</style>
