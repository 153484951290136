import axios from 'axios'
import {
  Message,
  MessageBox,
  Loading
} from 'element-ui'
import store from '@/store'
import {
  serverSrc
} from '@/apiConfig/index'
let loadingInstance = null
/**
 * 配置请求地址
 * @type {AxiosInstance}
 */
const service = axios.create({
  baseURL: serverSrc, // api 的 base_url
  timeout: 50000, // request timeout
  withCredentials: false // 禁止携带 Cookie
})
/**
 * 请求拦截器
 */
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    config.headers = {
      'Content-Type': 'application/json;charset=utf-8',
      'token': '',
    }
    loadingInstance = Loading.service({
      lock: true,
      text: '努力加载中...',
    })
    config.data = JSON.stringify(config.data)
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
  response => {
    loadingInstance.close()
    if (response.status == 10200) { // 登录过期
      MessageBox.confirm('因长时间未操作，登录超时，请重新登录！', '登录超时', {
        confirmButtonText: '重新登录',
        // cancelButtonText: '取消',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        store.dispatch('FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      }).catch()
      return Promise.reject(response)
    } else if (response.status == 10300) {
      MessageBox.confirm('因长时间未操作，登录超时，请重新登录！', '登录超时', {
        confirmButtonText: '重新选择',
        // cancelButtonText: '取消',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        store.dispatch('FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      }).catch()
      return Promise.reject(response)
    } else if (response.status == 200) {
      return Promise.resolve(response.data)
    } else {
      Message({
        message: response.data.message,
        type: 'warning',
        duration: 5 * 1000
      })
      return Promise.reject(response.data)
    }
  },
  err => {
    loadingInstance.close()
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误(400)';
          break
        case 401:
          err.message = '未授权，请重新登录(401)';
          break
        case 403:
          err.message = '拒绝访问(403)';
          break
        case 404:
          err.message = '请求出错(404)';
          break
        case 408:
          err.message = '请求超时(408)';
          break
        case 500:
          err.message = '服务器错误(500)';
          break
        case 501:
          err.message = '服务未实现(501)';
          break
        case 502:
          err.message = '网络错误(502)';
          break
        case 503:
          err.message = '服务不可用(503)';
          break
        case 504:
          err.message = '网络超时(504)';
          break
        case 505:
          err.message = 'HTTP版本不受支持(505)';
          break
        default:
          err.message = `连接出错(${err.response.status})!`
      }
    } else {
      err.message = '你的网络开了一个小差，请稍后再试!'
    }
    Message({
      message: err.message,
      type: 'warning',
      duration: 5 * 1000
    })
    return Promise.reject(err)
  }
)

export default service