<template>
  <div>
    <div
      class="fui-title diypage-title"
      :style="{
        background: diyitem.style.background,
        color: diyitem.style.color,
        padding:
          diyitem.style.paddingtop + 'px ' + diyitem.style.paddingleft + 'px',
        fontSize: diyitem.style.fontsize + 'px',
        textAlign: diyitem.style.textalign,
      }"
    >
      <template v-if="diyitem.data.return.icon == '1'">
        <i
          class="el-icon-arrow-left"
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        ></i>
      </template>
      <template v-if="diyitem.data.mail.icon == '1'">
        <i
          class="iconfont icon-custom-mail"
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        ></i>
      </template>
      <div class="fui-title-name">
        {{ diyitem.data.title || "请输入标题名称" }}
      </div>
      <!-- <template v-if="diyitem.data.search.icon == '1'">
        <i
          class="el-icon-search"
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        ></i>
      </template> -->
      <!-- <template v-if="diyitem.data.screen.icon == '1'">
        <i
          class="iconfont icon-custom-screen"
          :style="{
            color: diyitem.style.iconColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        ></i>
      </template> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_title",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.fui-title {
  display: flex;
  align-items: center;
  &-name {
    flex: 1;
  }
  .el-icon-arrow-left {
    margin-right: 5px;
  }
  .icon-custom-screen {
    margin-left: 5px;
  }
}
</style>
