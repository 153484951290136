<template>
    <div class="button-fixed flex-center" :style="{right:!helpHide?'264px':'14px',left:haveSidebar?'214px':'114px'}">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'index',
  computed: {
    haveSidebar () {
      return this.$store.state.theme.haveSidebar
    },
    helpHide () {
      return this.$store.state.theme.helpHide
    }
  }
}
</script>

<style scoped lang="scss">
    .button-fixed{
        position: fixed;
        height: 70px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0 40px;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        /*box-shadow: 0px 0px 5px 0px*/
        /*rgba(0, 0, 0, 0.15);*/
        border-radius: 4px 4px 0px 0px;
    }

</style>
