var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.diyitem.style.showtype == '0')?_c('div',{staticClass:"fui-icon-group noborder",class:['col-' + _vm.diyitem.style.rownum, _vm.diyitem.style.dotstyle],style:({ background: _vm.diyitem.style.background })},_vm._l((_vm.diyitem.data),function(childitem,inde){return _c('div',{key:inde,staticClass:"fui-icon-col external",on:{"click":function($event){return _vm.gotourl(childitem.linkurl)}}},[_c('div',{staticClass:"icon"},[(childitem.imgurl != 'img/default.3894ed26.png')?_c('img',{class:_vm.diyitem.style.navstyle,attrs:{"src":childitem.imgurl.indexOf('http') != -1
              ? childitem.imgurl
              : _vm.serverSrc + childitem.imgurl}}):_vm._e(),(childitem.imgurl == 'img/default.3894ed26.png')?_c('img',{class:_vm.diyitem.style.navstyle,attrs:{"src":childitem.imgurl}}):_vm._e()]),_c('div',{staticClass:"text",style:({ color: childitem.color })},[_vm._v(" "+_vm._s(childitem.text)+" ")])])}),0):_vm._e(),(_vm.diyitem.style.showtype == '1')?[_c('el-carousel',{staticClass:"menu",style:({
        background: _vm.diyitem.style.background,
        padding: '10px',
        height: _vm.diyitem.data_line * 75 + 'px',
      }),attrs:{"indicator-position":_vm.diyitem.style.showdot,"arrow":"never"}},_vm._l((_vm.diyitem.data_temp),function(childitem,index){return _c('el-carousel-item',{key:index,staticClass:"menu_banner",class:['col-' + _vm.diyitem.style.rownum, _vm.diyitem.style.dotstyle]},_vm._l((childitem),function(menu_item,indx){return _c('div',{key:indx,staticClass:"fui-icon-col external"},[_c('div',{staticClass:"icon"},[(menu_item.imgurl != 'img/default.3894ed26.png')?_c('img',{class:_vm.diyitem.style.navstyle,attrs:{"src":menu_item.imgurl.indexOf('http') != -1
                  ? menu_item.imgurl
                  : _vm.serverSrc + menu_item.imgurl}}):_vm._e(),(menu_item.imgurl == 'img/default.3894ed26.png')?_c('img',{class:_vm.diyitem.style.navstyle,attrs:{"src":menu_item.imgurl}}):_vm._e()]),_c('div',{staticClass:"text",style:({ color: menu_item.color })},[_vm._v(" "+_vm._s(menu_item.text)+" ")])])}),0)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }