import request from '@/utils/request'

// 视频列表 
export function QueryCourseList(data) {
    return request({
        url: '/api/v1/Course/QueryCourseList',
        method: 'post',
        data
    })
}
// 视频分类
export function QueryTopCategory(data) {
    return request({
        url: '/api/v1/Course/QueryTopCategory',
        method: 'get',
        params: data
    })
}