<template>
  <div>
    <div class="diy-videos">
      <div
        class="videos-item"
        v-for="(item, index) in diyitem.cate_goods"
        :key="index"
        :style="{
          paddingLeft: diyitem.style.padding + 'px',
          paddingRight: diyitem.style.padding + 'px',
          paddingTop: diyitem.style.goodsmargin + 'px',
        }"
      >
        <div
          class="videos-item-top"
          :style="{ backgroundImage: 'url(' + item.t_image + ')' }"
        >
          <div
            class="videos-item-title"
            :style="{ color: diyitem.style.titlecolor }"
          >
            {{ item.title }}
          </div>
          <img
            class="videos-item-img"
            src="@/assets/images/default/public/bf.png"
            alt=""
          />
          <div class="videos-item-info">
            <div :style="{ color: diyitem.style.peoplecolor }">
              {{ item.viewers }}
            </div>
            <div :style="{ color: diyitem.style.durationcolor }">
              {{ item.duration }}
            </div>
          </div>
        </div>
        <div class="videos-item-bottom">
          <div class="price">
            <span
              class="discountPrice"
              v-if="item.discountPrice"
              :style="{ color: diyitem.style.discountPricecolor }"
            >
              ￥{{ item.discountPrice }}
            </span>
            <span
              class="originalPrice"
              v-if="item.originalPrice"
              :style="{ color: diyitem.style.originalPricecolor }"
            >
              ￥{{ item.originalPrice }}
            </span>
            <span
              class="gratis"
              v-if="!item.discountPrice && !item.originalPrice"
            >
              免费播放
            </span>
          </div>
          <div class="discount">
            <div
              v-if="item.IsPlatformDiscount == 1"
              class="discount-icon icon1"
            >
              v
            </div>
            <div v-if="item.CourseTags == '推荐'" class="discount-icon icon2">
              荐
            </div>
            <!-- <div class="discount-icon icon3">新</div> -->
            <!-- <div class="discount-icon icon4">惠</div> -->
            <div
              class="discount-btn"
              v-if="!item.discountPrice && !item.originalPrice"
            >
              点击观看
            </div>
            <div class="discount-btn" v-else>购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_videos",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    console.log(this.diyitem);
  },
};
</script>

<style scoped lang="scss">
.diy-videos {
  width: 100%;
  .videos-item {
    width: 100%;
  }
  .videos-item-top {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-size: 100% 200px;
    .videos-item-title {
      width: 100%;
      color: #fff;
      font-weight: bold;
      text-align: left;
    }
    .videos-item-img {
      width: 95px;
      height: 95px;
    }
    .videos-item-info {
      width: 100%;
      color: #fff;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }
  .videos-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .discount {
      display: flex;
      .discount-btn {
        width: 79px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        background: #000000;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
      }
      .discount-icon {
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .icon1 {
        background: #5d5034;
      }
      .icon2 {
        background: #2b3356;
      }
      .icon3 {
        background: #304a2f;
      }
      .icon4 {
        background: #562d2d;
      }
    }
    .discountPrice {
      font-size: 14px;
    }
    .originalPrice {
      font-size: 12px;
      text-decoration: line-through;
    }
    .gratis {
      font-size: 14px;
    }
  }
}
</style>
