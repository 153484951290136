var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.diyitem.params.styleTemplate == '1')?_c('div',{staticClass:"fui-courses-group",style:({
      paddingLeft: _vm.diyitem.style.padding + 'px',
      paddingRight: _vm.diyitem.style.padding + 'px',
    })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-courses-item",style:({
        paddingTop: _vm.diyitem.style.goodsmargin + 'px',
      })},[_c('div',{staticClass:"fui-courses-item-top"},[_c('img',{staticClass:"salez",style:({
            borderRadius: _vm.diyitem.style.imgborder + 'px',
          }),attrs:{"src":childitem.t_image}}),_c('div',{staticClass:"fui-courses-item-title"},[_c('div',{staticClass:"overflow",style:({
              fontSize: _vm.diyitem.style.titleSize + 'px',
              color: _vm.diyitem.style.titlecolor,
            })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.title,11))+" ")]),_c('div',{style:({
              fontSize: _vm.diyitem.style.describeSize + 'px',
              color: _vm.diyitem.style.describecolor,
            })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.describe,22))+" ")])])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"detail-left"},[(childitem.TeacherInfo.length == 0)?_c('img',{staticClass:"detail-left-avatar",attrs:{"src":childitem.avatar}}):_c('img',{staticClass:"detail-left-avatar",attrs:{"src":childitem.TeacherInfo[0].HeadImg}}),(childitem.TeacherInfo.length == 0)?_c('span',{staticClass:"detail-left-name"},[_vm._v(_vm._s(childitem.name))]):_c('span',{staticClass:"detail-left-name"},[_vm._v(_vm._s(childitem.TeacherInfo[0].Name))])]),_c('div',{staticClass:"detail-right flex"},[(childitem.VipPrice == null ? false : true)?_c('img',{attrs:{"src":require("../../assets/images/default/store/icon_vip.png"),"alt":"","srcset":""}}):_vm._e(),(
              childitem.CourseTags == null
                ? false
                : childitem.CourseTags.includes('推荐')
            )?_c('img',{attrs:{"src":require("../../assets/images/default/store/icon_tuijian.png"),"alt":"","srcset":""}}):_vm._e(),(
              childitem.CourseTags == null
                ? false
                : childitem.CourseTags.includes('最新')
            )?_c('img',{attrs:{"src":require("../../assets/images/default/store/icon_zuixin.png"),"alt":"","srcset":""}}):_vm._e(),(
              childitem.CourseTags == null
                ? false
                : childitem.CourseTags.includes('热门')
            )?_c('img',{attrs:{"src":require("../../assets/images/default/store/icon_youhui.png"),"alt":"","srcset":""}}):_vm._e(),_c('div',{staticClass:"detail-right-playType",style:({
              color: _vm.diyitem.style.buybtntextcolor,
              backgroundColor: _vm.diyitem.style.buybtncolor,
            })},[_vm._v(" 直播 ")])])])])}),0):_vm._e(),(_vm.diyitem.params.styleTemplate == '2')?_c('div',{staticClass:"fui-courses-group",class:_vm.diyitem.params.styleTemplate == '2' ? 'fui-courses-group2' : '',style:({
      paddingLeft: _vm.diyitem.style.padding + 'px',
      paddingRight: _vm.diyitem.style.padding + 'px',
    })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-courses-item",style:({
        paddingTop: _vm.diyitem.style.goodsmargin + 'px',
      })},[_c('div',{staticClass:"fui-courses-item-top"},[_c('img',{staticClass:"salez",style:({
            borderRadius: _vm.diyitem.style.imgborder + 'px',
          }),attrs:{"src":childitem.t_image}}),_c('div',{staticClass:"fui-courses-item-title"},[_c('div',[_c('div',{staticClass:"overflow",style:({
                fontSize: _vm.diyitem.style.titleSize + 'px',
                color: _vm.diyitem.style.titlecolor,
              })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.title,12))+" ")]),_c('div',{staticClass:"suitable",style:({
                color: _vm.diyitem.style.title2color,
              })},[_c('p',[_vm._v("展览时间："+_vm._s(childitem.date))]),_c('p',[_vm._v("展览地点："+_vm._s(childitem.Address))])])]),_c('div',[_c('div',{staticClass:"detail-right"},[_c('div',{staticClass:"detail-right-playType",style:({
                  color: _vm.diyitem.style.buybtntextcolor,
                  backgroundColor: _vm.diyitem.style.buybtncolor,
                })},[_vm._v(" 进入展览 ")])])])])]),_c('div',{staticClass:"detail"},[_c('div',{style:({
            fontSize: _vm.diyitem.style.describeSize + 'px',
            color: _vm.diyitem.style.describecolor,
          })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.describe,62))+" ")])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }