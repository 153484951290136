<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <el-row :gutter="diyitem.style.goodsmargin">
      <el-col
        :span="12"
        :style="{
          marginTop: diyitem.style.goodsmargin + 'px',
        }"
        v-for="(childitem, index) in diyitem.cate_goods"
        :key="index"
      >
        <div
          class="fui-goods-item"
          :style="{
            borderColor: diyitem.style.bordercolor,
          }"
        >
          <img :src="childitem.t_image" alt="" />
          <!-- <div
            class="title ellipsis-1"
            :style="{
              color: diyitem.style.titlecolor,
            }"
          >
            {{ childitem.title }}
          </div>
          <div
            class="describe"
            :style="{
              color: diyitem.style.describecolor,
            }"
          >
            {{ childitem.describe }}
          </div>
          <div
            class="time"
            :style="{
              color: diyitem.style.timecolor,
            }"
          >
            {{ childitem.time }}
          </div>
          <div
            class="dsj-x"
            :style="{
              borderTopColor: diyitem.style.bordercolor,
            }"
          ></div>
          <div class="dsj-s"></div> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "tpl_goods",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-goods-item {
  text-align: center;
  line-height: 1.5;
  height: 100px;
  border: 1px solid #b7b7b7;
  position: relative;
  .title {
    color: #333333;
    font-weight: bold;
    font-size: 22px;
    margin-top: 5px;
  }
  .describe {
    color: #636466;
    font-size: 13px;
  }
  .time {
    color: #b6a683;
    transform: scale(0.8);
  }
  .dsj-x {
    position: absolute;
    top: 4.5px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid #b7b7b7;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: rotate(45deg);
  }
  .dsj-s {
    position: absolute;
    top: -3px;
    right: -10px;
    width: 0;
    height: 0;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 12px solid #ffffff;
    transform: rotate(45deg);
  }
}
</style>
