import request from '@/utils/request'

// 资讯分类
export function QueryCategoryList(data) {
    return request({
        url: 'api/designTemplate/QueryCategoryList',
        method: 'get',
        params: data
    })
}

// 资讯列表
export function QueryNewsList(data) {
    return request({
        url: 'api/designTemplate/QueryNewsList',
        method: 'get',
        params: data
    })
}

// 资讯列表
export function QueryNewsCategory(data) {
    return request({
        url: 'api/v1/Common/QueryNewsCategory',
        method: 'get',
        params: data
    })
}
// 资讯列表
export function QueryNewsCategoryQueryNewsList(data) {
    return request({
        url: 'api/v1/Common/QueryNewsList',
        method: 'post',
        data: data
    })
}