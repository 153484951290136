<template>
  <el-dialog
    title="资讯动态"
    :visible.sync="centerDialogVisible"
    :width="dialogWidth"
    :append-to-body="true"
    class="upload-demo-zqj"
  >
    <div>
      <el-row class="row-bg row-bg-list" type="flex">
        <el-col :span="8">
          <!-- <el-select v-model="cate_id" multiple style="width: 100%;" @change="onChange" filterable placeholder="请选择商品分类">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.goods_cate_id">
            </el-option>
          </el-select> -->
          <el-select
            v-model="template_id"
            placeholder="请先选择资讯模块"
            @change="templateonChange"
          >
            <el-option
              v-for="item in templats"
              :key="item.Id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="cate_id"
            placeholder="请选择资讯分类"
            @change="onChange"
            value-key="id"
          >
            <el-option
              v-for="item in options"
              :key="item.Id"
              :label="item.CategoryName"
              :value="{ id: item.Id, type: item.Type }"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="10">
          <el-input
            placeholder="请输入资讯名称"
            v-model="title"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="shearch"
            ></el-button>
          </el-input>
        </el-col> -->
      </el-row>
    </div>
    <div class="img-container">
      <el-table :data="tableData" stripe max-height="450" style="width: 100%">
        <el-table-column prop="title" label="资讯">
          <template slot-scope="scope">
            <div class="scope_title">
              <img :src="scope.row.CoverImg" alt="" />
              <div style="margin-left: 10px">{{ scope.row.Title }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="market_price" label="价格" width="100">
        </el-table-column> -->
        <!-- <el-table-column prop="stock" label="库存" width="80">
        </el-table-column> -->
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="goodChoose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page flex-justify-end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="page_size"
          :pager-count="5"
          :page-sizes="[5, 10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  QueryCategoryList,
  QueryNewsList,
  QueryNewsCategory,
  QueryNewsCategoryQueryNewsList,
} from "@/api/dynamic";
export default {
  name: "index",
  data() {
    return {
      dialogWidth: "835px",
      centerDialogVisible: false,
      templats: [
        {
          name: "课程资讯",
          id: 0,
        },
        {
          name: "三合艺术馆资讯",
          id: 1,
        },
        {
          name: "在线艺术",
          id: 2,
        },
      ],
      options: [],
      tableData: [],
      page: 1,
      page_size: 5,
      total: 0,
      title: "",
      keyWords: "",
      cate_id: {
        id: 0,
        type: 0,
      },
      template_id: "课程资讯",
      props: {
        multiple: true,
        emitPath: false,
        checkStrictly: false,
        label: "Name",
        value: "Id",
        children: "children",
      },
    };
  },
  mounted() {
    this.getGoodsList();
    this.templateonChange(0);
  },
  methods: {
    showimgselect: function () {
      this.centerDialogVisible = true;
    },
    shearch: function () {
      // this.getGoodsList();
    },
    onChange(e) {
      this.getGoodsList();
    },
    // 获取分类
    templateonChange(id) {
      console.log(id);
      this.cate_id = "";
      this.tableData = [];
      let data = {
        type: id,
      };
      QueryNewsCategory(data).then((res) => {
        this.options = res.response;
      });
    },
    // 获取商品列表
    getGoodsList: function () {
      console.log(this.cate_id);
      let data = {
        pageIndex: this.page,
        pageSize: this.page_size,
        categoryId: this.cate_id.id,
        type: this.cate_id.type,
      };
      QueryNewsCategoryQueryNewsList(data)
        .then((response) => {
          console.log(response);
          this.tableData = response.response.data;
          this.tableData.forEach((element) => {
            this.$set(element, "id", element.Id);
            this.$set(element, "title", element.Title);
            this.$set(element, "t_image", element.CoverImg);
            this.$set(element, "describe", element.Introduce);
            this.$set(element, "ReadCount", element.ReadCount);
            this.$set(
              element,
              "date",
              element.CreateTime ? element.CreateTime.slice(0, 10) : ""
            );
          });
          this.total = response.response.dataCount;
        })
        .catch();
    },
    //  获取分类列表
    getCateList: function () {
      QueryCategoryList()
        .then((response) => {
          console.log(response);
          this.options = response.response;
        })
        .catch();
    },
    // 选择
    goodChoose(data) {
      this.centerDialogVisible = false;
      this.$emit("selectImgshow", data);
    },
    //  改变每页大小
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    //  前去多少页
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsList();
    },
  },
};
</script>
<style>
.upload-demo-zqj .el-upload__input {
  display: none;
}

.upload-demo-zqj .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__footer {
  border-top: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__body {
  padding: 20px;
}
</style>
<style scoped lang="scss">
.scope_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.img-container {
  margin-top: 20px;
  height: 520px;
  position: relative;

  .page {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
</style>
