<template>
  <div class="editor_content">
    <div class="editor_title">公告</div>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 公告内容：</el-col>
      <el-col :span="18">
        <el-radio-group v-model="diyItem.data.type">
          <el-radio :label="1">读取系统公告</el-radio>
          <!-- <el-radio :label="2">自定义内容</el-radio> -->
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list" v-if="diyItem.data.type === 2">
      <el-col :span="6"> 自定义内容：</el-col>
      <el-col :span="18">
        <el-input
          v-model="diyItem.data.title"
          placeholder="请输入公告内容"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 背景颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.background"></el-color-picker>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 文字颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.color"></el-color-picker>
      </el-col>
    </el-row>
    <!-- <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 图标设置：</el-col>
      <el-col :span="18">
        <el-radio-group v-model="diyItem.style.iconType">
          <el-radio :label="1">图标</el-radio>
          <el-radio :label="2">图片</el-radio>
        </el-radio-group>
      </el-col>
    </el-row> -->
    <!-- <template v-if="diyItem.style.iconType == 1">
      <el-row class="row-bg row-bg-list">
        <el-col :span="6"> 图标设置：</el-col>
        <el-col :span="18">
          <div>
            <i class="iconfont" :class="diyItem.style.iconurl"></i>
            <el-button
              size="mini"
              style="margin-left: 10px"
              class="fixed_table_name"
              @click="changeIcon()"
              >更换图标</el-button
            >
          </div>
        </el-col>
      </el-row>
    </template>
    <template v-if="diyItem.style.iconType == 2">
      <el-row class="row-bg row-bg-list">
        <el-col :span="6"> 图片设置：</el-col>
        <el-col :span="18">
          <div class="flex-start-center">
            <img
              :src="diyItem.style.imgUrl"
              style="width: 20px; height: 20px"
              alt=""
            />
            <el-button
              size="mini"
              style="margin-left: 10px"
              class="fixed_table_name"
              @click="selectImgUrl()"
              >更换图片</el-button
            >
          </div>
        </el-col>
      </el-row>
    </template> -->
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 上下边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingtop"
          :min="5"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 左右边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingleft"
          :min="5"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>

    <select-iconfont @selectIcon="iconUrl" ref="iconfont"></select-iconfont>
    <select-img @selectImgshow="imgurl" ref="mychild"></select-img>
  </div>
</template>

<script>
import selectIconfont from "@/components/selectIconfont";
import selectImg from "@/views/components/selectImg";

export default {
  name: "edit_notice",
  components: {
    "select-iconfont": selectIconfont,
    "select-img": selectImg,
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
  },
  methods: {
    changeIcon: function (type) {
      this.type = type;
      this.$refs.iconfont.showDialog();
    },
    iconUrl: function (url) {
      this.diyItem.style.iconurl = url;
    },
    /** * 选择图片
     * */
    selectImgUrl: function (type) {
      this.$refs.mychild.showimgselect();
    },
    // 图片选择回调
    imgurl: function (url) {
      this.diyItem.style.imgUrl = url.Url;
    },
  },
};
</script>

<style scoped></style>
