let environment = 'production' // development：测试环境， production：正式环境 test:测试环境
// let Url = "http://192.168.10.121:8081" //测试
let Url = "https://puliedu.1plus.store/" //测试
// let UrlProd = "http://shop.egenew.com" //正式
let UrlProd = 'https://api.pulijiaoyu.org.cn/'
const serverSrc = environment == "development" ? Url : UrlProd
const outSrc = environment == "development" ? Url : UrlProd
const wwwSrc = environment == "development" ? Url : UrlProd
const integralSrc = environment == "development" ? Url + '/points' : UrlProd + '/points'
export {
  integralSrc, //积分Url
  serverSrc, // 服务器地址
  wwwSrc, // 前端地址
  outSrc // 公共登录地址
}