<template>
  <div
    :style="{
      paddingLeft: diyitem.style.padding + 'px',
      paddingRight: diyitem.style.padding + 'px',
    }"
  >
    <div
      :style="{
        marginTop: diyitem.style.goodsmargin + 'px',
      }"
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-art-item"
      :key="index"
    >
      <div
        class="images flex-between"
        v-if="childitem.t_image instanceof Array"
      >
        <img :src="childitem.t_image[0]" class="img1" alt="" srcset="" />
        <div
          class="flex ac-space-between fw-wrap"
          style="flex: 0 0 94px; height: 100%; margin-left: 13px"
        >
          <img :src="childitem.t_image[1]" class="img2" alt="" srcset="" />
          <img :src="childitem.t_image[2]" class="img2" alt="" srcset="" />
        </div>
      </div>
      <div class="images flex-between" v-else>
        <img :src="childitem.t_image" class="img1" alt="" srcset="" />
        <div
          class="flex ac-space-between fw-wrap"
          style="flex: 0 0 94px; height: 100%; margin-left: 13px"
        >
          <img :src="childitem.t_image" class="img2" alt="" srcset="" />
          <img :src="childitem.t_image" class="img2" alt="" srcset="" />
        </div>
      </div>
      <div
        class="title ellipsis-1"
        :style="{
          color: diyitem.style.titlecolor,
        }"
      >
        {{ childitem.title }}
      </div>
      <div class="describe flex-between">
        <p
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          {{ childitem.describe }}
        </p>
        <p
          class="price"
          :style="{
            color: diyitem.style.priceColor,
            fontSize: diyitem.style.iconsize + 'px',
          }"
        >
          <template v-if="childitem.market_price">
            ￥{{ childitem.market_price }}
          </template>
          <template v-else> ￥0 </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_art",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
    console.log(this.diyitem.cate_goods);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fui-art-item {
  text-align: left;
  line-height: 1.5;
  .images {
    height: 190px;
    img {
      display: block;
    }
    .img1 {
      width: 238px;
      height: 190px;
    }
    .img2 {
      width: 94px;
      height: 88px;
    }
  }
  .t_image {
    width: 261px;
    display: block;
  }
  .title {
    color: #373737;
    font-weight: bold;
    font-size: 15px;
    margin: 12px 0 6px;
  }
  .describe {
    color: #373737;
    font-size: 13px;
  }
  .price {
    color: #333333;
    font-size: 14px;
  }
}
</style>