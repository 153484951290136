import request from '@/utils/request'

// 合作机构 列表
export function QueryPartnersList(data) {
    return request({
        url: '/api/v1/Course/QueryPartnersList',
        method: 'get',
        params: data
    })
}
// 合作机构 分类  
// 1=在线展览 2=艺术活动 3=青年艺术家 4=专家 5=艺术家 6=馆藏
// 7=画廊(艺术机构) 8=艺术商城(艺术伙伴) 9艺术活动地址 10讲师分类 11合作机构分类（课程）
export function QueryArtistCategory(data) {
    return request({
        url: '/api/DesignTemplate/QueryArtistCategory',
        method: 'get',
        params: data
    })
}