<template>
  <div>
    <div class="index-banner diy-banner">
      <el-carousel
        height="205px"
        indicator-position="none"
        arrow="never"
        @change="change"
      >
        <el-carousel-item v-for="(image, index) in diyitem.data" :key="index">
          <img
            v-if="image.imgurl == 'img/default.3894ed26.png'"
            :src="image.imgurl"
          />
          <img
            v-if="image.imgurl != 'img/default.3894ed26.png'"
            :src="image.imgurl"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="dots center" :class="diyitem.style.dotstyle">
        <span :style="{ background: diyitem.style.background }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "tpl_banner",
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serverSrc: serverSrc,
    };
  },
  mounted() {},
  methods: {
    change: function (e) {},
  },

  components: {},
};
</script>

<style scoped>
.index-banner {
  height: 205px;
}
.diy-banner {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  position: relative;
}
.diy-banner .dots.center {
  text-align: center;
}
.diy-banner .dots {
  height: 14px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  z-index: 10;
}
.diy-banner .dots.round span {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
  margin: 3px;
}
.diy-banner .dots.rectangle span {
  height: 5px;
  width: 20px;
  display: inline-block;
  margin: 3px;
}
.diy-banner .dots.square span {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: 3px;
}
.diy-banner .dots.none span {
  height: 0;
}
</style>
