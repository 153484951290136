var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},[_c('el-row',{attrs:{"gutter":_vm.diyitem.style.goodsmargin}},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('el-col',{key:index,style:({
        marginTop: _vm.diyitem.style.goodsmargin + 'px',
      }),attrs:{"span":12}},[_c('div',{staticClass:"fui-goods-item",style:({
          borderColor: _vm.diyitem.style.bordercolor,
        })},[_c('img',{attrs:{"src":childitem.t_image,"alt":""}})])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }