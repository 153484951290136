var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    paddingLeft: _vm.diyitem.style.padding + 'px',
    paddingRight: _vm.diyitem.style.padding + 'px',
  })},_vm._l((_vm.diyitem.cate_goods),function(childitem,index){return _c('div',{key:index,staticClass:"fui-exhibition-item",style:({
      marginTop: _vm.diyitem.style.goodsmargin + 'px',
    })},[_c('img',{staticClass:"t_image",style:({
        borderRadius: _vm.diyitem.style.imgborder + 'px',
      }),attrs:{"src":childitem.t_image,"alt":"","srcset":""}}),_c('div',{staticClass:"title flex-between"},[_c('span',{style:({
          color: _vm.diyitem.style.titlecolor,
        })},[_vm._v(" "+_vm._s(childitem.title)+" ")])]),_c('div',{staticClass:"describe"},[_c('p',{style:({
          color: _vm.diyitem.style.describecolor,
        })},[_vm._v(" "+_vm._s(_vm._f("intercept")(childitem.describe,36))+" ")]),_c('p',{staticClass:"describe-bottom",style:({
          color: _vm.diyitem.style.iconColor,
          fontSize: _vm.diyitem.style.iconsize + 'px',
        })},[_c('span',[_vm._v(" "+_vm._s(childitem.date)+" ")]),_c('span',[_c('i',{staticClass:"iconfont el-icon-view"}),(childitem.ReadCount == null)?[_vm._v(" 0 ")]:[_vm._v(" "+_vm._s(childitem.ReadCount)+" ")]],2)])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }