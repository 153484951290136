<template>
  <div class="swipe_main">
    <div
      v-for="(childitem, index) in diyitem.cate_goods"
      class="fui-art-item"
      :key="index"
    >
      <img
        v-if="childitem.HeadImg == null"
        :src="childitem.t_image"
        class="t_image"
        alt=""
        srcset=""
      />
      <img v-else :src="childitem.HeadImg" class="t_image" alt="" srcset="" />
      <div style="">
        <div
          class="title-box"
          :style="{
            color: diyitem.style.titlecolor,
          }"
        >
          <div class="min-box"></div>
          <div>
            <p>{{ childitem.title }}</p>
            <p class="title">{{ childitem.CategoryName }}</p>
          </div>
        </div>
        <div class="describe flex-between">
          <div class="describe-box">
            {{ childitem.describe | intercept(20) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tpl_art",
  components: {},
  data() {
    return {};
  },
  props: {
    diyitem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.diyitem);
  },
  methods: {},
  filters: {
    stock: function (e) {
      e = parseInt(e);
      if (e === -1) {
        return "无限";
      } else {
        return e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.swipe_main {
  display: flex;
  justify-content: space-evenly;
}
.fui-art-item {
  // text-align: center;
  line-height: 1.5;
  width: 150px;
  flex: 0 0 150px;
  margin-top: 15px;
  .t_image {
    width: 150px;
    height: 150px;
    display: block;
    margin: 0 auto;
  }
  .title-box {
    color: #373737;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    margin: 12px 0 12px;
    display: flex;
    .min-box {
      width: 4px;
      height: 40px;
      background: #f1c800;
      margin-right: 10px;
    }
    .title {
      font-size: 12px;
      color: #7e7e7e;
    }
  }
  .describe {
    color: #afafaf;
    font-size: 13px;
    text-align: left;
    margin-bottom: 10px;
    word-break: break-all;
  }
  .flex-between {
    align-items: flex-start;
  }
  .describe-box {
    width: 100%;
    height: 40px;
    // white-space: nowrap; // 规定段落中的文本不换行
    text-overflow: ellipsis; //文本溢出部分用省略号代替
    display: -webkit-box; // 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示
    -webkit-box-orient: vertical; // 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式
    -webkit-line-clamp: 3; // 用来限制在一个块元素显示的文本的行数
    overflow: hidden; // 溢出元素不显示
  }
}
</style>