import request from '@/utils/request'

// 讲师列表
export function QueryTeacherList(data) {
    return request({
        url: 'api/designTemplate/QueryTeacherList',
        method: 'get',
        params: data
    })
}
// 讲师分类 
export function QueryTeacherCategory(data) {
    return request({
        url: 'api/designTemplate/QueryTeacherCategory',
        method: 'get',
        params: data
    })
}