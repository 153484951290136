<template>
  <div class="editor_content">
    <div class="editor_title">标题</div>

    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 标题名称：</el-col>
      <el-col :span="18">
        <el-input
          v-model="diyItem.data.title"
          placeholder="请输入标题名称"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 图标设置：</el-col>
      <el-col :span="18">
        <el-radio-group v-model="diyItem.style.iconType">
          <el-radio :label="1">图标</el-radio>
          <el-radio :label="2">图片</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <template v-if="diyItem.style.iconType == 1">
      <el-row class="row-bg row-bg-list">
        <el-col :span="6"> 图标设置：</el-col>
        <el-col :span="18">
          <div>
            <i
              class="iconfont"
              :class="diyItem.data.icon"
              :style="{
                fontSize: diyItem.style.iconsize + 'px',
              }"
            ></i>
            <el-button
              size="mini"
              style="margin-left: 10px"
              class="fixed_table_name"
              @click="changeIcon()"
              >更换图标</el-button
            >
          </div>
        </el-col>
      </el-row>
    </template>
    <template v-if="diyItem.style.iconType == 2">
      <el-row class="row-bg row-bg-list">
        <el-col :span="6"> 图片设置：</el-col>
        <el-col :span="18">
          <div class="flex-start-center">
            <img
              :src="serverSrc + diyItem.data.imgUrl"
              style="width: 20px; height: 20px"
              alt=""
            />
            <el-button
              size="mini"
              style="margin-left: 10px"
              class="fixed_table_name"
              @click="selectImgUrl()"
              >更换图片</el-button
            >
          </div>
        </el-col>
      </el-row>
    </template>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6">右侧设置：</el-col>
      <el-col :span="18">
        <el-radio-group v-model="diyItem.style.rightIcon">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <template v-if="diyItem.style.rightIcon < 3">
      <el-row class="row-bg row-bg-list">
        <el-col :span="6">右侧颜色 ：</el-col>
        <el-col :span="18">
          <el-color-picker v-model="diyItem.style.iconColor"></el-color-picker>
        </el-col>
      </el-row>
    </template>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 背景颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.background"></el-color-picker>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 文字颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.color"></el-color-picker>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 跳转链接：</el-col>
      <el-col :span="18">
        <el-input
          disabled=""
          placeholder="请选择跳转链接"
          v-model="diyItem.data.linkname"
        >
          <el-button @click="showLink" slot="suffix" type="text"
            >选择链接</el-button
          >
        </el-input>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 图标大小：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.iconsize"
          :min="8"
          :max="25"
        ></el-slider>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 文字大小：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.fontsize"
          :min="8"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 上下边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingtop"
          :min="0"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>

    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 左右边距：</el-col>
      <el-col :span="18">
        <el-slider
          v-model="diyItem.style.paddingleft"
          :min="0"
          :max="20"
        ></el-slider>
      </el-col>
    </el-row>
    <select-iconfont @selectIcon="iconUrl" ref="iconfont"></select-iconfont>
    <select-img @selectImgshow="imgurl" ref="mychild"></select-img>
    <choose-link @selectItem="linkUrl" ref="link"></choose-link>
  </div>
</template>

<script>
import selectIconfont from "@/components/selectIconfont";
import selectImg from "@/views/components/selectImg";
import chooseLink from "@/views/components/chooseLink";
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "edit_title",
  components: {
    "select-iconfont": selectIconfont,
    "select-img": selectImg,
    "choose-link": chooseLink,
  },
  data() {
    return {
      serverSrc,
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
  },
  methods: {
    changeIcon: function (type) {
      this.type = type;
      this.$refs.iconfont.showDialog();
    },
    iconUrl: function (url) {
      this.diyItem.data.icon = url;
    },
    /** * 选择图片
     * */
    selectImgUrl: function (type) {
      this.$refs.mychild.showimgselect();
    },
    // 图片选择回调
    imgurl: function (url) {
      this.diyItem.data.imgUrl = url.Url;
    },
    //    链接回调
    linkUrl: function (url) {
      this.$set(this.diyItem.data, "linkurl", url.linkurl);
      this.$set(this.diyItem.data, "linkname", url.linkname);
    },
    //  选择链接
    showLink: function () {
      this.$refs.link.showChoose();
    },
  },
};
</script>

<style scoped lang="scss">
.item-hotdot {
  margin-right: 10px;
  img {
    width: 16px;
    height: auto;
    max-height: 25px;
  }
}
</style>
