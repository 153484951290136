<template>
  <el-dialog
    title="活动管理"
    :visible.sync="centerDialogVisible"
    :width="dialogWidth"
    :append-to-body="true"
    class="upload-demo-zqj"
  >
    <div>
      <el-row class="row-bg row-bg-list" type="flex" justify="space-between">
        <el-col :span="5">
          <el-select
            v-model="cate_id"
            placeholder="请选择活动分类"
            @change="onChange"
          >
            <el-option
              v-for="item in options"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div class="img-container">
      <el-table :data="tableData" stripe max-height="450" style="width: 100%">
        <el-table-column prop="title" label="活动名称">
          <template slot-scope="scope">
            <div class="scope_title">
              <img :src="scope.row.t_image" alt="" />
              <div style="margin-left: 10px">{{ scope.row.title }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="goodChoose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page flex-justify-end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="page_size"
          :pager-count="5"
          :page-sizes="[5, 10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { QueryArtActivity } from "@/api/activity";
export default {
  name: "index",
  props: {
    type: "",
  },
  data() {
    return {
      dialogWidth: "835px",
      centerDialogVisible: false,
      options: [
        { Id: "0", Name: "全部" },
        { Id: "1", Name: "预告" },
        { Id: "2", Name: "进行中" },
        { Id: "3", Name: "往期" },
      ],
      tableData: [],
      page: 1,
      page_size: 5,
      total: 0,
      title: "",
      cate_id: "0",
    };
  },
  mounted() {
    this.getGoodsList();
  },
  methods: {
    showimgselect: function () {
      this.centerDialogVisible = true;
    },
    shearch: function () {
      this.getGoodsList();
    },
    onChange() {
      this.getGoodsList();
    },
    // 获取商品列表
    getGoodsList: function () {
      let data = {
        pageIndex: this.page,
        pageSize: this.page_size,
        category: this.cate_id,
      };
      QueryArtActivity(data).then((response) => {
        console.log(response);
        this.tableData = response.response.data;
        this.tableData.forEach((element) => {
          let date1 = element.StartTime.slice(0, 10).replace(/-/g, "/");
          let date2 = element.EndTime.slice(0, 10).replace(/-/g, "/");
          this.$set(element, "id", element.Id);
          this.$set(element, "title", element.Title);
          this.$set(element, "t_image", element.CoverImg);
          this.$set(element, "date", date1 + "——" + date2);
        });
        this.total = response.response.dataCount;
      });
    },
    // 选择
    goodChoose(data) {
      this.centerDialogVisible = false;
      this.$emit("selectImgshow", data);
    },
    //  改变每页大小
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    //  前去多少页
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsList();
    },
  },
};
</script>
<style>
.upload-demo-zqj .el-upload__input {
  display: none;
}

.upload-demo-zqj .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__footer {
  border-top: 1px solid #e5e5e5;
}

.upload-demo-zqj .el-dialog__body {
  padding: 20px;
}
</style>
<style scoped lang="scss">
.scope_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.img-container {
  margin-top: 20px;
  height: 520px;
  position: relative;

  .page {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
</style>
