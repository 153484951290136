<template>
  <div class="editor_content">
    <div class="editor_title">轮播图</div>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 指示器形状：</el-col>
      <el-col :span="18">
        <el-radio-group v-model="diyItem.style.dotstyle">
          <el-radio label="rectangle">长方形</el-radio>
          <!-- <el-radio label="square">正方形</el-radio> -->
          <el-radio label="round">圆形</el-radio>
          <el-radio label="none">不显示</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 指示颜色：</el-col>
      <el-col :span="18">
        <el-color-picker v-model="diyItem.style.background"></el-color-picker>
      </el-col>
    </el-row>

    <!-- <div class="msg row-bg-list">添加图片：<span>最多添加10个图片</span></div> -->
    <el-row class="row-bg row-bg-list">
      <el-col :span="6"> 轮播图选择：</el-col>
      <el-col :span="18">
        <div class="flex-start-center">
          <el-tag
            type="success"
            style="margin-right: 15px"
            v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
            >{{ diyItem.params.catedata.title }}</el-tag
          >
          <div
            class="pointer"
            :style="{ color: type == 3 ? defaultColor : '#333' }"
            @click="showSelect(3)"
          >
            选择App轮播图
          </div>
          <div
            class="pointer"
            :style="{
              color: type == 4 ? defaultColor : '#333',
              marginLeft: '30px',
            }"
            @click="showSelect(4)"
          >
            选择三合美术馆轮播图
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="row-bg row-bg-list">
      <el-col :span="24">
        <div class="flex-start-center">
          <el-tag
            type="success"
            style="margin-right: 15px"
            v-if="JSON.stringify(diyItem.params.catedata) !== '{}'"
            >{{ diyItem.params.catedata.title }}</el-tag
          >
          <div
            class="pointer"
            :style="{ color: type == 2 ? defaultColor : '#333' }"
            @click="showSelect(2)"
          >
            选择商城轮播图
          </div>
          <div
            class="pointer"
            :style="{
              color: type == 5 ? defaultColor : '#333',
              marginLeft: '30px',
            }"
            @click="showSelect(5)"
          >
            选择在线艺术轮播图
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="img_list" v-for="(item, index) in diyItem.data" :key="index">
      <el-row class="row-bg">
        <el-col :span="6">
          <div class="img_left">
            <img
              v-if="item.imgurl != 'img/default.3894ed26.png'"
              :src="item.imgurl"
            />
            <img
              v-if="item.imgurl == 'img/default.3894ed26.png'"
              :src="item.imgurl"
            />
            <div class="bton" @click="selectedimg(index)">更换图片</div>
          </div>
        </el-col>
        <el-col :span="18">
          <!-- <el-row type="flex" style="align-items: center">
            <el-col :span="6" class="name">图片标题</el-col>
            <el-col :span="18">
              <input
                class="input"
                type="text"
                placeholder="建议10个字以内，可不填"
              />
            </el-col>
          </el-row> -->
          <!-- <el-row type="flex" style="align-items: center; margin-top: 10px">
            <el-col :span="6" class="name">跳转路径</el-col>
            <el-col :span="18">
              <el-radio v-model="item.linkType" label="system"
                >系统链接</el-radio
              >
              <el-radio v-model="item.linkType" label="external"
                >外部链接</el-radio
              >
              <el-radio v-model="item.linkType" label="appLink"
                >app跳转</el-radio
              >
              <el-input
                :disabled="item.linkType == 'system'"
                :placeholder="
                  item.linkType == 'system'
                    ? '请选择跳转链接'
                    : '请输入跳转链接'
                "
                v-model="item.linkurl"
              >
                <el-button
                  v-if="item.linkType == 'system'"
                  @click="showLink(index)"
                  slot="suffix"
                  type="text"
                  >选择</el-button
                >
              </el-input>
            </el-col>
          </el-row> -->
        </el-col>
      </el-row>
      <div
        class="img_list_remove"
        @click="removeChild(index)"
        style="color: #999999"
      >
        <i class="el-icon-error"></i>
      </div>
    </div>
    <div class="img_list img_add" @click="addChild">
      <div><i class="el-icon-plus"></i> 添加一张图片</div>
      <div class="prompt">建议尺寸750x350</div>
    </div>

    <select-banner
      @selectImgshow="imgurl"
      ref="mychild"
      :type="type"
    ></select-banner>
    <choose-link @selectItem="linkUrl" ref="link"></choose-link>
  </div>
</template>

<script>
import selectImg from "@/views/components/selectImg";
import selectBanner from "@/views/components/selectBanner";
import chooseLink from "@/views/components/chooseLink";
import { serverSrc } from "@/apiConfig/index";
export default {
  name: "edit_banner",
  components: {
    "choose-link": chooseLink,
    "select-img": selectImg,
    "select-banner": selectBanner,
  },
  data() {
    return {
      type: "3",
      serverSrc: serverSrc,
      options: [
        {
          linkurl: "www.baidu.com1",
          label: "测试链接1",
        },
        {
          linkurl: "www.baidu.com2",
          label: "测试链接2",
        },
      ],
      index: "",
      isSystem: true, // 是否是系统链接
    };
  },
  computed: {
    diyItem: function () {
      return this.$store.state.diypage.diyItem;
    },
    diyKey: function () {
      return this.$store.state.diypage.diyKey;
    },
    diyList() {
      return this.$store.state.diypage.diyList;
    },
    defaultColor() {
      return this.$store.state.theme.defaultColor;
    },
  },
  mounted() {},
  methods: {
    // 选择分类
    showSelect: function (val) {
      this.type = val;
    },
    // 图片选择回调
    imgurl: function (vl) {
      if (this.index === "") {
        let item = {
          imgurl: vl.ImgUrl,
          linkurl: "",
          linkType: "system",
          GoodsId: vl.GoodsId ? vl.GoodsId : "",
        };
        this.diyItem.data.push(item);
      } else {
        this.diyItem.data[this.index].imgurl = vl.ImgUrl;
        if (vl.GoodsId) {
          this.diyItem.data[this.index].GoodsId = vl.GoodsId;
        } else {
          this.diyItem.data[this.index].GoodsId = "";
        }
        this.index = "";
      }
      console.log(this.diyItem);
    },
    // 选择图片
    selectedimg: function (index) {
      this.index = index;
      this.$refs.mychild.showimgselect();
    },
    // 删除子元素
    removeChild: function (index) {
      this.diyItem.data.splice(index, 1);
    },
    //    链接回调
    linkUrl: function (url) {
      this.$set(this.diyItem.data[this.index], "linkurl", url.linkurl);
    },
    //  选择链接
    showLink: function (index) {
      this.index = index;
      this.$refs.link.showChoose();
    },
    // 添加子元素
    addChild: function () {
      if (this.diyItem.data.length >= 10) {
        this.$message.error("最多添加10张图片");
        return "";
      }
      this.$refs.mychild.showimgselect();
    },
  },
};
</script>

<style scoped></style>
